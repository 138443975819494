import { Flight, Info, InfoOutlined, InfoRounded, InfoSharp, QuestionMarkOutlined } from "@mui/icons-material";
import { Button, FormControlLabel, FormGroup, Icon, Switch, Tooltip, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { DataGridPro, gridFilterModelSelector, GridLinkOperator, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarQuickFilter, useGridApi, useGridApiContext } from "@mui/x-data-grid-pro";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import { useAPI } from "../../hooks/useAxios";



function CustomToolbar(props) {

    const apiRef = useGridApiContext();

    const [selectValues, setSelectValues] = useState([]);

    // Build our items list for our checkbox, and reset if the parent chagnes
    const select_box_items = useMemo(() => {
        // Return a list of items for the select
        let wing_airframe_items = [];
        let other_airframe_items = [];
        let items = [];

        for (const af of props.airframes) {
            if (props.wing_airframe_ids.includes(af.id)) {
                wing_airframe_items.push(af)
            } else {
                other_airframe_items.push(af);
            }
        }

        // Special item we'll deselect
        items.push({id: "", checkbox: false, display_name: "Filter by Airframe", disabled: true})
        items.push({id: 0, checkbox: false, display_name: <em>Deselect All</em>})

        if (wing_airframe_items.length > 0) {
            items.push({subheader: true, name: "Wing Airframes"})
            items.push(...wing_airframe_items)
        }

        if (other_airframe_items.length > 0) {
            items.push({subheader: true, name: "Non Wing Airframes"})
            items.push(...other_airframe_items)
        }
        setSelectValues(props.wing_airframe_ids);

        return items
    }, [props.airframes, props.wing_airframe_ids])

    // Items to display in the Select Box are: All Wing Airframes, wing airframes, non-wing airframes in that order
    const updateCheck = (e) => {
        // 0 = deseelct all, "" = our plain header
        let filter = null;

        if (e.target.value.includes(0)) {
            setSelectValues([""])
        } else {
            let new_value = e.target.value.filter((x) => x !== "")
            if (new_value.length === 0) {
                setSelectValues([""])
            } else {
                setSelectValues(new_value)
                filter = new_value;
            }
        }

        // { columnField: 'airframes', operatorValue: 'is', value: 'true' }
        if (!filter) {
            apiRef.current.deleteFilterItem({columnField: 'airframe_ids', operatorValue: "interseects", value: null})
        } else {
            apiRef.current.upsertFilterItem({columnField: 'airframe_ids', operatorValue: "interseects", value: filter})
        }

    }

    return (
        <GridToolbarContainer>
            <MultiSelectCheckbox
                name="wing_airfarmes"
                label="Filter by Airframe"
                items={select_box_items}
                value={selectValues}
                onChange={updateCheck}
                />
            <Box sx={{display: "flex", flexGrow: 1}} />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

const TooltipQuery = React.forwardRef(({ownerState, wing_airframe_ids, row, ...props}, ref) => {

    // find wing airframes
    //const used_by_wing_airframes = row.airframes.filter((used_by)  => {  for (const wa of wing_airframes) { if (wa.id == used_by.id) return used_by; }});

    const [used_by_state, set_used_by_state] = useState({'wing': [], 'non_wing': []});

    useEffect(() => {
        let wing_airframes = [];
        let non_wing_airframes = [];

        row.airframes.forEach((available_by) => {
            if (wing_airframe_ids.includes(available_by.id)) {
                wing_airframes.push(available_by);
            } else {
                non_wing_airframes.push(available_by);
            }
        });

        set_used_by_state({
            wing: wing_airframes,
            non_wing: non_wing_airframes,
        })

    }, []);


    return <Box
            {...props}
            ref={ref}
            style={{
                marginTop: '20px',
                backgroundColor: "#00000099",
                padding: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                borderRadius: '5px',
                color: 'white'
            }}
        >
            {used_by_state.wing.length > 0 &&
                <Box>
                    Used By Wing Airframes
                    <ul>
                    {used_by_state.wing.map((x, idx) => <li key={`ubw_${idx}`}>{x.display_name}</li>)}
                    </ul>
                </Box>
            }
            {used_by_state.non_wing.length > 0 &&
                <Box>
                    Used By Non Wing Airframes:
                    <ul>
                    {used_by_state.non_wing.map((x, idx) => <li key={`ubw_${idx}`}>{x.display_name}</li>)}
                    </ul>
                </Box>
            }
        </Box>
})


const DisplayNameComponent = React.forwardRef(({wing_airframe_ids, row, props, value}, ref) => {

    return <Box sx={{width: "100%", gap: '10px', display: "flex", alignItems: "center"}} {...props} ref={ref}>
        <Tooltip
            title="dummy"
            placement="right-start"
            slots={{
                tooltip: TooltipQuery
            }}
            slotProps={{
                tooltip: {"row": row, "wing_airframe_ids": wing_airframe_ids}
            }}
        >
            <Icon>
                <Flight/>
            </Icon>
        </Tooltip>
        {value}
    </Box>
})


export default function CampaignWarehouse() {


    const axios = useAPI();
    const params = useParams();


    // Array intersection operator
    const array_intersection = {
        label: 'Intersects',
        value: 'interseects',
        getApplyFilterFn: (filterItem, column ) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
              return null;
            }

            return (params) => {
                // Ensure that filterItem.value intersects params.value
                for (const fiv of filterItem.value) {
                    if (params.value.includes(fiv)) return true
                }
                return false;
            };
        },
    }

    const warehouse_cols = [
        {
            field: 'display_name',
            headerName: "Weapon",
            flex: 1,
            renderCell: (params) => <DisplayNameComponent wing_airframe_ids={wing_airframe_ids} {...params} />,
        },
        {
            field: 'wing_airframe',
            headerName: "Used by wing",
            type: "boolean",
            flex: 1,
            maxWidth: 200,
            align: "center",
            headerAlign: "left"
        },
        {
            field: 'airframe_ids',
            filterOperators: [array_intersection],
        },
        {
            field: 'quantity',
            headerName: "Quantity",
            maxWidth: 200,
            valueFormatter: (v) => v.value ? v.value : "∞",
            flex: 1,
            align: "right",
            type: "number",
            editable: true
        },
    ];

    // List of airframe objects {id:, displayName:} for filter dropdown
    const [airframes, set_airframes] = useState([]);

    // List of airframe IDs
    const [wing_airframe_ids, set_wing_airframe_ids] = useState([]);

    // Warehouse Table Items
    const [warehouse_rows, set_warehouse_rows] = useState([]);
    const [warehouse_filter, set_warehouse_filter] = useState({
        items: [],
        quickFilterLogicOperator: GridLinkOperator.And,
    });

    useEffect(() => {

        axios.get(`/airframe`).then((res) => {
            set_airframes(res.data);
        })

        axios.get(`/campaigns/${params.campaign_id}/warehouse`).then((res) => {
            if (!res.data) return;

            // We pass this down to tooltip to help
            let new_wing_airframe_ids = res.data.wing_airframes.map(x => x.id);
            set_wing_airframe_ids(new_wing_airframe_ids);

            // And default filter by these
            set_warehouse_filter({
                items: [{columnField: 'airframe_ids', operatorValue: "interseects", value: new_wing_airframe_ids}],
                quickFilterLogicOperator: GridLinkOperator.And,
            });

            // Create a field of if it's used in the wing or not
            let rows = [];
            for (let row of res.data.warehouse) {
                row.wing_airframe = (() => {
                    for (const af of row.airframes) {
                        if (new_wing_airframe_ids.includes(af.id)) return true
                    }
                    return false
                })();

                // Condense airframes to just an array to make quicker matching
                row.airframe_ids = row.airframes.map((x) => x.id);
                rows.push(row);
            }
            set_warehouse_rows(rows);
        })
    }, []);

    const updateQuantity = (newRow, oldRow) => {

        if (newRow.quantity === "") newRow.quantity = null;
        if (newRow.quantity === oldRow.quantity) return oldRow;

        return axios.patch(
            `/campaigns/${params.campaign_id}/warehouse/${oldRow.id}`,
            {quantity: newRow.quantity}
        ).then((res) => {
            if (res?.data?.ok) return newRow;
            console.log("ERR", res)
            return oldRow;
        });
    }

    return (
        <Box sx={{width: '100%'}}>
            <Typography variant="h4" sx={{marginBottom: '20px'}}>
                Campaign Warehouse
            </Typography>
            <Typography sx={{marginBottom: '20px'}}>
                The campaign warehouse shows the total campaign available inventory. On an event level, these stores can be distributed to
                specific airfields and provide the mission maker with a compatible warehouse file to place within the mission.
                <br/><br/>
                There are some items in game, that have duplicate warehouse options such as the F/A-18C's Lightning pod, which has separate
                inventories for the centerline pod and left mounted pod. These will be shown as one on this page, and the value linked to both
                in the warehouse file so you will see 10 centerline pods, and 10 left pods for example.
                <br/><br/>
                To consoldate the inventory after an event, participants will be required to fill out the quantity of ammo they land
                with to appropriately remove the correct quantity from the inventory.
                <br/><br/>
                Event hosts will be given a preview of the expected inventory usage on the event, and warned should flights select more than is
                available in the inventory so that informed decisions can be made as to the allocation of munitions (rather than first come first
                served)
            </Typography>

            <DataGridPro
                columns={warehouse_cols}
                rows={warehouse_rows}
                filterModel={warehouse_filter}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        airframes: airframes,
                        wing_airframe_ids: wing_airframe_ids,
                    },
                }}
                columnVisibilityModel={{
                    airframe_ids: false,
                }}
                onFilterModelChange={(filter) => { set_warehouse_filter(filter) } }
                processRowUpdate={updateQuantity}
                experimentalFeatures={{ newEditingApi: true }}
                disableSelectionOnClick
                disableColumnFilter
                autoHeight
            />
        </Box>


    )


}