import { Close } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

import { useEffect, useState } from "react";
import { updateDataGridRows } from "../GridManagement/updateDataGridRows";
import { useAPI } from "../../hooks/useAxios";
import { useShowError } from "../../hooks/useErrorDialog";
import { renderGridCellFrequency, renderGridCellFrequencyEdit } from "../GridCells/GridCellFrequency";
import { renderGridCellStringNonEmpty } from "../GridCells/GridCellValidated";
import { reflectErrorValue } from "../GridCells/reflectErrorValue";
import { getGridRowPatchObject } from "../GridManagement/getGridRowPatchObject";
import StandardGrid from "../Grid/StandardGrid";
import { useParams } from "react-router-dom";
import { CELL_FREQUENCY_PLUS_OVERRIDE_WIDTH, CELL_FREQUENCY_WIDTH } from "../GridCells/Constants";

export default function GridAgencies({
    apiEndpoint,
}) {

    const params = useParams();

    const [state, setState] = useState(null);
    const showError = useShowError();

    const axios = useAPI();

    useEffect(() => {
        setState(null);
        axios.get(apiEndpoint).then((res) => {
            if (!res.data) return;
            setState(res.data);
        });
    }, [apiEndpoint]);

    ///////////////////////////////////////////////////////////////////////////
    // Row creation handling
    ///////////////////////////////////////////////////////////////////////////

    // Let process Row Update handle the additional validation
    const createAgency = (createData) => {
        // Ensure name is not empty
        if ((createData.name||"").trim() === "") {
            showError("Invalid Agency", "Name must not be empty!")
            return;
        }
         // Make sure one of primary, secondary, or tertary is set
        let frequency_set = ['primary_frequency', 'secondary_frequency', 'tertiary_frequency'].filter((x) => createData[x] !== undefined)
        if (frequency_set.length === 0) {
            showError("Invalid Agency", "At least one frequency must be applied")
            return;
        }

        // to json
        return axios.post(`${apiEndpoint}`, createData).then((res) => {
            if (!res.data) return;
            setState({...state, agencies: [...state.agencies, res.data]});
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // STANDARD GRID HANDLING
    ///////////////////////////////////////////////////////////////////////////

    const handleDeleteClick = (id) => () => {
        if (id === 0) return;

        return axios.delete(`${apiEndpoint}/${id}`).then((res) => {
            if (!res.data) return;
            setState({...state, agencies: state.agencies.filter((x) => x.id !== id)});
        });
    }

    const processRowUpdate = (newRow, oldRow) => {
        const patch = getGridRowPatchObject(newRow, oldRow);
        if (!patch) return oldRow;
        if (!newRow.id) return oldRow;

        console.log(patch);

        return axios.patch(`${apiEndpoint}/${newRow.id}`, patch).then((res) => {
            if (!res.data) return oldRow;
            const new_state = {...state, agencies: updateDataGridRows(state.agencies, [res.data])}
            setState(new_state);
            return newRow;
        });
    }

    const onProcessRowUpdateError = (e) => {
        console.log(e)
    }

    const columns = (() => {
        let cols = [
            {
                field: 'name',
                headerName: 'Agency',
                flex: 1,
                editable: true,
                cellClassName: 'fullCell',
                renderCell: renderGridCellStringNonEmpty(),
                renderEditCell: renderGridCellStringNonEmpty("edit"),
                preProcessEditCellProps: reflectErrorValue,
            },
            {
                field: 'primary_frequency',
                headerName: 'Primary',
                width: CELL_FREQUENCY_PLUS_OVERRIDE_WIDTH,
                sortable: false,
                editable: true,
                cellClassName: 'fullCell',
                renderEditCell: renderGridCellFrequencyEdit,
                renderCell: renderGridCellFrequency,
                preProcessEditCellProps: reflectErrorValue,
            },
            {
                field: 'secondary_frequency',
                headerName: 'Secondary',
                width: CELL_FREQUENCY_PLUS_OVERRIDE_WIDTH,
                sortable: false,
                editable: true,
                cellClassName: 'fullCell',
                renderEditCell: renderGridCellFrequencyEdit,
                renderCell: renderGridCellFrequency,
                preProcessEditCellProps: reflectErrorValue,
            },
            {
                field: 'tertiary_frequency',
                headerName: 'Tertiary',
                width: CELL_FREQUENCY_PLUS_OVERRIDE_WIDTH,
                sortable: false,
                editable: true,
                cellClassName: 'fullCell',
                renderEditCell: renderGridCellFrequencyEdit,
                renderCell: renderGridCellFrequency,
                preProcessEditCellProps: reflectErrorValue,
            },
            {
                field: "enabled",
                headerName: 'Enabled',
                flex: 1,
                maxWidth: 110,
                sortable: true,
                editable: true,
                valueGetter: ({value}) => value === undefined ? true : value,
                type: "boolean",
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 80,
                getActions: (props) => {
                    return [
                        <GridActionsCellItem
                            icon={<Close />}
                            label="Cancel"
                            onClick={handleDeleteClick(props.id)}
                        />,
                    ]
                }
            }
        ]

        return cols;
    })();

    return (
        <StandardGrid

            columns={columns}
            rows={state?.agencies||[]}

            autoHeight

            createRow={createAgency}
            createRowTitle="Add Agency"

            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}

            pageSize={5}
            pagination
            rowsPerPageOptions={[5, 15, 25, 50, 100]}

            disableSelectionOnClick
            disableColumnMenu
            density="compact"

            loading={state === null}

            components={{
                NoRowsOverlay: () => (
                    <Stack height="100%" sx={{backgroundColor: "rgba(255,255,255,0.4)"}} alignItems="center" justifyContent="center">
                        {`No agencies configured for ${state?.name}`}
                    </Stack>
                ),
            }}

            initialState={{
                sorting: {
                    sortModel: [{ field: 'name', sort: 'desc' }],
                },
                pagination: {
                    pageSize: 5,
                    classes: null,
                },
            }}
        />
    )
}