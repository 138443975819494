export const getGridRowPatchObject = (newRow, oldRow) => {

    let patch = {}

    for (let [key, vnew] of Object.entries(newRow)) {
        if (key === "id") continue
        const vold = oldRow[key];

        if (isNaN(vnew) && isNaN(vold) && typeof vnew === "number" && typeof vold === "number") continue;

        // If we're a string, trim when comparing
        if (typeof vnew === "string") vnew = vnew.trim()

        // Simple check first to bail if pointing to same object
        // or same primitive (string, number etc)
        if (vnew === vold) continue;

        // Otherwise, we rely on isEqual
        if (typeof vnew?.isEqual === "function" && vnew.isEqual(vold)) continue;

        // And lastly, good enough for a patch
        patch[key] = vnew
    }

    // If patch has nothign we return null to avoid doing this everywhere
    if (!Object.keys(patch).length) return null;
    return patch;
}