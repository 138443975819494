import { Route, Routes } from "react-router-dom";
import CampaignTheatreEdit from "./CampaignTheatreEdit";
import CampaignTheatreRoot from "./CampaignTheatreRoot";

export default function CampaignTheatreRouter() {

    return (
        <Routes>
            <Route path="" element={<CampaignTheatreRoot />} />
            <Route path="/:theatre_id" element={<CampaignTheatreEdit />} />
            <Route path="/:theatre_id/airfield/:airfield_id" element={<CampaignTheatreEdit />} />
        </Routes>
    )

}