
import luaparse from "luaparse";
import * as zip from "@zip.js/zip.js";
import { M_TO_FT, NM_TO_M } from "../Constants";
import TACAN from "../common/TACAN";
import LatLon from "../common/LatLon";


// python.exe -c "import dcs; print(sorted([x.id for x in dcs.ships.ship_map.values() if getattr(x, 'plane_num', 0) > 2 or getattr(x, 'helicopter_num', 0) > 2], key=lambda x: x.lower()))"
const CARRIER_TYPES = ['CV_1143_5', 'CVN_71', 'CVN_72', 'CVN_73', 'CVN_75', 'Forrestal', 'hms_invincible', 'KUZNECOW', 'LHA_Tarawa', 'Stennis', 'VINSON'];
const FARP_TYPES = ['FARP', 'FARP_SINGLE_01', 'Invisible FARP', 'SINGLE_HELIPAD'];
const TANKER_TYPES = ['IL-78M', 'KC-135', 'KC130', 'KC135MPRS', 'S-3B', 'S-3B Tanker'];
const TASK_REQUIRES_UNIT = ['ActivateICLS']
const TASK_ONLY_PRIMARY_UNIT = ['ActivateBeacon']

function parseLUATable(ast, obj) {

    obj = obj || {}

    function _removeQuotes(str) {
        var char0 = str.charAt(0)
        if (["'", '"'].includes(char0) && str.charAt(str.length - 1) === char0)
            return str.substr(1, str.length - 2)
        return str
    }

    function _getValue(value) {
        var retval = null
        if (value.type === "StringLiteral") {
            retval = _removeQuotes(value.raw)
        } else if (value.type === "NumericLiteral") {
            retval = value.value
        } else if (value.type === "UnaryExpression") {
            if (value.operator === "-") {
                retval = - value.argument.value;
            }
        } else if (value.type === "BooleanLiteral") {
            retval = value.value
        }
        return retval
    }

    function _visit(node, obj) {
        if (node.type === "TableConstructorExpression") {
            for (let j = 0; j < node.fields.length; j++) {
                _visit(node.fields[j], obj);
            }
        } else if (node.type === 'TableKey') {
            var key = _getValue(node.key);
            var value = _getValue(node.value)

            if (value !== null) {
                obj[key] = value;
            } else {
                if (["TableConstructorExpression", "TableKey"].includes(node.type)) {
                    obj[key] = {};
                    _visit(node.value, obj[key]);
                } else {
                    console.log("FATAL", node);
                }
            }
        } else {
            console.log("FATAL", node);
        }
    }

    _visit(ast, obj);
    return obj;
}


export default async function MizFileParser(axiosInstance, target_theatre_id, file) {

    // Avionics directory files for F-16 / A10-C radios, we don't know these ahead of times, so, match
    const re_avionics = /Avionics\/(?<module>[^/]+)\/(?<unitid>[0-9]+)\/(?<radio>[^/]+)\/SETTINGS.lua/i

    const re_unit_iff = /[#@]IFF:(?<mode_1>\([0-9]{2}\))?(?<mode_2>\[[0-9]{4}\])?(?<mode_3>[0-9]{4})?(?<mode_4>[A-Z]{2})?$/

    const get_name = (dictionary, name) => name && name.startsWith("DictKey") ? (dictionary?.[name] || name) : name;

    const get_iff_from_name = (name) => {
        // This returns the a LotATC sqwawk mode from a name if present
        // https://www.lotatc.com/documentation/client/transponder.html#what-i-need-for-ai
        let match = re_unit_iff.exec(name)
        if (!match) return;

        let iff_obj = {};
        let groups = match.groups;
        if (groups.mode_1) iff_obj.mode_1 = groups.mode_1;
        if (groups.mode_2) iff_obj.mode_2 = groups.mode_2;
        if (groups.mode_3) iff_obj.mode_3 = groups.mode_3;
        if (groups.mode_4) iff_obj.mode_4 = groups.mode_4;

        return iff_obj
    }

    const parse_waypoints = (theatre, unitGroupId, unitId, groupData) => {

        // Returns a list of waypoints, and associated tasks that are relevent for this unit
        return Object.entries(groupData?.route?.points || {}).map(([idx, info])  => {

            // Waypoint Actions
            const tasks = Object.fromEntries(Object.entries((info?.task?.params?.tasks || {})).map(([taskId, taskInfo]) => {

                if (taskInfo.id !== "WrappedAction") {
                    return {
                        task: taskInfo.id,
                        params: taskInfo.params,
                    }
                } else {
                    // If the params have a unit ID that matches then we accept,
                    // otherwise if it's a general task, we assign it to all
                    // units in the group

                    let params = taskInfo?.params?.action?.params || {}
                    let task_id = taskInfo?.params?.action?.id;
                    if (!task_id) return [];

                    if (params.unitId) {
                        if (params.unitId !== unitId) return [];
                    } else {
                        if (TASK_REQUIRES_UNIT.includes(task_id)) return [];
                        if (TASK_ONLY_PRIMARY_UNIT.includes(task_id) && unitGroupId !== "1") return [];
                    }

                    return {
                        task: task_id,
                        params: params,
                    }
                }
            })
            .filter((x) => x !== null && x !== undefined)
            .map((x) => [x.task, x.params]));

            return {
                action: info.action,
                linkUnit: info.linkUnit,        // If linkUnit is set, then it's a FARP / Crarrier etc.
                airdromeId: info.airdromeId,    // otherwise, if airdromeId then it's a DCS core airfield
                latlon: LatLon.from_dcs(info.x, info.y, theatre),
                alt_ft: info.alt * M_TO_FT,
                tasks: tasks,
            }
        });
    }

    const process_carrier = (theatre, dictionary, groupData, unitGroupId, unitData) => {

        // Get our waypoints and extract our ICLS / TACAN information
        const waypoints = parse_waypoints(theatre, unitGroupId, unitData.unitId, groupData)

        let tacan = null;
        let icls = null;

        // We'll use the cumultative location of waypoints....
        let sumX = 0;
        let sumY = 0;
        let wpCount = 0;

        for (const wp of waypoints) {
            if (wp?.tasks?.ActivateBeacon) {
                if (wp.tasks.ActivateBeacon.type === 4) {
                    tacan = new TACAN(wp.tasks.ActivateBeacon.channel, wp.tasks.ActivateBeacon.modeChannel)
                }
            }
            if (wp?.tasks?.ActivateICLS?.channel) {
                icls = wp.tasks.ActivateICLS.channel
            }

            sumX += wp.latlon.lat.value
            sumY += wp.latlon.lat.value
            wpCount++;
        }

        console.log(sumX, sumY, wpCount);

        let refpoint = LatLon.from_dcs(sumX/wpCount, sumY/wpCount, theatre);

        let retval = {
            "unit_type": unitData.type,

            "unit_id": unitData.unitId,
            "unit_name": get_name(dictionary, unitData.name),

            "frequency": {
                khz: Number((unitData.frequency/1000).toFixed()),
                modulation: ["AM", "FM"][unitData.modulation],
            },

            "latlon": refpoint,
            "icls": icls,
            "tacan": tacan,

            // Do we need these ?
            //"waypoints": parse_waypoints(theatre, unitGroupId, unitData.unitId, groupData),
        }

        return ["carrier", retval];
    }

    const process_farp = (theatre, dictionary, groupData, unitGroupId, unitData) => {
        // For now farps are pretty basic, just location and frequency
        let farp_data = {
            "unit_type": unitData.type,

            "unit_id": unitData.unitId,
            "unit_name": get_name(dictionary, unitData.name),

            "latlon": LatLon.from_dcs(unitData.x, unitData.y, theatre),
        }

        // Heliport frequency is a string in Mhz and always AM, but still presents modulation so we use that
        let farp_frequency = Number(((parseFloat(unitData.heliport_frequency)||0)*1000).toFixed())
        if (farp_frequency !== 0) {
            farp_data.frequency = {
                khz: farp_frequency,
                modulation: ["AM", "FM"][unitData.heliport_modulation],
            }
        }

        return ["farp", farp_data]
    }

    const process_tanker = (theatre, dictionary, groupData, unitGroupId, unitData) => {
        // For tankers we return the position of the orbit anchor point which may not be the starting position
        let waypoints = parse_waypoints(theatre, unitGroupId, unitData.unitId, groupData);

        // first find if we have a tanker task on any of the waypoints
        let is_tanker = false;
        let orbit_wp = null;
        let tacan = null;

        for (const wp of waypoints) {
            if (!orbit_wp && wp?.tasks?.Orbit) orbit_wp = wp;
            if (wp?.tasks?.Tanker) is_tanker = true;
            if (wp?.tasks?.ActivateBeacon) {
                if (wp.tasks.ActivateBeacon.type === 4) {
                    tacan = new TACAN(wp.tasks.ActivateBeacon.channel, wp.tasks.ActivateBeacon.modeChannel)
                }
            }
        }

        if (!is_tanker || !orbit_wp) return;

        let name = get_name(dictionary, unitData.name);
        let iff = get_iff_from_name(name);

        return ["tanker", {

            "unit_type": unitData.type,

            "unit_id": unitData.unitId,
            "unit_name": name,

            "latlon": orbit_wp.latlon,

            "frequency": {
                khz: Number((groupData.frequency/1000).toFixed()),
                modulation: ["AM", "FM"][groupData.modulation],
            },

            "alt_ft": Math.round(orbit_wp.tasks.Orbit.altitude * M_TO_FT),
            "iff": iff,
            "tacan": tacan,
        }]
    }

    const process_ai_unit = (theatre, dictionary, groupData, unitGroupId, unitData) => {
        let name = get_name(dictionary, unitData.name);
        let iff = get_iff_from_name(name);
        let waypoints = parse_waypoints(theatre, unitGroupId, unitData.unitId, groupData);
        let tacan = null;

        for (const wp of waypoints) {
            if (wp?.tasks?.ActivateBeacon) {
                if (wp.tasks.ActivateBeacon.type === 4) {
                    tacan = new TACAN(wp.tasks.ActivateBeacon.channel, wp.tasks.ActivateBeacon.modeChannel);
                    break;
                }
            }
        }

        if (!iff && !tacan) return
        return ["placeholder", {
            iff: iff,
            tacan: tacan,
        }];
    }

    const process_player_unit = (theatre_info, airfields, theatre, dictionary, coalition, groupData, unitGroupId, unitData) => {
        // We have to delay player units until after we've done the rest as we want to map them
        // to an airfield which may be a FARP / CVN that has yet to be processed, so we delay it

        const waypoints = parse_waypoints(theatre, unitGroupId, unitData.unitId, groupData);
        const unitLatLon = LatLon.from_dcs(unitData.x, unitData.y, theatre);

        const max_airfield_distance = 2 * NM_TO_M;

        const find_closest_airfield = () => {
            let nearest = null;
            let nearest_distance = null;
            for (const airfield_list of Object.values(airfields)) {
                for (const af of airfield_list) {
                    let distance = unitLatLon.distance_to(af.latlon)
                    if (!nearest_distance || distance < nearest_distance) {
                        nearest = af;
                        nearest_distance = distance;
                    }
                }
            }
            return [nearest, nearest_distance];
        }

        // Find our depature airfield
        let first_action = waypoints?.[0]?.action;

        let retval = {

            unit_type: unitData.type,

            unit_id: unitData.unitId,
            unit_name: get_name(dictionary, unitData.name),

            group_id: groupData.groupId,
            group_name: get_name(dictionary, groupData.name),

            coalition: coalition,

            latlon: unitLatLon,
            waypoints: waypoints,
        }

        // Populate our departure location - either a DCS core airfield, Carrier or FARP, and if it's a "Ground Start"
        // Instead of "From Parking", then find the closest airfield and if it's within 2nm, consider that our base

        if (['From Parking Area', 'From Parking Area Hot', 'From Runway'].includes(first_action)) {
            if (waypoints[0].airdromeId) {
                retval.location_airfield_id = waypoints[0].airdromeId;
            } else if (waypoints[0].linkUnit) {
                retval.location_unit_id = waypoints[0].linkUnit
            } else {
                throw Error(`${first_action} should have an airdromeId or LinkUnit, but none found`);
            }
        } else if (['From Ground Area', 'From Ground Area Hot'].includes(first_action)) {
            const [af, distance] = find_closest_airfield()
            if (distance < max_airfield_distance) {
                if(af.unit_id) retval.location_unit_id = af.unit_id
                else retval.location_airfield_id = af.id;
            }
        }


        return retval;
    }

    const process_unit = (theatre, dictionary, classType, coalition, groupData, unitGroupId, unitData) => {
        //  Process a unit within the group, and return a tuple of [str: unit_type, dict: info]

        const unitType = unitData?.type;
        if (!unitType) return [];

        // Carriers / LHAs etc.
        if (CARRIER_TYPES.includes(unitType)) {
            return process_carrier(theatre, dictionary, groupData, unitGroupId, unitData);
        }

        // FARPs, Invisible FARPs, etc.
        if (FARP_TYPES.includes(unitType)) {
            return process_farp(theatre, dictionary, groupData, unitGroupId, unitData);
        }

        // Tankers...
        if (TANKER_TYPES.includes(unitType)) {
            return process_tanker(theatre, dictionary, groupData, unitGroupId, unitData);
        }

        let ai_unit = !(["Client", "Player"].includes(unitData.skill))

        // If it's an AI unit, process for TCN / IFF excludes
        if (ai_unit) {
            return process_ai_unit(theatre, dictionary, groupData, unitGroupId, unitData);
        }

        // We have to delay player units until after we've done the rest as we want to map them
        // to an airfield which may be a FARP / CVN that has yet to be processed, so we delay it
        return ["player_process", {"args": [theatre, dictionary, coalition, groupData, unitGroupId, unitData]}];
    }

    const file_extension = file.name.split('.').splice(-1)[0];

    if (file_extension !== 'miz') {
        return Promise.reject(`Invalid file extension (${file_extension}) for Mission Parser`)
    }

    // List of file paths, and target object location
    let files_to_load = {
        'VHF_FM_RADIO/SETTINGS.lua': 'vhf_fm',      // A-10 Radio Presets (and dials)
        'VHF_AM_RADIO/SETTINGS.lua': 'vhf_am',      // A-10 Global VHF Radio Presets
        'UHF_RADIO/SETTINGS.lua': 'uhf',            // A-10 and F-16 UHF Radio Peesets
        'VHF_RADIO/SETTINGS.lua': 'vhf',            // F-16 VHF Radio Presets
        'l10n/DEFAULT/dictionary': 'dictionary',    // Dictionary for unit / group name lookups etc.
        'mission': 'mission',                       // Our possibly large mission LUA table
    };

    // Our collation object
    let return_object = {
        'avionics': {},
    }

    let zip_reader = new zip.ZipReader(new zip.BlobReader(file));

    // TODO: We should read the theatre file to get our theatre and ensure it
    // matches, before reading the entire zip which could take a while

    return zip_reader.getEntries().then((entries) => {

        // Hold our list of promises of the files we're loading
        let to_process = [];

        entries.forEach(function(entry) {

            // Simple files, to dictionary keys
            let target = files_to_load[entry.filename];
            if (target) {
                to_process.push(entry.getData(new zip.TextWriter()).then((data) => {
                    let ast = luaparse.parse(data, { 'comments': false });
                    return_object[target] = parseLUATable(ast.body[0].init[0]);
                }));
            } else {
                // Avonics to part of the avionics dict, we need to nest on module because the unitID
                // may, could potentially be for a different module, so let's only use them when we
                // are sure and lookup based on module + unit id

                let match = re_avionics.exec(entry.filename)
                if (match) {
                    let groups = match.groups;

                    to_process.push(entry.getData(new zip.TextWriter()).then((data) => {

                        let ast = luaparse.parse(data, { 'comments': false });

                        if (!return_object.avionics[groups.module])
                            return_object.avionics[groups.module] = {}
                        if (!return_object.avionics[groups.module][groups.unitid])
                            return_object.avionics[groups.module][groups.unitid] = {}

                        // And dump the table
                        return_object.avionics[groups.module][groups.unitid][groups.radio] = parseLUATable(ast.body[0].init[0]);
                    }));
                }
            }
        });

        // Once we have all our data collated, we can process it to something more useful
        return Promise.all(to_process).then(() => {

            let {dictionary, mission} = return_object;

            let theatre = mission.theatre;

            // Get our theatre global information
            return axiosInstance.get(`/theatre/${target_theatre_id}`).then((res) => {

                const theatre_data = res.data;

                if (theatre_data.id !== target_theatre_id)
                    return Promise.reject("The mission file provided is not for this theatre")

                let navpoints = [];
                let bullseyes = [];
                let units = {};

                // Coalition Information (Navpoints / Bullseye)
                for (const [coalition, coalitionData] of Object.entries(mission.coalition)) {

                    let navpoints_seen = {}

                    bullseyes.push({
                        "latlon": LatLon.from_dcs(coalitionData.bullseye.x,  coalitionData.bullseye.y, theatre),
                        "coalition": coalition,
                    });

                    for (const navData of Object.values(coalitionData.nav_points)) {
                        if (!navData.callsignStr) continue;

                        // May have duplicates in a miz, so we'll just go with the first
                        // navpoints_seen is reset per coalition where they shant conflict
                        if (navpoints_seen[navData.callsignStr]) continue;
                        navpoints_seen[navData.callsignStr] = 1;

                        navpoints.push({
                            "name": navData.callsignStr,
                            "latlon": LatLon.from_dcs(navData.x, navData.y, theatre),
                            "type": "navpoint",
                            "coalition": coalition,
                        });
                    }

                    for (const countryData of Object.values(coalitionData.country)) {
                        for (const [classType, classData] of Object.entries(countryData)) {
                            for (const groupData of Object.values(classData.group||[])) {
                                let groupName = get_name(dictionary, groupData.name);
                                // unitGroupId = relative unit in the group, 1,2,3...
                                for (const [unitGroupId, unitData] of Object.entries(groupData.units)) {
                                    const [unitType, unitDict] = process_unit(theatre, dictionary, classType, coalition, groupData, unitGroupId, unitData) || [];
                                    if (unitType) {
                                        if (!units[unitType]) units[unitType] = [];
                                        units[unitType].push({
                                            ...unitDict,
                                            group_id: groupData.groupId,
                                            group_name: groupName,
                                            coalition: coalition,
                                        })
                                    }
                                }
                            }
                        }
                    }
                }

                // Build a combined list of theatre airfields + mission airfields with x/y coordinates so we can find if a unit
                // is near a FARP / airfield to associate it even if it's not "from parking"
                let airfields = {
                    core: theatre_data.airfields.map((x) => { return {...x, latlon: LatLon.from_ll(x.latlon.lat, x.latlon.lon, theatre)} }),
                    units: [...(units.farp || []), ...(units.carrier || [])],
                }

                // Now we can process our player units
                units["player"] = [];
                for (const playerUnitData of (units?.player_process || [])) {
                    units["player"].push(process_player_unit(theatre_data, airfields, ...(playerUnitData.args)));
                }
                delete units["player_process"];

                // Now we have the full info, we can
                return {
                    'filename': file.name,
                    'theatre': theatre,
                    'bullseye': bullseyes,
                    'navpoints': navpoints,
                    'units': units,
                }
            });
        });
    });

}