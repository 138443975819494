import { Avatar, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Collapse, IconButton, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/system";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import remarkGfm from 'remark-gfm'
import style from '../../../css/markdown-styles.module.css';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
}));

export default function CampaignCard({
    id,
    manager,
    avatar,
    avatarColor,
    target,
    title,
    detailMarkdown,
    actions,
}) {

    const [expanded, set_expanded] = useState(false)
    const navigate = useNavigate();

    const click = () => {
        navigate(target)
    }

    return (

        <Card sx={{mb: 2}}>
            <Box sx={{display: "flex"}}>
                <CardActionArea onClick={click}>
                    <CardHeader
                        avatar={<Avatar sx={{ bgcolor: avatarColor }} variant="rounded" aria-label="recipe">{avatar}</Avatar>}
                        title={`${title}`}
                        subheader={manager}
                    />
                </CardActionArea>
                <Box sx={{display: "flex", m: 1, alignItems: "end"}}>
                    <ExpandMore
                        expand={expanded}
                        onClick={() => {set_expanded(!expanded)}}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>

                </Box>
            </Box>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{paddingTop: 0, paddingBottom: 0}}>
                    <ReactMarkdown
                        className={style.reactMarkDown}
                        children={detailMarkdown}
                        remarkPlugins={[remarkGfm]}
                    />
                </CardContent>
                {(actions || []).length > 0 &&
                    <CardActions sx={{display: "flex", justifyContent: "end"}}>
                        {actions}
                    </CardActions>
                }
            </Collapse>
        </Card>
    )


}