import { Restore } from "@mui/icons-material";
import { Box, IconButton, Popover } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useCellCommitValue } from "../GridManagement/useCellCommitValue";


export function GridCellOverrideNotification({
    sx,
    row,
    field,
    text,
    onRevert,
    absolutePosition,
    children,
}) {

    onRevert ??= () => {}

    // If we are an override then we build our hover state
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopOverclick = () => {
        // Time to trigger stuff...
        onRevert({
            id: row.id,
            field: field,
            value: row.base[field],
        })
    };

    const handlePopoverOpen = (event) => { setAnchorEl(event.currentTarget) }
    const handlePopoverClose = () => { setAnchorEl(null) }
    const popOverOpen = Boolean(anchorEl);

    const content = (() => {
        if (Boolean(text)) {
            return <>
                <IconButton
                    size="small"
                    color="info"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    onClick={handlePopOverclick}
                    sx={{borderRadius: 2, m:0}}
                >
                    <Restore sx={{fontSize: '17px'}} />
                </IconButton>
                <Popover
                    open={popOverOpen}
                    sx={{
                        pointerEvents: 'none',
                        borderRadius: '10px',
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Box sx={{p:1, fontSize:"14px", display: "grid", gridTemplateColumns: 'auto', gap: '10px'}}>
                        <>Reset to {text}</>
                    </Box>
                </Popover>
            </>;
        }
        return null;
    })();

    // If we're absolute position, make it os
    const override_style = (() => {
        if (absolutePosition) return { width: '27px', position: "absolute", right: 0 }
        return { width: '27px' }
    })();

    const main_style = {...(() => {
        if (absolutePosition) return {}
        return { paddingLeft: '8px' }
    })(), ...sx}


    return (
        <Box sx={{position: "relative", display: 'flex', flexGrow: 1, height: '100%', alignItems: 'center', p:0, m:0}}>
            <Box sx={{display: "flex", height: '100%', alignItems: "center", flexGrow: 1, p:0, m:0, ...main_style}}>
                {children}
            </Box>
            <Box sx={override_style}>
                {content}
            </Box>
        </Box>
    )
}