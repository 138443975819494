import { Box } from "@mui/system";
import { GridRowModes, gridVisibleSortedRowIdsSelector, useGridApiContext } from "@mui/x-data-grid-pro";
import GridCellFeedback from "./GridCellFeedback";
import { rowEditTabHandler } from "./rowEditTabHandler";


export default function GridCellInput({

    gridProps,

    value,
    onChange,
    autoFocus,

    feedbackLevel,
    feedback,

    inputType,
    inputMin,
    inputMax,
    inputStep,
}) {

    const apiRef = useGridApiContext()

    return (
        // This box needs to be 100% for the feedback to work, so padding the input needs to be handled at input level
        <Box
            sx={{
                display: "flex",
                position: "relative",
                width: "100%",
                height: "100%",
                margin: 0,
                p: 0,
                border: 0,
                alignItems: "center",
            }}
        >
            <Box sx={{m:1, flexGrow: 1, height:"100%"}}>
                <input
                    tabIndex={0}
                    autoFocus={autoFocus}
                    style={{
                        background: 'transparent',
                        height: '100%',
                        width: '100%',
                        border: 0,
                        outline: 0,
                    }}
                    value={value||""}
                    onChange={onChange}
                    onKeyDown={rowEditTabHandler(apiRef, gridProps.id, gridProps.field)}

                    type={inputType}
                    min={inputMin}
                    max={inputMax}
                    step={inputStep}
                />
            </Box>
            <GridCellFeedback feedbackLevel={feedbackLevel}>{feedback}</GridCellFeedback>
        </Box>
    )
}