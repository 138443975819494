import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useAPI } from "../hooks/useAxios";

export default function TheatreList({
    ...rest
}) {

    const axios = useAPI()
    const [theatre_rows, set_theatre_rows] = useState([]);

    const theatre_cols = [
        {field: 'display_name', headerName: "Theatre", flex: 1},
    ]

    useEffect(() => {
        axios.get('/theatre').then((res) => {
            if(!res?.data) return;
            set_theatre_rows(res.data);
        })
    }, [])

    return (
        <DataGridPro
            rows={theatre_rows}
            columns={theatre_cols}
            disableSelectionOnClick autoHeight hideFooter
            {...rest}
        />
    )


}