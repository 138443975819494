import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CssBaseline, FormGroup, TextField, InputAdornment, Tab, Typography, FormControl, Select, MenuItem, Grid } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { createContext, useCallback, useEffect, useState } from "react";
import LatLon from "../common/LatLon";

export const CoordinateContext = createContext({
    'error': null,
    addError: () => {},
});

// We want to allow comma separation for fractionals
const RE_SIGNED_FLOAT = /^-?(?<int>[0-9]*)([.,](?<frac>[0-9]*))?$/
const RE_UNSIGNED_FLOAT = /^(?<int>[0-9]*)(?<frac>[.,](?:[0-9]*))?$/
const RE_INT = /^-?[0-9]*$/
const RE_UINT = /^[0-9]*$/


const validate_input = (value, min, max, mode) => {
    // Returns Number or null on success
    // returns false on failure

    if (typeof(min) !== "number") throw new Error("min: number is required");
    if (typeof(max) !== "number") throw new Error("max: number is required");
    if (!["int", "uint", "float", "ufloat"].includes(mode)) throw new Error("mode: must be one of int, uint, float, ufloat");

    // Short circuit empty
    if (value === "") return null;

    // Make sure they're valid characters
    const match = (() => {
        if (mode == "int") return RE_INT.exec(value);
        if (mode == "uint") return RE_UINT.exec(value);
        if (mode == "float") return RE_SIGNED_FLOAT.exec(value);
        if (mode == "ufloat") return RE_UNSIGNED_FLOAT.exec(value);
        throw new Error("invalid mode")
    })();
    if (!match) return false;

    // If the change will push us over min, max, reject it
    const float_value = parseFloat(value);
    if (float_value > max || float_value < min) return false;

    // Otherwise, we're good
    return float_value;
}


export const CoordinateContextProvider = ({
    children,
}) => {

    // Starting State: When we open the dialog
    const [startState, setStartState] = useState({
        ll: null,
        callback: null,
        open: false,
    });

    // Current State for maintaining controled entries
    const [state, _setState] = useState();

    // Parsing / Validation to populate state for changing tabs etc.
    // and all the errors - these get pushed into state
    const setState = useCallback((ll, input, value) => {

        // Given a DD Lat / DD Lon, update our state / errors etc for render
        const lat_ddm = ll.lat.as_ddm()
        const lon_ddm = ll.lon.as_ddm()

        const lat_dms = ll.lat.as_dms()
        const lon_dms = ll.lon.as_dms()

        let new_state = {
            latlon: ll,
            inputs: {
                dd_lat: ll.lat.as_dd(),
                dd_lon: ll.lon.as_dd(),

                ddm_lat_hemi: lat_ddm[0],
                ddm_lat_deg: lat_ddm[1],
                ddm_lat_min: lat_ddm[2],
                ddm_lon_hemi: lon_ddm[0],
                ddm_lon_deg: lon_ddm[1],
                ddm_lon_min: lon_ddm[2],

                dms_lat_hemi: lat_dms[0],
                dms_lat_deg: lat_dms[1],
                dms_lat_min: lat_dms[2],
                dms_lat_sec: lat_dms[3],

                dms_lon_hemi: lon_dms[0],
                dms_lon_deg: lon_dms[1],
                dms_lon_min: lon_dms[2],
                dms_lon_sec: lon_dms[3],
            }
        }

        if (input && value) {
            new_state.inputs[input] = value
        }

        console.log(new_state);

        _setState(new_state);
    }, [_setState]);

    // When start state changes (we clicked to open the dialog), update our current state
    useEffect(() => {
        if (!startState) return;
        setState(startState?.ll ? startState.ll.clone() : new LatLon(), true)
    }, [startState]);

    // Which tab we're on, we default to ddm
    const [tab, setTab] = useState("dd");

    const ctxValue = {
        showDialog: useCallback(
            (latlon_obj, cb) => {
                setStartState({
                    ll: latlon_obj,
                    callback: cb,
                    open: true,
                });
            }, [setStartState]),

        hideDialog: useCallback(() => { setStartState() })
    }


    // When a value changes, rebuild our DD, and then call setState with a LatLon object
    const changeDDLat = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, -90, 90, "float")
        if (parsedValue === false) return;
        setState(new LatLon(parsedValue, state.latlon.lon.value), e.target.id, value)
    }

    const changeDDLon = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, -180, 180, "float")
        if (parsedValue === false) return;
        setState(new LatLon(state.latlon.lat.value, parsedValue), e.target.id, value)
    }

    const changeLatHemi = (e) => {
        setState(new LatLon(Math.abs(state.latlon.lat.value)*(e.target.value === 'N' ? 1 : -1), state.latlon.lon.value))
    }

    // Shared by DDM/DMS
    const changeLatDeg = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, 0, 90, "uint")
        if (parsedValue === false) return;

        let components = state.latlon.lat.as_ddm()
        let new_value = parsedValue + (components[2]/60);
        setState(new LatLon(new_value, state.latlon.lon.value), e.target.id, value)
    }

    const changeDDMLatMin = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, 0, 60, "ufloat")
        if (parsedValue === false) return;

        let components = state.latlon.lat.as_ddm()
        let new_value = components[1] + (parsedValue/60);
        setState(new LatLon(new_value, state.latlon.lon.value), e.target.id, value)
    }

    const changeLonHemi = (e) => {
        setState(new LatLon(state.latlon.lat.value, Math.abs(state.latlon.lon.value)*(e.target.value === 'E' ? 1 : -1)))
    }

    // Shared by DDM/DMS
    const changeLonDeg = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, 0, 90, "uint")
        if (parsedValue === false) return;

        let components = state.latlon.lon.as_ddm()
        let new_value = parsedValue + (components[2]/60);
        setState(new LatLon(state.latlon.lat.value, new_value), e.target.id, value)
    }

    const changeDDMLonMin = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, 0, 60, "ufloat")
        if (parsedValue === false) return;

        let components = state.latlon.lon.as_ddm()
        let new_value = components[1] + (parsedValue/60);
        setState(new LatLon(state.latlon.lat.value, new_value), e.target.id, value)
    }

    const changeDMSLatMin = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, 0, 60, "uint")
        if (parsedValue === false) return;

        let components = state.latlon.lat.as_dms()
        let new_value = components[1] + (parsedValue/60) + (components[3]/3600);
        setState(new LatLon(new_value, state.latlon.lon.value), e.target.id, value)
    }

    const changeDMSLatSec = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, 0, 60, "ufloat")
        if (parsedValue === false) return;

        let components = state.latlon.lat.as_dms()
        let new_value = components[1] + (components[2]/60) + (parsedValue/3600);
        setState(new LatLon(new_value, state.latlon.lon.value), e.target.id, value)
    }

    const changeDMSLonMin = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, 0, 60, "uint")
        if (parsedValue === false) return;

        let components = state.latlon.lon.as_dms()
        let new_value = components[1] + (parsedValue/60) + (components[3]/3600);
        setState(new LatLon(state.latlon.lat.value, new_value), e.target.id, value)
    }

    const changeDMSLonSec = (e) => {
        let value = e.target.value.trim();
        let parsedValue = validate_input(value, 0, 60, "ufloat")
        if (parsedValue === false) return;

        let components = state.latlon.lon.as_dms()
        let new_value = components[1] + (components[2]/60) + (parsedValue/3600);
        setState(new LatLon(state.latlon.lat.value, new_value), e.target.id, value)
    }

    const update = (e) => {
        startState.callback(state.latlon)
    }

    const onClose = (e, reason) => {
        if (reason === "backdropClick") return;
        setStartState(null)
    }


    return (
        <CoordinateContext.Provider value={ctxValue}>
            {children}
            <Dialog open={startState?.open||false} onClose={onClose} fullWidth maxWidth="md" PaperProps={{sx: {minHeight: '420px'}}}>
                <DialogTitle>Manage Coordinates</DialogTitle>
                <DialogContent  sx={{
                    display: "flex",
                    '& .MuiOutlinedInput-root': {
                        paddingRight: '0px !important',
                    }
                }}>
                    <TabContext value={tab}>
                        <Box sx={{
                                borderRight: 1,
                                borderColor: 'divider',
                                '& .MuiTab-root': {
                                    alignItems: "start"
                                }
                        }}>
                            <TabList onChange={(e,v) => setTab(v)}  orientation="vertical" sx={{width: '240px'}} >
                                <Tab variant="small" value="dd" label="Decimal Degrees" />
                                <Tab value="ddm" label="Degrees Decimal Minutes" />
                                <Tab value="dms" label="Degrees Minutes Seconds" />
                                <Tab value="mgrs" label="MGRS" />
                                <Tab value="string" label="Load From String" />
                                <Tab value="formats" label="Formats" />
                            </TabList>
                        </Box>

                        <Stack sx={{width: '100%', height: '100%'}}>

                            <TabPanel value="dd" sx={{p: 0, pl: 1, width: '100%'}}>
                                <Stack  sx={{width: '100%', height: '100%'}}>
                                    <Typography sx={{ml:1}}>Latitude</Typography>
                                    <TextField
                                        id="dd_lat"
                                        onChange={changeDDLat}
                                        value={state?.inputs?.dd_lat||"0"}
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            endAdornment: <InputAdornment
                                                sx={{
                                                    padding: "27.5px 14px",
                                                    backgroundColor: (theme) => theme.palette.divider,
                                                    borderTopRightRadius: (theme) =>
                                                        theme.shape.borderRadius + "px",
                                                    borderBottomRightRadius: (theme) =>
                                                        theme.shape.borderRadius + "px",
                                                }}
                                                position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>&deg;</Typography></InputAdornment>,
                                        }}
                                        helperText=""
                                    />
                                    <Typography sx={{ml:1}}>Longitude</Typography>
                                    <TextField
                                        id="dd_lon"
                                        value={state?.inputs?.dd_lon||""}
                                        onChange={changeDDLon}
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            endAdornment: <InputAdornment
                                                sx={{
                                                    padding: "27.5px 14px",
                                                    backgroundColor: (theme) => theme.palette.divider,
                                                    borderTopRightRadius: (theme) =>
                                                        theme.shape.borderRadius + "px",
                                                    borderBottomRightRadius: (theme) =>
                                                        theme.shape.borderRadius + "px",
                                                }}
                                                position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>&deg;</Typography></InputAdornment>,
                                        }}
                                    />
                                    <Box sx={{height: '100%'}} />
                                </Stack>
                            </TabPanel>



                            <TabPanel value="ddm" sx={{p: 0, pl: 1, width: '100%'}}>
                                <Stack  sx={{width: '100%', height: '100%'}}>
                                    <Typography sx={{ml:1}}>Latitude</Typography>
                                    <Box sx={{display: "flex", flexGrow: 1, alignItems: "center"}}>
                                        <FormControl sx={{m:1, minWidth: '65px', width: '65px'}}>
                                            <Select
                                                    value={state?.inputs?.ddm_lat_hemi || "N"}
                                                    name="ddm_lat_hemi"
                                                    onChange={changeLatHemi}
                                            >
                                                <MenuItem value="N">N</MenuItem>
                                                <MenuItem value="S">S</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            id="ddm_lat_deg"
                                            onChange={changeLatDeg}
                                            value={state?.inputs?.ddm_lat_deg||"0"}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                            width: '15px',
                                                            justifyContent: 'center',
                                                        }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>&deg;</Typography></InputAdornment>,
                                            }}
                                            helperText=""
                                        />
                                        <TextField
                                            id="ddm_lat_min"
                                            value={state?.inputs?.ddm_lat_min||"0"}
                                            onChange={changeDDMLatMin}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                    }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>'</Typography></InputAdornment>,
                                            }}
                                        />
                                    </Box>
                                    <Typography sx={{ml:1}}>Longitude</Typography>
                                    <Box sx={{display: "flex", flexGrow: 1, alignItems: "center"}}>
                                        <FormControl sx={{m:1, minWidth: '65px', width: '65px'}}>
                                            <Select
                                                    value={state?.inputs?.ddm_lon_hemi || "E"}
                                                    name="ddm_lon_hemi"
                                                    onChange={changeLonHemi}
                                            >
                                                <MenuItem value="E">E</MenuItem>
                                                <MenuItem value="W">W</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            id="ddm_lon_deg"
                                            onChange={changeLonDeg}
                                            value={state?.inputs?.ddm_lon_deg||"0"}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        sx={{
                                                            padding: "27.5px 14px",
                                                            backgroundColor: (theme) => theme.palette.divider,
                                                            borderTopRightRadius: (theme) =>
                                                                theme.shape.borderRadius + "px",
                                                            borderBottomRightRadius: (theme) =>
                                                                theme.shape.borderRadius + "px",
                                                        }}
                                                        position="end"
                                                    >
                                                        <Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>&deg;</Typography>
                                                    </InputAdornment>,
                                            }}
                                            helperText=""
                                        />
                                        <TextField
                                            id="ddm_lon_min"
                                            value={state?.inputs?.ddm_lon_min||"0"}
                                            onChange={changeDDMLonMin}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                    }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>'</Typography></InputAdornment>,
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </TabPanel>

                            <TabPanel value="dms" sx={{p: 0, pl: 1, width: '100%'}}>
                                <Stack  sx={{width: '100%', height: '100%'}}>
                                    <Typography sx={{ml:1}}>Latitude</Typography>
                                    <Box sx={{display: "flex", flexGrow: 1, alignItems: "center"}}>
                                        <FormControl sx={{m:1, minWidth: '65px', width: '65px'}}>
                                            <Select
                                                    value={state?.inputs?.dms_lat_hemi || "N"}
                                                    name="dms_lat_hemi"
                                                    onChange={changeLatHemi}
                                            >
                                                <MenuItem value="N">N</MenuItem>
                                                <MenuItem value="S">S</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            id="dms_lat_deg"
                                            onChange={changeLatDeg}
                                            value={state?.inputs?.dms_lat_deg||"0"}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                            width: '15px',
                                                            justifyContent: 'center',
                                                        }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>&deg;</Typography></InputAdornment>,
                                            }}
                                            helperText=""
                                        />
                                        <TextField
                                            id="dms_lat_min"
                                            value={state?.inputs?.dms_lat_min||"0"}
                                            onChange={changeDMSLatMin}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                    }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>'</Typography></InputAdornment>,
                                            }}
                                        />
                                        <TextField
                                            id="dms_lat_sec"
                                            value={state?.inputs?.dms_lat_sec||"0"}
                                            onChange={changeDMSLatSec}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                    }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>"</Typography></InputAdornment>,
                                            }}
                                        />
                                    </Box>
                                    <Typography sx={{ml:1}}>Longitude</Typography>
                                    <Box sx={{display: "flex", flexGrow: 1, alignItems: "center"}}>
                                        <FormControl sx={{m:1, minWidth: '65px', width: '65px'}}>
                                            <Select
                                                    value={state?.inputs?.dms_lon_hemi || "E"}
                                                    name="dms_lon_hemi"
                                                    onChange={changeLonHemi}
                                            >
                                                <MenuItem value="E">E</MenuItem>
                                                <MenuItem value="W">W</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            id="dms_lon_deg"
                                            onChange={changeLonDeg}
                                            value={state?.inputs?.dms_lon_deg||"0"}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                            width: '15px',
                                                            justifyContent: 'center',
                                                        }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>&deg;</Typography></InputAdornment>,
                                            }}
                                            helperText=""
                                        />
                                        <TextField
                                            id="dms_lon_min"
                                            value={state?.inputs?.dms_lon_min||"0"}
                                            onChange={changeDMSLonMin}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                    }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>'</Typography></InputAdornment>,
                                            }}
                                        />
                                        <TextField
                                            id="dms_lon_sec"
                                            value={state?.inputs?.dms_lon_sec||"0"}
                                            onChange={changeDMSLonSec}
                                            sx={{ m: 1, width: '50%' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    sx={{
                                                        padding: "27.5px 14px",
                                                        backgroundColor: (theme) => theme.palette.divider,
                                                        borderTopRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                        borderBottomRightRadius: (theme) =>
                                                            theme.shape.borderRadius + "px",
                                                    }}
                                                    position="end"><Typography sx={{width: '10px', textAlign: 'center', fontSize: "20px"}}>"</Typography></InputAdornment>,
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{height: '100%'}} />
                                </Stack>
                            </TabPanel>


                            <TabPanel value="mgrs">
                                MGRS
                            </TabPanel>


                            <TabPanel value="formats" sx={{p: 0, pl: 1, width: '100%'}}>
                                <Grid>
                                    <Box>
                                        <Typography sx={{color: "#444"}}>Decimal Degrees</Typography>
                                        <Typography sx={{ml: 2, mb:2}}>{state?.latlon?.format_dd() || "Unknown"}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{color: "#444"}}>Degrees Decimal Minutes</Typography>
                                        <Typography sx={{ml: 2, mb: 2}}>{state?.latlon?.format_ddm() || "Unknown"}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{color: "#444"}}>Degrees Minutes Seconds</Typography>
                                        <Typography sx={{ml: 2, mb: 2}}>{state?.latlon?.format_dms() || "Unknown"}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{color: "#444"}}>Combat Flite</Typography>
                                        <Typography sx={{ml: 2, mb:2}}>{state?.latlon?.format_ddm(3) || "Unknown"}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{color: "#444"}}>MGRS</Typography>
                                        <Typography sx={{ml: 2, mb:2}}>{state?.latlon?.format_mgrs() || "Unknown"}</Typography>
                                    </Box>
                                </Grid>

                            </TabPanel>

                            <Box sx={{height: '100%', backgroundColor: 'blue'}} />

                            <Box sx={{display: "flex", justifyContent: "end"}}>
                                <Button onClick={onClose}>CLOSE</Button>
                                <Button onClick={update}>Update</Button>
                            </Box>
                        </Stack>


                    </TabContext>
                </DialogContent>
            </Dialog>
        </CoordinateContext.Provider>
    )
};