import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAPI } from "../hooks/useAxios"


export function AuthenticationTATTableElement({
    title,
    version,
    rows,
}) {

    const rows_title = 'ABCDEFGHIJKLMNOPQRSTUVWXY'.split('');

    // We'll just munge the rows here to strings
    let real_rows = [];
    const counts = [4,3,3,2,2,3,2,2,2,2]
    for (const row_data of rows) {
        let row = []
        let offset = 0;

        for (const count of counts) {
            let elem = ""
            for (let i = 0; i < count; i++) {
                elem = elem + row_data[offset];
                offset++;
            }
            row.push(elem)
        }
        real_rows.push(row);
    }

    return (
        <Box sx={{ fontFamily: 'monospace', fontSize: 20, lineHeight: '12px', textAlign: "center" }}>
            <table cellPadding={'4px'} cellSpacing={0} border={0} style={{border: "1px solid black" }}>
                <colgroup>
                    <col style={{width: '110px'}} />
                    <col style={{width: '110px'}} />
                    <col style={{width: '110px'}} />
                    <col style={{width: '110px'}} />
                    <col style={{width: '110px'}} />
                    <col style={{width: '110px'}} />
                </colgroup>
                <tbody>
                    <tr>
                        <td colSpan={6} style={{textAlign: "center"}}>{title}</td>
                    </tr>
                    <tr>
                        <td colSpan={6} style={{textAlign: "right", lineHeight: "24px", borderBottom: "1px solid black"}}>Version: {version}</td>
                    </tr>
                    <tr style={{height: "90px", verticalAlign: "top", fontWeight: "bold", textAlign: "center", lineHeight: "20px"}}>
                        <td style={{borderBottom: "1px solid black", borderRight: "1px solid black"}}>0000<br/>0600<br/>1200<br/>1800</td>
                        <td style={{borderBottom: "1px solid black", borderRight: "1px solid black"}}>0100<br/>0700<br/>1300<br/>1900</td>
                        <td style={{borderBottom: "1px solid black", borderRight: "1px solid black"}}>0200<br/>0800<br/>1400<br/>2000</td>
                        <td style={{borderBottom: "1px solid black", borderRight: "1px solid black"}}>0300<br/>0900<br/>1500<br/>2100</td>
                        <td style={{borderBottom: "1px solid black", borderRight: "1px solid black"}}>0400<br/>1000<br/>1600<br/>2200</td>
                        <td style={{borderBottom: "1px solid black"}}>0500<br/>1100<br/>1200<br/>2300</td>
                    </tr>
                    {rows.map((x, idx) =>
                        <tr key={`tr_${idx}`}>
                            {x.map((y, yidx) => <td key={`td_${idx}_${yidx}`} style={{borderRight: yidx == 5 ? "0" : "1px solid black", paddingLeft: "12px", paddingRight: "12px"}}>{(idx*2).toString().padStart(2, "0")} - {y}</td>)}
                        </tr>
                    )}

                </tbody>
            </table>
        </Box>
    )
}

export function CreateTATTableDialog({
    api_endpoint,
    open,
    onCreate,
    onClose: parentOnClose,
}) {

    const axios = useAPI();

    const [form_state, set_form_state] = useState({});
    const [error, set_error] = useState(null);

    const update_form_state = (key, value) => {
        set_form_state({...form_state, [key]: value})
    }

    const submit = () => {
        let title = (form_state?.title || "").trim()
        if (title === "") {
            set_error("you must supply a title")
            return;
        }

        axios.post(
            api_endpoint,
            form_state
        ).then((res) => {
            if (!res?.data) return;
            onCreate(res.data);
        })
    }

    const onClose = () => {
        set_form_state({});
        set_error(null);
        parentOnClose();
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Generate TAT Table</DialogTitle>
            <DialogContent sx={{paddingBottom: 0}}>
                <Grid sx={{display: "grid", gridTemplateRows: 'auto', gridTemplateColumns: 'repeat(3, 1fr)', p: 1, gridGap: '15px' }}>
                    <TextField
                        onChange={(e) => { update_form_state(e.target.name, e.target.value) }}
                        sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        name="title"
                        error={!!error}
                        helperText={error||" "}
                        required label="Title (eg: Operation Exercise)"
                        InputLabelProps={{shrink: true}} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={submit}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}


export default function AuthenticationTATTable() {

    const axios = useAPI()
    const params = useParams()

    const [state, setState] = useState();
    const [showCreateDialog, setShowCreateDialog] = useState(false);

    useEffect(() => {
        axios.get(`/campaigns/${params.campaign_id}/auth/tat`).then((res) => {
            if (!res?.data) return;
            setState(res.data);
            setShowCreateDialog(false);
        })
    }, []);

    // Just draw a fixed table since there's no editing here
    return (
        <Box>
            <AuthenticationTATTableElement title={state?.title} rows={state?.data||[]} version={state?.version} />

            <Box sx={{display: "flex", justifyContent: "end", width: "660px"}}>
                <Button onClick={() => setShowCreateDialog(true)}>{state ? "Update" : "Create" }</Button>
            </Box>


            <CreateTATTableDialog
                api_endpoint={`/campaigns/${params.campaign_id}/auth/tat/create`}
                open={showCreateDialog}
                onClose={() => { setShowCreateDialog(false) }}
                onCreate={(data) => { setState(data); setShowCreateDialog(false); }}
            />
        </Box>
    )
}