import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import axios from 'axios';
import React, { createContext, useState, useRef, useContext, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from '../Constants';
import { useShowDialog } from '../hooks/useErrorDialog';

export const AxiosContext = createContext(null);

export const AxiosInstanceProvider = ({
    children,
}) => {

    const showError = useShowDialog();

    const params = {withCredentials: true, ...config.api};
    const instanceRef = useRef(axios.create(params));

    const navigate = useNavigate();
    const location = useLocation();

    // Context State
    const [state, setState] = useState({
        api: instanceRef.current,
        auth: null,
    })

    const setAuthState = (authState) => {
        setState({...state, auth: authState});
    }

    // Failed Auth Callback: This is called on 401 (most likely when our JWT expires), and will try to use a refresh
    // Token to obtain a new jWT , and failing that, fail
    const refreshAuthLogic = useCallback(async (failedRequest) => {
        return instanceRef.current.get('/auth/token/refresh').then((res) => {

            // If we have no refresh, we failed
            if (!res) Promise.reject();

            // Now we process the token, to find out who we are and set the login state accordingly
            return process_access_token(res.data).then((res) => {
                if (!res) return Promise.reject();

                // Update our authorization header to match new requests from process_access_token
                failedRequest.response.config.headers['Authorization'] = instanceRef.current.defaults.headers.common['Authorization']

                // Then retry
                return instanceRef.current(failedRequest.response.config);
            })
        })
    }, [instanceRef]);

    // When we have a new access token, refresh our auth info from the DB
    const process_access_token = useCallback(async (token_response) => {

        if (!token_response || !token_response.access_token) return Promise.resolve(false);

        // We now have an access token, lifetime, and need to update our context to use it each request
        instanceRef.current.defaults.headers.common['Authorization'] = `Bearer ${token_response.access_token}`;

        // Now lookup who I am, and set our login state and redirect back to where I came from
        return instanceRef.current.get('/user/me').then((res) => {
            setAuthState({
                'info': res.data,
                'logout': logout,
                'isAdmin': res.data.admin == "True",
            });
            navigate(location.pathname || '/');

            return Promise.resolve(true);
        });
    }, [instanceRef, setAuthState]);


    // Logout
    const logout = useCallback(() => {
        instanceRef.current.get('/auth/token/logout').then((res) => {
            if (!res) return;
            setAuthState(null);
        });
        navigate("/")
    }, [setAuthState, instanceRef]);


    // Setup our Auth Callback, used by the static auth_redirect.html
    useEffect(() => {
        const authCallbackHandler = event => {
            // We have a few things here, first we should have state to point to our provider, and code
            // if we have code, then we can verify it to our API and update our access token, otherwise
            // if we have error / error_discription we can set our global error dialog to that
            const params = event.detail;
            if (!params.state) return;

            if (params.state === "discord") {
                if (params.error) {
                    showError(`Discord Error:  ${params.error}`, params.error_description);
                    return
                }

                if (!params.code) {
                    showError(`Discord Error: No Code`, "Unable to continue with authentication as we did not receive the expected response from Discord API");
                    return
                }

                const token_params = {
                    code: params.code,
                    redirect_uri: params.redirect_url,
                }

                // Otherwise we push off and try and get our login
                instanceRef.current.get('/auth/discord/redirect', { params: token_params }).then((res) => {
                    if (!res) return;
                    process_access_token(res.data);
                })
                return;
            };

            if (params.state === "force_login") {
                instanceRef.current.get(`/auth/force_login/${params.user_id}`).then((res) => {
                    if (!res) return;
                    process_access_token(res.data);
                })
                return;
            }

            showError(`Error: Unknown provider`, `Got an unexpected provider: ${state.auth}`);
        };

        // Watch for our auth events
        window.addEventListener('authCallbackEvent', authCallbackHandler);

        // See if we're already authenticated
        instanceRef.current.get('/auth/token/refresh').then((res) => {
            if (!res) return;
            process_access_token(res.data);
        });

        return () => {
            window.removeEventListener('authCallbackEvent', authCallbackHandler);
        }
    }, []);


    // Setup our error handling interceptors
    instanceRef.current.interceptors.response.use((response) => response, (error) => {
        console.log(error)
        // We capture all 422 errors as these are generic information
        if (error?.response?.status === 422) {

            let issues = [];

            for (const item of error.response.data.detail) {
                issues.push(<li>{item.msg}: {item.loc[item.loc.length-1]}</li>)
            }

            showError(
                'Validation Error',
                <>
                    There were errors encountered in the following items:
                    <ul>
                        {issues}
                    </ul>
                </>);

        } else if (error?.response?.status === 409) {
            // 409 is our general error, database, or such
            showError('Request Error', error.response.data.detail)
        } else if (error?.response?.status === 403) {
            showError('Forbidden', error.response.data.detail)
        } else if (error?.response?.status === 401) {
            // 401 unauthorized, if we have a token, it probably needs a refresh try to do that, or fail
            return refreshAuthLogic(error).catch(() => {
                showError('Authorization Error', 'Failed to authorize, please login again');
                setAuthState(null);
                navigate('/');
                return Promise.resolve(null);
            })
        } else if (error.code === 'ERR_NETWORK' || [504, 502].includes(error?.response?.status)) {
            // General API Access issues, 504 timeout, 502 bad gateway
            showError('Network Error', 'Unable to communicate with the API');
        } else if (error?.response) {
            // All other requests are uncaught expceptions
            showError(`Uncaught Exception: ${error?.response?.status}`, JSON.stringify(error.response.data));
        } else {
            showError('Uncaught Exception', error.message)
        }

        return Promise.resolve(null);
    });


    return (
        <AxiosContext.Provider value={state}>
            {children}
        </AxiosContext.Provider>
    );

};
