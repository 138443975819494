import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPro, GridFooter, GridFooterContainer, useGridApi, useGridApiRef } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useMemo, useState } from "react";
import { AxiosContext } from "../../contexts/AxiosContext";
import { useAPI } from "../../hooks/useAxios";

function CustomFooter() {
    return (
      <GridFooterContainer sx={{justifyContent: "end"}}>
        <Button>Add Range</Button>
      </GridFooterContainer>
    );
  }


export default function DataAirframeDetailRadioInfo({
    airframe_id,
    radio_info,
}) {

    const axios = useAPI();

    const [inputs, set_inputs] = useState({
        ref: radio_info?.ref
    });
    const [preset_rows, set_preset_rows] = useState([]);
    const [range_rows, set_range_rows] = useState([]);
    const preset_api_ref = useGridApiRef();
    const range_api_ref = useGridApiRef();

    const toint = (x) => parseInt(x)

    const preset_cols = [
        {field: 'display_name', headerName: 'Display Name', flex: 0.35, sortable: false },
        {field: 'mdc_name', headerName: 'MDC Name', flex: 0.25, sortable: false, editable: true, align:"right" },
        {field: 'frequency', headerName: 'Frequency (MHz)', flex: 0.4, type: 'number', sortable: false, editable: true, align:"right",
            valueFormatter: (elem) => {
                return (elem.value.khz/1000).toFixed(3)
            }}
    ]
    const preset_cols_api = Object.assign({}, ...preset_cols.map((x) => ({[x.field]: x.apiFormatter})));

    const range_cols = [
        {field: 'start_khz', headerName: 'Range Start (Khz)', type: 'number', apiFormatter: toint, flex: 1, sortable: false, editable: true },
        {field: 'end_khz', headerName: 'Range End (Khz)', type: 'number', apiFormatter: toint, flex: 1, sortable: false, editable: true },
        {field: 'step_khz', headerName: 'Step', type: 'number', apiFormatter: toint, flex: 0.5, sortable: false, editable: true }
    ]
    const range_cols_api = Object.assign({}, ...range_cols.map((x) => ({[x.field]: x.apiFormatter})));

    useEffect(() => {

        // Process our frequency range columns
        set_range_rows([...(radio_info?.ranges || []).sort((a, b) => a.start_khz < b.start_khz)]);

        // Process our presets
        let presets = [];
        for (const elem of (radio_info?.presets || []).sort((a, b) => a.preset_id < b.preset_id)) {
            presets.push(elem)
        }
        set_preset_rows(presets);

    }, [radio_info])

    const onInputChange = (event) => {
        let new_inputs = {...inputs};
        new_inputs[event.target.name] = event.target.value;
        set_inputs(new_inputs);
    }

    const onInputBlur = (event) => {
        if (inputs[event.target.name] !== radio_info[event.target.name]) {
            let patch = {}
            let value = inputs[event.target.name];
            patch[event.target.name] = value;
            axios.patch(`/airframe/${airframe_id}/radio/${radio_info.id}`, patch).then((res) => {
                if(!res) return;
                radio_info[event.target.name] = value;
            });
        }
    }

    const handleProcessRowUpdateRange = (newRow, oldRow) => {
        let patch = {}

        for (const[k, v] of Object.entries(newRow)) {
            if (oldRow[k] !== v) patch[k] = (range_cols_api[k] || ((x) => x))(v);
        }

        // No patch, no go
        if (Object.keys(patch).length == 0) return oldRow;

        // SHouldn't happen
        if (patch.id !== undefined) return oldRow;

        return axios.patch(`/airframe/${airframe_id}/radio/${radio_info.id}/range/${oldRow.id}`, patch).then((res) => {
            if (!res?.data?.ok) return oldRow
            return newRow
        });
    }

    const handleProcessRowUpdatePreset = (newRow, oldRow) => {
        let patch = {}

        for (const[k, v] of Object.entries(newRow)) {
            if (oldRow[k] !== v) patch[k] = (preset_cols_api[k] || ((x) => x))(v);
        }

        // No patch, no go
        if (Object.keys(patch).length == 0) return oldRow;

        // This shouldn't happen
        if (patch.preset_id !== undefined) return oldRow;

        return axios.patch(`/airframe/${airframe_id}/radio/${radio_info.id}/preset/${oldRow.preset_id}`, patch).then((res) => {
            if (!res?.data?.ok) return oldRow
            return newRow
        });
    }

    return (
        <div style={{ width: '100%' }}>
        <Box sx={{display: 'flex'}}>
            <TextField name="ref" onBlur={onInputBlur} onChange={onInputChange} sx={{m: 1}} fullWidth value={inputs.ref} label="MDC Prefix (e.g: PRI-2 / U-12 / V-6)" InputLabelProps={{shrink: true}} />
            {radio_info.dcs_id === null &&
                <TextField sx={{m: 1}}  fullWidth label="DCS Directory Name (e.g: VHF_RADIO)" InputLabelProps={{shrink: true}} /> }
        </Box>

        <Box sx={{display: 'flex'}}>
            <DataGridPro
                sx={{m: 1}}
                columns={preset_cols}
                rows={preset_rows}
                processRowUpdate={handleProcessRowUpdatePreset}
                components={{Footer: CustomFooter}}
                disableSelectionOnClick disableColumnMenu
                autoHeight
                experimentalFeatures={{ newEditingApi: true }}
            />

            <DataGridPro
                sx={{m: 1}}
                columns={range_cols}
                rows={range_rows}
                processRowUpdate={handleProcessRowUpdateRange}
                components={{Footer: CustomFooter}}
                disableSelectionOnClick disableColumnMenu
                autoHeight
                experimentalFeatures={{ newEditingApi: true }}
            />
        </Box>
        </div>
    )



}