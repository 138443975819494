const prod = {
    api: {
        baseURL: "/api",
    },
    auth: {
        discord: {
            CLIENT_ID: "1038030490562723880",
        }
    }
}

export const config = prod;
export const KG_TO_LBS = 2.20462262185;
export const M_TO_FT = 3.28084;
export const NM_TO_M = 1852;
export const API_LATLON_DD_POINTS = 7