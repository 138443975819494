import { GridCellModes } from "@mui/x-data-grid-pro";

export const rowEditTabHandler = (apiRef, id, field) => (e) => {
    // Inspired by https://github.com/mui/mui-x/issues/3016#issuecomment-1111366679
    if (!(e.key === 'Tab' && id === 0)) return;

    // Find the list of editable columns, and our index in it
    const editableColumns = apiRef.current.getVisibleColumns().filter((x) => x.editable)
    const ourIndex = editableColumns.findIndex((x) => x.field === field);

    // Get our next or previous cell (if shift)
    let nextIndex = ourIndex + (e.shiftKey ? -1 : 1)
    nextIndex = nextIndex > editableColumns.length - 1 ? 0 : (nextIndex < 0 ? editableColumns.length - 1 : nextIndex);

    const nextField = editableColumns[nextIndex].field;
    //if (apiRef.current.getCellMode(id, nextField) !== GridCellModes.Edit) return;

    const focusElement = apiRef.current.getCellElement(id, nextField).querySelector('[tabindex = "0"]')
    if (focusElement) { focusElement.focus() }

    e.preventDefault();
}