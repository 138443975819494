export class Frequency {

    constructor(khz, modulation) {
        // We maintain KHz with only khz and mhz for display
        this._khz = khz;

        // AM / FM ?
        this.modulation = modulation || "AM";
    }

    get mhz() {
        return this._khz ? (this._khz / 1000).toFixed(3) : "";
    }

    get khz() {
        return this._khz ? this._khz.toFixed(3) : "";
    }

    set khz(value) {
        this._khz = value;
    }

    conforms(expected_bands) {

        // Returns: [(warning|error), message], null

        // If we haven't got any expected bands use a nice wide range
        expected_bands ??= [
            [30_000,   87_975, 50],
            [100_000, 173_975, 50],
            [225_000, 399_975, 50],
        ]

        let matched = false;
        let instep = true;

        for (const [start, end, step] of expected_bands) {
            if (this._khz > start && this._khz < end) {
                matched = true;
                instep = this._khz % step === 0;
                break;
            }
        }

        if (matched && instep) return null;
        if (!matched) return [1, "Frequency outside of expected ranges"];
        if (!instep) return [1, "Recommend 50khz separation"];
        return null;
    }

    toString() {
        if (this.display_as_khz) return `${this.khz} ${this.modulation}`
        return `${this.mhz} ${this.modulation}`
    }

    toJSON() {
        return {
            khz: this._khz,
            modulation: this.modulation,
        }
    }

    static from_object(obj) {
        return new Frequency(obj.khz, obj.modulation);
    }

}