import './App.css';
import { AxiosInstanceProvider } from './contexts/AxiosContext';
import { DialogContextProvider, ErrorContextProvider } from './contexts/DialogContext';

import {
  Routes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import DummyProfile from './dummy_pages/dummy_profile';
import { useRef } from 'react';
import { ProtectedRoute } from './components/ProtectedRoute';
import HomePage from './pages/HomePage';
import LoginPage from './pages/Login/LoginPage';
import DataPage from './pages/Data/DataPage';
import WingPage from './pages/Wings/WingsPage';
import CampaignRouter from './pages/Campaign/CampaignRouter';
import WingRouter from './pages/Wings/WingRouter';
import { CoordinateContextProvider } from './contexts/CoordinateContextProvider';
import TestPage from './pages/TestPage';
import TestPage2 from './pages/TestPage2';
import { LicenseInfo } from '@mui/x-data-grid-pro';



LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE)

function App() {

  const scrollRef = useRef();

  return (
    <Router>
      <DialogContextProvider>
        <CoordinateContextProvider>
          <AxiosInstanceProvider>
            <div style={{height: "100vh", width: "100%", minWidth: "var(--wm-min-width)", overflowY: "auto", scrollSnapType: "y mandatory", scrollBehavior: "smooth" }} ref={scrollRef}>
              <Routes>
                <Route path="/" element={<HomePage forwardRef={scrollRef} />} />
                <Route path="/login" element={<LoginPage />} />

                <Route path="/user/profile" element={<ProtectedRoute><DummyProfile/></ProtectedRoute>} />

                <Route path="/data/*" element={<ProtectedRoute><DataPage/></ProtectedRoute>} />

                <Route path="/campaigns/*" element={<ProtectedRoute><CampaignRouter/></ProtectedRoute>} />

                <Route path="/wings" element={<ProtectedRoute><WingPage/></ProtectedRoute>} />
                <Route path="/wings/:wing_id/*" element={<ProtectedRoute><WingRouter/></ProtectedRoute>} />

                <Route path="/test" element={<TestPage2/>} />

              </Routes>
            </div>
          </AxiosInstanceProvider>
        </CoordinateContextProvider>
      </DialogContextProvider>
    </Router>
  );
}

export default App;
