import TopBar from "../../layout/TopBar";
import DataAirframesRouter from "./DataAirframesRouter";
import SideBarLayout from "../../layout/SideBarLayout"

export default function DataPage() {

    return (
        <>
        <TopBar logoBackgroundColor="var(--wm-color-sidebar)" />
        <SideBarLayout config={[
            { prefix: "", redirect: "airframes" },
            {
                title: 'Airframes',
                prefix: "airframes",
                fetch: '/airframes',
                element: <DataAirframesRouter />,
            },
            {
                title: 'Theatres',
                prefix: "theatres",
                element: <>Theatres</>,
            },
            {
                title: 'Threats',
                prefix: "threats",
                element: <>Threats</>
            },
        ]} />
        </>
    )
}