import { AppBar, Avatar, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Menu, MenuItem, Tab, Tabs, Toolbar } from "@mui/material"
import TopBarContainer from "./TopBarContainer"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, {  useContext, useMemo, useRef, useState } from "react";
import { AxiosContext } from "../contexts/AxiosContext";
import { Link, useLocation } from "react-router-dom";
import { DiscordLoginButton } from "../components/DiscordLoginButton";
import axios from "axios";
import { useAPI, useAuth, useAxios } from "../hooks/useAxios";


const UserProfile = React.forwardRef(({user, anchor, children}, ref) => {

    const userRef = useRef();
    const auth = useAuth()

    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const closeProfileMenu = () => { setShowProfileMenu(false); }
    const openProfileMenu = () => { setShowProfileMenu(true); }

    const doLogout = () => {
        auth.logout();
    };

    //<div style={{ position: "absolute", display: "inline-block", verticalAlign: "middle", background: "", right: "20px", top: "10px", cursor: "pointer"}} >
    return (
        <div style={{ position: "relative", top: "8px", cursor: "pointer"}} >
            <div onClick={openProfileMenu} ref={userRef}>
                <div style={{display: "inline-block"}}><Avatar sx={{ width: 30, height: 30 }}/></div>
                <div style={{display: "inline-block", position: "relative", top: "-5px", fontFamily: "Roboto", fontSize: "16px", paddingLeft: "7px"}}>{user?.info?.username}</div>
                <div style={{display: "inline-block", position: "relative", top:"1px"}}>
                    <ArrowDropDownIcon/>
                </div>
            </div>
            <Menu
                disableAutoFocusItem
                anchorEl={userRef.current}
                anchorOrigin={{
                    vertical: 35,
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{sx: { width: '170px', position: "relative" }}}
                open={showProfileMenu}
                onClick={closeProfileMenu}
                onClose={closeProfileMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem component={Link} to='/user/profile'>Profile</MenuItem>
                <MenuItem onClick={doLogout}>Logout</MenuItem>
            </Menu>
            {children}
        </div>
    )
});

const UserLogin = React.forwardRef(({anchor, children}, ref) => {

    const [showLoginModal, setShowLoginModal] = useState(false);
    const axios = useAPI()

    const onClick = (e) => {
        setShowLoginModal(true);
    }

    const onForceLogin = (e) => {
        // We create a custom event here instead
        window.dispatchEvent(new CustomEvent('authCallbackEvent', {
            'detail': {
                state: 'force_login',
                user_id: 1,
                redirect_url: 'http://localhost',
            }
        }));

        setShowLoginModal(false);
    }

    const onClose = (e) => {
        setShowLoginModal(false);
    }

    return (
        <div style={{ position: "relative", top: "8px", cursor: "pointer", paddingRight: '10px'}} >
            <div onClick={onClick} style={{cursor: "pointer"}}>
                <div style={{display: "inline-block"}}><Avatar sx={{ width: 30, height: 30 }} /></div>
                <div style={{display: "inline-block", position: "relative", top: "-5px", fontFamily: "Roboto", fontSize: "16px", color: "#444", paddingLeft: "7px"}} >Login / Register</div>
            </div>
            <Menu
                anchorEl={anchor.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{sx: {width: '300px', position: "relative", zIndex: 9999}}}
                open={showLoginModal}
                onClose={onClose}
                disableAutoFocusItem
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem disabled>
                    <div style={{width: "100%", whiteSpace: "normal"}}>
                        We have decided to keep things simple, and not handle any of your personal information or security details.
                        <br/><br/>
                        Instead we have gone well known providers to keep things simple. We only store your
                        external ID, Thumbnail, and initial username (until you change it here)
                        <br/><br/>
                    </div>
                </MenuItem>
                <MenuItem component={DiscordLoginButton} onClickedCallback={onClose} >Login with Discord</MenuItem>
                {process.env.NODE_ENV == "development" &&
                <MenuItem onClick={onForceLogin}>Force Login</MenuItem>
                }
            </Menu>
            {children}
        </div>
    )
});

export default function TopBar({
    logoBackgroundColor,
}) {

    const auth = useAuth();
    const topBarRef = useRef();
    const location = useLocation();

    const tabs = [
        '/wings',
        '/campaigns',
        '/events',
        '__unset__',
        '/data'
    ]

    const activeTab = useMemo(() => {
        for (var i = 0; i < tabs.length; i++) {
            if(location.pathname.startsWith(tabs[i])) {
                return i;
            }
        }
        return false;
    }, [location]);

    return (
        <TopBarContainer forwardRef={topBarRef} logoBackgroundColor={logoBackgroundColor}>
            {auth &&
            <Tabs value={activeTab}>
                <Tab component={Link} to="/wings" label="Wings" />
                <Tab component={Link} to="/campaigns" label="Campaigns" />
                <Tab label="Events" />
                <Tab sx={{minWidth: 0}} label="" icon={<Divider orientation='vertical' />} disabled />
                <Tab component={Link} to="/data/airframes" label="Data" />
            </Tabs>
            }

            <div style={{display: 'inline-block', flexGrow: 100, minWidth: '10px'}} />

            <Tabs value={location?.pathname?.startsWith('/user/profile') ? 0 : false }>
                <Tab sx={{width: "auto", height: "100%", backgroundColor: "blue"}} component={auth ? UserProfile : UserLogin} user={auth} anchor={topBarRef} />
            </Tabs>

        </TopBarContainer>
    )
}