import { Accordion as MuiAccordian, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import TabPanel from '@mui/lab/TabPanel';

import { DataGridPro } from "@mui/x-data-grid-pro";
import { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TabContext, TabList } from "@mui/lab";
import DataAirframeDetailRadioInfo from "./DataAirframeDetailRadioInfo";
import styled from "@emotion/styled";
import { InfoOutlined } from "@mui/icons-material";
import BreadcrumbLayout from "../../layout/BreadcrumbLayout";
import { useAPI } from "../../hooks/useAxios";


const Accordion = styled((props) => (
    <MuiAccordian disableGutters elevation={1}  {...props} />
  ))(({ theme }) => ({
    border: `1px solid #ccc`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
        display: 'block',
    },
}));

export default function DataAirframeDetailPage() {

    const params = useParams();
    const axios = useAPI()

    // Main Data Container
    const [state, setState] = useState(null);

    const [weight_grid_rows, set_weight_grid_rows] = useState([]);
    const [roles_grid_rows, set_roles_grid_rows] = useState([]);
    const [radio_tab, set_radio_tab] = useState("0");

    // Dialogs
    const [open_radio_dialog, set_open_radio_dialog] = useState(false)

    // Which Accordian is open
    const [expanded, setExpanded] = useState(null);
    const handleExpansion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };


    useEffect(() => {
        axios.get(`/airframe/${params.airframe_id}`).then((res) => {
            console.log(res.data);
            setState(res.data);
        })
    }, []);

    const breadcrumbs = [
        { title: 'Airframes', target: ".." },
        { title: state?.display_name },
    ];

    // Weights
    const weight_grid_columns = [
        {field: "type", headerName: "Description", flex: 1, sortable: false},
        {field: "value", headerName: "Weight (Kg)", type: "number", flex: 0.25, editable: true, sortable: false},
    ]

    const roles_grid_columns = [
        {field: "name", headerName: "Role", flex: 1, sortable: false},
        {field: "abbrev", headerName: "Abbreviation", flex: 0.25, editable: true, sortable: false},
    ]


    useEffect(() => {

        if (!state) {
            set_weight_grid_rows([]);
            set_roles_grid_rows([]);
            return
        }

        // Weights
        let rows = [
            {field: "weight_empty_kg", headerName: "Empty Weight", sortable: false},
            {field: "weight_ammo_kg", headerName: "Max Internal Ammo", sortable: false},
            {field: "weight_fuel_kg", headerName: "Max Internal Fuel", sortable: false},
            {field: "weight_mtow_kg", headerName: "Max Takeoff Weight (Land)", sortable: false},
            {field: "weight_mlw_kg", headerName: "Max Landing Weight (Land)", editable: true, sortable: false},
            {field: "weight_mtow_cvn_kg", headerName: "Max Takeoff Weight (Carrier)", editable: true, sortable: false},
            {field: "weight_mlw_cvn_kg", headerName: "Max Landing Weight (Carrier)", editable: true, sortable: false},
        ];

        let weights = []
        rows.forEach((x, k) => {
            weights.push({
                id: k,
                index: k,
                type: x.headerName,
                field: x.field,
                value: state[x.field],
                editable: x.editable || false,
            })
        });

        set_weight_grid_rows(weights);

        let roles = [];

        (state?.roles || []).sort((a, b) => {console.log(a, b); return a.display_order - b.display_order}).forEach((k, idx) => {
            k._id = k.id;
            k.id = idx;
            roles.push(k)
        });
        set_roles_grid_rows(roles);

        // If we have a radio, set our RadioTab
        if (state?.radios.length > 0) set_radio_tab("0");

    }, [state]);

    // updates
    const handleWeightCellCommit = (cell_params, event) => {
        // We always reject the commit, and predicate it on the API response
        event.defaultMuiPrevented = true;

        let patch = {}
        patch[weight_grid_rows[cell_params.id].field] = cell_params.value;
        axios.patch(`/airframe/${params.airframe_id}`, patch).then((res) => {
            if (!res?.data?.ok) return
            weight_grid_rows[cell_params.id].value = cell_params.value;
            set_weight_grid_rows([...weight_grid_rows])
        });
    }

    const handleRollCellCommit = (cell_params, event) => {
        // We always reject the commit, and predicate it on the API response
        event.defaultMuiPrevented = true;

        let patch = {}
        patch[cell_params.field] = cell_params.value

        axios.patch(`/airframe/${params.airframe_id}/roles/${roles_grid_rows[cell_params.id]._id}`, patch).then((res) => {
            if (!res?.data?.ok) return
                roles_grid_rows[cell_params.id][cell_params.field] = cell_params.value;
                set_roles_grid_rows([...roles_grid_rows])
            });
    }


    if (!state) return <></>;

    // If we have our state then we can show the airframe detail page and determine our doodah
    return <BreadcrumbLayout breadcrumbs={breadcrumbs}>
        <Box sx={{
            '& .MuiDataGrid-cell--editable': {
                borderBottom: '2px solid #999 !important',
            },
        }}>

            <Accordion expanded={expanded === 'weights'} onChange={handleExpansion('weights')}>
                <AccordionSummary>Weights</AccordionSummary>
                <AccordionDetails>
                    <DataGridPro
                        rows={weight_grid_rows}
                        columns={weight_grid_columns}
                        isCellEditable={(params) => params.row.editable}
                        onCellEditCommit={handleWeightCellCommit}
                        disableSelectionOnClick disableColumnMenu hideFooter autoHeight />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'roles'} onChange={handleExpansion('roles')}>
                <AccordionSummary>Roles</AccordionSummary>
                <AccordionDetails>
                    <DataGridPro
                        rows={roles_grid_rows}
                        columns={roles_grid_columns}
                        onCellEditCommit={handleRollCellCommit}
                        disableSelectionOnClick disableColumnMenu hideFooter autoHeight />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'radios'} onChange={handleExpansion('radios')}>
                <AccordionSummary>Radios</AccordionSummary>
                <AccordionDetails>
                    <Box sx={{
                        display: "flex",
                        width: '100%',
                        '& .MuiTabPanel-root': {
                            padding: 0,
                            paddingLeft: '10px',

                        }
                    }}>
                        <TabContext value={radio_tab}>
                            <TabList
                                orientation="vertical"
                                onChange={(e,v) => set_radio_tab(v)}
                                sx={{
                                    width: '35%',
                                    borderRight: 1,
                                    borderColor: 'divider' }}
                            >
                            {(state?.radios||[]).map((elem, index) => (
                                <Tab sx={{alignItems: "start"}} key={index} value={`${index}`} label={elem.display_name} />
                            ))}
                            <Box sx={{display: "flex", justifyContent: "end", borderTop: '1px solid #777', margin: '5px'}}>
                                <Button onClick={() => set_open_radio_dialog(true)}>Add Radio</Button>
                            </Box>
                            </TabList>
                            {(state?.radios||[]).map((elem, index) => (
                                <TabPanel sx={{width: '100%'}} key={index} value={`${index}`}>
                                    <DataAirframeDetailRadioInfo airframe_id={params.airframe_id} radio_info={elem} />
                                </TabPanel>))}
                        </TabContext>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>

        <Dialog open={open_radio_dialog} onClose={() => set_open_radio_dialog(false)}>
            <DialogTitle>Add Radio</DialogTitle>
            <DialogContent sx={{maxWidth: '400px'}}>
                <p>
                    Not all airframes have all their human radios referenced in the ME to extract complete information.
                </p>
                <p>
                    It may be required to manually configure radios such as all radios in the A10s or the Ka50's R800 which
                    is human controllable, but has no presets.
                </p>

                <Box sx={{
                    display: "grid",
                    gridTemplateRows: "repeat (3, 2fr)",
                    flex: "wrap",
                    margin: '5px',
                    '& .MuiFormControl-root': {
                        m: 1,
                    }
                }}>
                    <TextField name="display_name" label="Display Name" required InputLabelProps={{shrink: true}} />
                    <TextField name="ref" label="MDC Reference (PRI / SEC / U)" required InputLabelProps={{shrink: true}} />
                    <FormControl>
                        <InputLabel htmlFor="dcs_dir">DCS Directory (optional)</InputLabel>
                        <OutlinedInput
                            id="dcs_dir"
                            label="DCS Directory (optional)"
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip
                                        title={
                                            <Fragment>
                                                <Typography color="inherit">
                                                    <h3>DCS Directory</h3>
                                                    <p>
                                                        The DCS Directory references the directory which contains the
                                                        <span sx={{fontFamily: 'Monospace'}}> SETTINGS.lua </span> for extracting out preset information contained within mission files.
                                                    </p>
                                                    <p>
                                                        This is used by the F-16 and A10 and can be ignored on all other aircraft.
                                                    </p>
                                                </Typography>

                                            </Fragment>
                                        } placement="right-start">
                                    <IconButton edge="end"><InfoOutlined/></IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => set_open_radio_dialog(false)}>Cancel</Button>
                <Button onClick={() => set_open_radio_dialog(false)}>Add Radio</Button>
            </DialogActions>
        </Dialog>



        <pre>

        {JSON.stringify(state, null, 2)}
        </pre>
    </BreadcrumbLayout>

}