// General inputFilter / validator Cell for the grid
import PropTypes from 'prop-types';

import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import GridCellDisplay from "./GridCellDisplay";
import { Frequency } from '../../common/Frequency';
import { Box } from '@mui/system';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import GridCellFeedback from './GridCellFeedback';
import { rowEditTabHandler } from './rowEditTabHandler';
import { useCellCommitValue } from '../GridManagement/useCellCommitValue';


export const GridCellFrequency = ({
    value: initialValue,
    gridProps,
    input,
    in_mhz
}) => {

    const cellCommitValue = useCellCommitValue();

    // We have two values....a frequency, and a modulation
    // These only go back to a grid as a Frequency object
    // and so initialValue should be there

    const [frequencyObj, setFrequencyObj] = useState(null);

    // We have to store the modulation / frequency ourselves,
    // as otherwise we can't set modulation until we have a valid
    // frequency, and that's ugly
    const [inputValue, setInputValue] = useState("");
    const [khz, setKhz] = useState(null);
    const [modulationValue, setModulationValue] = useState("AM");

    // If we have an override, what's our display format
    const [revertText, setRevertText] = useState(null);

    // If we have an error
    const [error, setError] = useState(null);

    const apiRef = useGridApiContext();

    const onModulationChange = async (e, newModulation) => {

        // Ignore deselection
        if (newModulation === null) return;

        // If we have an input Khz - then we have enough to create our frequency object and validate it
        if (khz) {

            const newFrequency = new Frequency(khz, newModulation);
            const newErrors = newFrequency.conforms();

            await cellCommitValue({
                id: gridProps.id,
                field: gridProps.field,
                value: !newFrequency ? { error: true } : newFrequency,
            });
        }

        setModulationValue(newModulation);
    }

    const onFrequencyChange = async (e) => {

        // When we change our frequency text field, process it to a number, and try and
        // create a frequency object and validate it

        const inputValue = e.target.value;

        let newFrequencyValue = inputValue.trim();

        let newError = null;
        let newKhz = null;
        let newFrequencyObject = null;

        if (!newFrequencyValue) {
            newKhz = null;
        } else {

            if (!newFrequencyValue.match(/^[0-9]+([,.][0-9]{0,3})?$/)) return;
            newKhz = Number(newFrequencyValue.replace(',', '.'))

            // convert to khz
            if (in_mhz) {
                newKhz = Number((newKhz*1000).toFixed(3))
            }

            // Create a new frequency object to ensure we capture pending modulation value changes
            newFrequencyObject = new Frequency(newKhz, modulationValue)

            // Store our frequency value in case it's invalid
            setKhz(newKhz)

            newError = newFrequencyObject.conforms();
        }

        // Error reflection, and update grid value if we're valid, we ignore
        // this on our pinned row
        await cellCommitValue({
            id: gridProps.id,
            field: gridProps.field,
            value: newError !== null && newError[0] == 2 ? {error: true} : newFrequencyObject
        })

        // and set our string value to whatever was entered (incl. end spaces etc)
        setError(newError);
        setInputValue(inputValue);
    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {

        // Plain reset to null
        if (!initialValue) {
            setError(null);
            // Text Field State
            setInputValue("");
            // Default state
            setKhz(null);
            // Reset object in case it used to be set
            setFrequencyObj(null);
            setModulationValue("AM");
            return;
        }

        // Ignore error reflection
        if (initialValue.error === true) return;

        // Try and get a frequency object
        let frequencyObject = null;

        if (initialValue instanceof Frequency) {
            frequencyObject = initialValue;
        } else {
            try {
                frequencyObject = Frequency.from_object(initialValue)
            } catch (e) {
                console.log(e)
            }
        }

        if (gridProps.row?.base?.[gridProps.field]) {
            try {
                const old_value = Frequency.from_object(gridProps.row.base[gridProps.field])
                setRevertText(old_value.toString())
            } catch(e) {
                setRevertText(null)
            }
        } else {
            setRevertText(null)
        }

        if (frequencyObject) {
            setFrequencyObj(frequencyObject)
            setModulationValue(frequencyObject.modulation)
            setKhz(frequencyObject._khz)
            setInputValue(in_mhz ? frequencyObject.mhz : frequencyObject.khz)
            setError(frequencyObject.conforms())
            return;
        }

        // shouldn't really get here, so we just ignore it's existence
        setError(null);
        setInputValue("");
        setKhz(null);
        setModulationValue("AM");

    }, [gridProps.row, gridProps.field, initialValue])


    if (input) return (


        <Box
            sx={{
                position: "relative",
                display: "flex",
                width: '100%',
                height: '100%',
                pl:1,
                pr:0,
                justifyItems: "center",
                alignItems: "center",
            }}
        >
            <input
                tabIndex={0}
                autoFocus={gridProps.hasFocus === true}
                style={{
                    textAlign: "left",
                    width: '100%',
                    height: '100%',
                    border:0,
                    outline: 0,
                    background: "transparent",
                    fontFamily: "var(--wm-font-monospace)",
                 }}
                value={inputValue}
                onChange={onFrequencyChange}
                onKeyDown={rowEditTabHandler(apiRef, gridProps.id, gridProps.field)}
            />
            <ToggleButtonGroup
                value={modulationValue}
                color="primary"
                orientation="vertical"
                exclusive
                onChange={onModulationChange}
                aria-label="text alignment"
                sx={{
                    '& .MuiToggleButton-root': {
                        borderRadius: 1.5,
                        pr: 1,
                        pl: 1,
                        mt: '1px',
                        mr: '2px',
                    }
                }}
            >
                <ToggleButton sx={{fontSize: '10px', p:'4px', height: '16px'}} size="small" value={"AM"} aria-label="AM">
                AM
                </ToggleButton>
                <ToggleButton sx={{fontSize: '10px', p:'4px', height: '16px'}}  size="small" value={"FM"} aria-label="FM">
                FM
                </ToggleButton>
            </ToggleButtonGroup>
            {error && <GridCellFeedback feedbackLevel={error[0]}>{error[1]}</GridCellFeedback>}
        </Box>
    )

    return <GridCellDisplay
        gridProps={gridProps}
        sx={{
            justifyContent: "right",
            fontFamily: "var(--wm-font-monospace)",
        }}
        override={revertText}
        feedbackLevel={error && error[0]}
        feedback={error && error[1]}
    >
        {frequencyObj ? frequencyObj.toString() : ""}
    </GridCellDisplay>

}

GridCellFrequency.propTypes = {
    value: PropTypes.object
}

export const renderGridCellFrequency = (props) => {
    return <GridCellFrequency input={props.id === 0} gridProps={props} value={props.value} in_mhz />
}

export const renderGridCellFrequencyEdit = (props) => {
    return <GridCellFrequency input gridProps={props} value={props.value} in_mhz />
}

export const renderGridCellFrequencySortComparator = (a, b) => a?.khz - b?.khz
export const renderGridCellFrequencyValueFormatter = (a) => {
    let frequencyObject = null;
    try {
        frequencyObject = Frequency.from_object(a.value)
    } catch (e) {
        return ""
    }
    return frequencyObject.toString();
}
