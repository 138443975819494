import { Geodesic } from "geographiclib-geodesic"
import { API_LATLON_DD_POINTS } from "../Constants";
import { CoordinateConverter } from "./Coordinates"

// We use DD to 7 decimal places giving approx ~1cm resolution
//
//      112,120 / 10_000_000 => 0.011212 m
//
// In decimal minutes (60th of a degree) this would be ...
//
//      (112,120/60) / 0.011212 => 166_666, so 5 DP should be sufficient keeping us under 2cm
//      (112,120/60) / 100_000  => 0.0187
//
// In decimal seconds (60th, 60th of a degree) the equivilent would be
//
//      (112,120/60/60) / 0.011212 => 2_777, so we round to 4 decimal places (JDAM is DMS 3 DP)
//      (112,120/60/60) / 10_000   => 0.00311
//
// Return all formats in an easy to use manner

const WGS84 = Geodesic.WGS84;


// This doesn't want to minimize in production builds for some reason, even if we do a .source + .source + .source...
//
// const RE_MGRS = new RegExp([
//     /^\s*/,                                                                         // Allow start padding
//     /(?<grid_zone>(?:[0-6]?[0-9])\s*([ABCDEFGHJKLMNPQRSTYVWX]))\s*/,                // Grid Zone: C-X omiiting I and O (not including poles / UPS)
//     /(?<square>(?:[ABCDEFGHJKLMNPQRSTUVWXYZ])\s*(?:[ABCDEFGHJKLMNPQRSTUV]))\s*/,    // 100km square, column and row, [A-Z, A-V] excluding I and O
//     /(?<grid_location>[0-9 ]+)/,                                                    // location reference, this needs to be two groups of eql. length
//                                                                                     // But we'll handle that in the test function
//     /$/                                                                             // End Padding
// ].map((r) => r.source).join(''));

const RE_MGRS = new RegExp('^\\s*(?<grid_zone>(?:[0-6]?[0-9])\\s*([ABCDEFGHJKLMNPQRSTYVWX]))\\s*(?<square>(?:[ABCDEFGHJKLMNPQRSTUVWXYZ])\\s*(?:[ABCDEFGHJKLMNPQRSTUV]))\\s*(?<grid_location>[0-9 ]+)$');


export class Lat {

    constructor(value) {
        // Short Circut null / empty string
        value ??= ""

        if (value === "" || isNaN(value)) {
            this._value = null
            return;
        }

        if (typeof(value) !== "number") throw new Error("value must be a number, or empty string");
        if (value > 90 || value < -90) throw new Error(`value must be in range -90 <= x <= 90, got: ${value}`)

        this._value = value
    }

    get value() { return this._value; }

    as_dd(precision) {
        precision ??= 7
        return this._value ? Number(this._value.toFixed(precision)) : null;
    }

    as_ddm(precision) {
        precision ??= 5

        // return a DDM array, eg: ['N', 24, 4.125125]
        if (!this._value) return [null, null, null];

        let north = this._value > 0;

        let deg = Math.floor(Math.abs(this._value))
        let min = Number(((this._value - deg)*60).toFixed(precision));

        if (min === 60) {
            min = 0;
            deg += 1;
        }

        return [['N', 'S'][north ? 0 : 1], deg, min]
    }

    as_dms(precision) {
        precision ??= 5

        // return a DDM array, eg: ['N', 24, 4.125125]
        if (!this._value) return [null, null, null];

        let positive = this._value > 0;

        let work = Math.abs(this._value);
        let deg = Math.floor(work)

        work = (work - deg)*60;
        let min = Math.floor(work);

        work = (work - min)*60;
        let sec = Number(work.toFixed(precision))

        if (sec === 60) {
            sec = 0;
            min += 1;

            if (min === 60) {
                min = 0;
                deg += 1;
            }
        }

        return [['N', 'S'][positive ? 0 : 1], deg, min, sec]
    }

    format_dd(precision) {
        precision ??= 7
        if (!this._value) return "";
        return this._value.toFixed(precision);
    }

    format_ddm(precision) {
        precision ??= 5
        if (!this._value) return "";
        const [hemisphere, deg, min] = this.as_ddm(precision)
        return `${hemisphere} ${deg.toFixed().padStart(2, '0')} ${min.toFixed(precision).padStart(precision+3, '0')}`;
    }

    format_dms(precision) {
        precision ??= 4
        if (!this._value) return "";
        const [hemisphere, deg, min, sec] = this.as_dms(precision)
        return `${hemisphere} ${deg.toFixed().padStart(2, '0')} ${min.toFixed().padStart(2, '0')} ${sec.toFixed(precision).padStart(precision+3, '0')}`;
    }
}


export class Lon {

    constructor(value) {
        // Short Circut null / empty string
        value ??= ""

        if (value === "" || isNaN(value)) {
            this._value = null
            return;
        }

        if (typeof(value) !== "number") throw new Error("value must be a number");
        if (value > 180 || value < -180) throw new Error("value must be in range -180 <= x <= 180")

        this._value = value
    }

    get value() { return this._value; }

    as_dd(precision) {
        precision ??= API_LATLON_DD_POINTS
        return this._value ? this._value.toFixed(precision) : null;
    }

    as_ddm(precision) {
        precision ??= 5
        // return a DDM array, eg: ['N', 24, 4.125125]

        if (!this._value) return [null, null, null];

        let east = this._value > 0;

        let deg = Math.floor(Math.abs(this._value))
        let min = Number(((this._value - deg)*60).toFixed(precision));

        if (min === 60) {
            min = 0;
            deg += 1;
        }

        return [['E', 'W'][east ? 0 : 1], deg, min]
    }

    as_dms(precision) {
        precision ??= 5

        // return a DDM array, eg: ['N', 24, 4.125125]
        if (!this._value) return [null, null, null];

        let positive = this._value > 0;

        let work = Math.abs(this._value);
        let deg = Math.floor(work)

        work = (work - deg)*60;
        let min = Math.floor(work);

        work = (work - min)*60;
        let sec = Number(work.toFixed(precision))

        if (sec === 60) {
            sec = 0;
            min += 1;

            if (min === 60) {
                min = 0;
                deg += 1;
            }
        }

        return [['E', 'W'][positive ? 0 : 1], deg, min, sec]
    }

    format_dd(precision) {
        precision ??= 7
        if (!this._value) return "";
        return this._value.toFixed(precision);
    }

    format_ddm(precision) {
        precision ??= 5

        if (!this._value) return "";
        const [hemisphere, deg, min] = this.as_ddm(precision)
        return `${hemisphere} ${deg.toFixed().padStart(3, '0')} ${min.toFixed(precision).padStart(precision+3, '0')}`;
    }

    format_dms(precision) {
        precision ??= 4

        if (!this._value) return "";
        const [hemisphere, deg, min, sec] = this.as_dms(precision)
        return `${hemisphere} ${deg.toFixed().padStart(3, '0')} ${min.toFixed().padStart(2, '0')} ${sec.toFixed(precision).padStart(precision+3, '0')}`;
    }

    formatted_value() {
        return this.format_ddm();
    }
}


export default class LatLon {

    constructor (dd_lat, dd_lon, theatre, x, y) {

        // Constrain Lat / Lon to 7 decimal places matching the API
        this.lat = new Lat(dd_lat ? Number(dd_lat.toFixed(API_LATLON_DD_POINTS)) : "");
        this.lon = new Lon(dd_lon ? Number(dd_lon.toFixed(API_LATLON_DD_POINTS)) : "");
        this._x = x
        this._y = y
        this._theatre = theatre
    }

    get x() {
        if (this._x) return this._x
        this.add_dcs_xy()
        return this._x;
    }

    get y() {
        if (this._y) return this._y
        this.add_dcs_xy()
        return this._y;
    }

    ddm() {
        return [this.lat.format_ddm(), this.lon.format_ddm()]
    }

    add_dcs_xy() {
        if (!this._theatre) throw Error("Theatre must be defined to use x/y coordinates")
        let ll = CoordinateConverter.ll_to_dcs(this._lat, this._lon, this._theatre)
        this._x = ll.x
        this._y = ll.y
    }

    distance_to(b) {
        if (!b instanceof LatLon) throw new TypeError("distance_to requires a LatLon instance")

        // If both have a theatre, then we convert to DCS
        // else we use vincenty to go from LL -> LL
        if (this.theatre) return Math.hypot(this.x - b.x, this.y - b.y);

        // Otherwise, just return the WGS84 inverse
        return WGS84.Inverse(this.lat, this.lon, b.lat, b.lon).s12
    }

    clone() {
        return new LatLon(this.lat.value, this.lon.value)
    }

    isEqual(latlon) {
        if (!latlon) return false;
        return latlon.lat.value === this.lat.value && latlon.lon.value === this.lon.value;
    }

    format_dd(precision) { return `${this.lat.format_dd(precision)} ${this.lon.format_dd(precision)}`; }
    format_ddm(precision) { return `${this.lat.format_ddm(precision)} ${this.lon.format_ddm(precision)}`; }
    format_dms(precision) { return `${this.lat.format_dms(precision)} ${this.lon.format_dms(precision)}`; }

    format_mgrs(precision) {
        let mgrs = CoordinateConverter.ll_to_mgrs(this.lat.value, this.lon.value, precision)
        let match = RE_MGRS.exec(mgrs);

        if (!match) return "Invalid"
        let groups = match.groups;

        let grid_location = groups.grid_location.replace(" ", "");
        if (!grid_location) return "Invalid";

        let grid_ref_length = grid_location.length/2
        let loc = grid_location.slice(0, grid_ref_length) + " " + grid_location.slice(grid_ref_length);

        return `${groups.grid_zone} ${groups.square} ${loc}`
    }

    static from_ll(lat, lon, theatre) {
        return new LatLon(lat, lon, theatre);
    }

    static from_dcs(x, y, theatre) {
        const ll = CoordinateConverter.dcs_to_ll(x, y, theatre)
        return new LatLon(ll.lat, ll.lon, theatre, ll.x, ll.y);
    }

    static from_obj(obj, theatre)  {
        if (!obj) return null;

        // See if we can do dcs first
        if (obj.lat && obj.lon) {
            return LatLon.from_ll(obj.lat, obj.lon, theatre)
        }
        if (obj.x && obj.y && theatre) {
            return LatLon.from_dcs(obj.x, obj.y, theatre)
        }
        return null
    }

    toJSON() {
        return {
            lat: this.lat.as_dd(API_LATLON_DD_POINTS),
            lon: this.lon.as_dd(API_LATLON_DD_POINTS),
        }
    }

}