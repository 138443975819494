import { useContext } from "react";
import { DialogContext } from "../contexts/DialogContext";


export function useShowDialog() {
    const { showDialog } = useContext(DialogContext);
    return showDialog
}

export function useErrorDialog() {
    const { error, showError } = useContext(DialogContext);
    return { error, showError };
}

export function useShowError() {
    return useShowDialog()
}
