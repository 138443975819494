import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { ChromePicker, HuePicker } from "react-color";
import { useAPI } from "../../../hooks/useAxios";


function CampaignDialogPicker({
    color,
    onChange,
    onClick,
    inputRef,
}) {

    const position = inputRef.getBoundingClientRect();

    return (
        <div style={{ position: 'fixed', top: position.top + inputRef.clientHeight + 5, left: position.left , zIndex: 20 }}>
            <div style={{ position: 'absolute' }}>
                <div
                    style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                    onClick={onClick}
                />
                <ChromePicker
                    disableAlpha
                    color={color}
                    onChange={onChange}
                    onClick={onClick}
                />
            </div>
        </div>
    )
}

export function CreateCampaignDialogAvatarPicker({
    avatarCode,
    avatarColor,
    onChange,
}) {

    const [showPicker, setshowPicker] = useState(false);

    const avatarRef = useRef();

    const onColorChange = (evt) => {
        onChange(evt.hex);
    }

    return (
        <>
        <div ref={avatarRef}>
            <Avatar onClick={() => { console.log(avatarRef); setshowPicker(true) } } sx={{ bgcolor: avatarColor }} variant="rounded" aria-label="recipe">{avatarCode.toUpperCase()}</Avatar>
        </div>
        { showPicker && <CampaignDialogPicker
            onChange={onColorChange}
            onClick={() => setshowPicker(false) }
            color={avatarColor}
            inputRef={avatarRef.current} /> }
        </>
    )
}



export default function CreateCampaignDialog({
    open,
    close,
    onCreate,
    onUpdate,
    data,
}) {

    const axios = useAPI();

    const [wing_list, set_wing_list] = useState([])

    const [initialState, setInitialState] = useState(data);
    const [form_state, set_form_state] = useState({
        tag: 'TR',
        tagColor: '#46A7C7',
    });

    // Update data if we're told to
    useEffect(() => {
        set_form_state({tag: 'TR', tagColor: '#46A7C7', ...data})
    }, [data]);

    const avatarColorChanged = (color) => {
        set_form_state({...form_state, tagColor: color})
    }

    const set_form_value = (evt) => {
        set_form_state({...form_state, [evt.target.name]: evt.target.value})
    }

    // If we don't know our wing, create a list
    useEffect(() => {
        if (data?.wing_id !== undefined) return;
        axios.get(`/wing`).then((res) => {
            if (!res?.data) return;
            // Select Items
            let items = []
            for (const wing of res.data) {
                items.push(<MenuItem key={wing.id} value={wing.id}>{wing.name}</MenuItem>)
            }
            set_wing_list(items);
        });
    }, []);

    useEffect(() => {
        setInitialState(data)
    }, [data])

    const submit = () => {

        // If we have a form_state.id then we're patching
        if (form_state.id && data) {
            let patch = {}
            for (const [key, value] of Object.entries(form_state)) {
                if (data?.[key] !== value) {
                    patch[key] = value
                }
            }

            axios.patch(`/campaigns/${form_state.id}`, patch).then((res) => {
                if (!res?.data) return;
                onUpdate(res.data)
            })
        } else {
            axios.post(`/campaigns`, form_state).then((res) => {
                if (!res?.data) return;
                onCreate(res.data)
            })
        }
    }

    return (

        <Dialog open={open} onClose={close}>
            <DialogTitle>{form_state.id ? 'Edit' : 'Create'} Campaign</DialogTitle>
            <DialogContent sx={{paddingBottom: 0}}>
                <Grid sx={{display: "grid", alignItems: "center", gridTemplateRows: 'auto', gridTemplateColumns: 'repeat(10, 1fr)', p: 1, gridGap: '15px' }}>


                    <CreateCampaignDialogAvatarPicker onChange={avatarColorChanged} avatarColor={form_state.tagColor} avatarCode={form_state?.tag || ""} />

                    <TextField
                        sx={{gridColumnStart: 2, gridColumnEnd: 4}}
                        name="tag"
                        label="Tag"
                        value={form_state?.tag || ""}
                        onChange={set_form_value}
                        inputProps={{ maxLength: 2 }}
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        sx={{gridColumnStart: 4, gridColumnEnd: 6}}
                        name="code"
                        label="Code"
                        value={form_state?.code || ""}
                        onChange={set_form_value}
                        inputProps={{ maxLength: 5 }}
                        placeholder="OPEG"
                        InputLabelProps={{shrink: true}}
                    />

                    <TextField
                        sx={{gridColumnStart: 6, gridColumnEnd: 12}}
                        name="name"
                        label="Name"
                        value={form_state?.name || ""}
                        onChange={set_form_value}
                        placeholder="Operation Enduring Freedom"
                        InputLabelProps={{shrink: true}}
                    />

                    {initialState?.wing_id === undefined &&
                    <FormControl sx={{gridColumnStart: 1, gridColumnEnd: 12}}>
                        <InputLabel id="select-wing-label">Wing</InputLabel>
                        <Select
                                labelId="select-wing-label"
                                label="Wing"
                                value={form_state?.wing_id || "_"}
                                name="wing_id"
                                onChange={set_form_value}
                        >
                            <MenuItem key="_" value="_" disabled><em>Select Wing</em></MenuItem>
                            {wing_list}
                        </Select>
                    </FormControl>
                    }

                    <TextField
                        sx={{gridColumnStart: 1, gridColumnEnd: 12}}
                        name="descriptionMarkdown"
                        onChange={set_form_value}
                        label="Operation Description"
                        value={form_state?.descriptionMarkdown || ""}
                        multiline
                        minRows={6}
                    />



                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
                <Button onClick={submit}>{form_state.id ? 'Update' : 'Create'}</Button>
            </DialogActions>
        </Dialog>

    )


}