import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { updateDataGridRows } from "../GridManagement/updateDataGridRows";
import { useAPI } from "../../hooks/useAxios";
import { useShowError } from "../../hooks/useErrorDialog";
import { reflectErrorValue } from "../GridCells/reflectErrorValue";
import { getGridRowPatchObject } from "../GridManagement/getGridRowPatchObject";
import StandardGrid from "../Grid/StandardGrid";
import { useParams } from "react-router-dom";
import { renderGridCellLatLon } from "../GridCells/GridCellLatLon";
import { renderGridCellStringNonEmpty } from "../GridCells/GridCellValidated";
import { CELL_LATLON_PLUS_OVERRIDE_WIDTH } from "../GridCells/Constants";


export default function GridBullseye({
    apiEndpoint: rootEndpoint,
}) {

    const params = useParams();
    const apiEndpoint = `${rootEndpoint}`

    const [state, setState] = useState(null);
    const showError = useShowError();

    const axios = useAPI();

    useEffect(() => {
        setState(null);
        axios.get(apiEndpoint).then((res) => {
            if (!res.data) return;
            setState(res.data);
        });
    }, [rootEndpoint]);



    const processRowUpdate = (newRow, oldRow) => {
        console.log(newRow, oldRow)
        const patch = getGridRowPatchObject(newRow, oldRow);
        if (!patch) return oldRow;
        if (!newRow.id) return oldRow;

        console.log(patch);

        return axios.patch(`${apiEndpoint}/${newRow.id}`, patch).then((res) => {
            if (!res.data) return oldRow;
            setState(updateDataGridRows(state, [res.data]));
            return newRow;
        });
    }

    const onProcessRowUpdateError = (e) => {
        console.log(e)
    }

    const columns = (() => {
            let cols = [
            {
                field: 'coalition',
                headerName: 'Coalition',
            },
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                cellClassName: 'fullCell',
                renderCell: renderGridCellStringNonEmpty(),
                renderEditCell: renderGridCellStringNonEmpty("edit"),
                preProcessEditCellProps: reflectErrorValue,
                editable: true,
            },
            {
                field: 'latlon',
                headerName: 'Location',
                width: CELL_LATLON_PLUS_OVERRIDE_WIDTH,
                sortable: false,
                editable: false,
                cellClassName: 'fullCell',
                renderCell: renderGridCellLatLon(state?.theatre),
            },
        ];

        return cols;
    })();

    return (
        <StandardGrid

            columns={columns}
            rows={state ? state : []}

            autoHeight

            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}

            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableChildrenSorting
            disableColumnResize
            density="compact"
            hideFooter
            loading={state === null}

        />
    )
}