// Shows a list of theatres, upon selecting a theatre
// queries the agencies for that theatre and presents

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAPI } from "../../hooks/useAxios";
import StandardGrid from "../Grid/StandardGrid";
import GridCellDisplay, { renderGridCellDisplay } from "../GridCells/GridCellDisplay";
import { getGridRowPatchObject } from "../GridManagement/getGridRowPatchObject";
import { updateDataGridRows } from "../GridManagement/updateDataGridRows";
import { StripedDataGrid } from "../StripedDataGrid";
import GridAirfieldAgency from "./GridAirfieldAgency";

export default function GridTheatreAirfields({
    apiEndpoint,
    setTheatre,
    toggleable,
    useRouting,
    component,
    editable,
    displayField,
}) {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const axios = useAPI();

    const [state, setState] = useState(null);
    const [localAirfieldId, setLocalAirfieldId] = useState(null);

    // We can either maintain the state locally or get it from path
    useRouting ??= true;
    const airfieldId = useRouting ? params.airfield_id : localAirfieldId;
    const Component = component || GridAirfieldAgency;

    // This only shows DCS airfields, so just find our airfields
    useEffect(() => {
        axios.get(apiEndpoint).then((res) => {
            if (!res?.data) return;
            if (typeof setTheatre === "function") setTheatre(res.data.name);
            setState(res.data);
        });
    }, [apiEndpoint])

    // Just route
    const onAirfieldClick = (evt) => {
        if (!useRouting) {
            setLocalAirfieldId(evt.id);
        } else {
            // navigate really doesn't like relative paths, so full we go
            navigate(location.pathname + (params.airfield_id ? "/../" : "/airfield/") + evt.id);
        }
    }

    // Miz Airfields etc. can have their name updated
    const processRowUpdate = (newRow, oldRow) => {
        const patch = getGridRowPatchObject(newRow, oldRow);
        if (!patch) return oldRow;
        if (!newRow.id) return oldRow;

        console.log(patch);

        return axios.patch(`${apiEndpoint}/${newRow.id}`, patch).then((res) => {
            if (!res.data) return oldRow;
            const new_state = {...state, airfields: updateDataGridRows(state.airfields, [res.data])}
            setState(new_state);
            return newRow;
        });
    }

    // Airfields Table
    const columns = [
        {
            field: displayField || 'name',
            headerName: 'Airfield',
            editable: editable,
            renderCell: renderGridCellDisplay(),
            flex: 1,
        }
    ]

    return (
        <Box sx={{display: 'flex'}}>

            <StandardGrid
                processRowUpdate={processRowUpdate}
                sx={{m: 1}}
                density="compact"
                columns={columns}
                rows={state?.airfields||[]}
                selectionModel={airfieldId ? [airfieldId] : []}
                disableColumnMenu
                onRowClick={onAirfieldClick}
                autoHeight
                loading={state === null}
                hideFooter
                noRowsLabel="No agencies"
            />
            {airfieldId &&
                <Box sx={{minHeight: '300px', width: '70%'}}>
                    <Component
                        apiEndpoint={`${apiEndpoint}/${airfieldId}`}
                        toggleable={toggleable}
                    />
                </Box>
            }
        </Box>
    )

}