import { forwardRef } from "react";
import { DiscordLoginButton as UpstreamDiscordLoginButton } from "react-social-login-buttons";
import { config } from "../Constants";

export const DiscordLoginButton = forwardRef(({
    onClickedCallback,
    children
}, ref) => {

    const onDiscord = (e) => {

        const width = 430;
        const height = 750;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;

        const params_dict = {
            client_id: config.auth.discord.CLIENT_ID,
            redirect_uri: `${window.location.origin}/auth_redirect.html`,
            response_type: "code",
            scope: "identify",
            prompt: "none",
            state: "discord",
        };

        const params = new URLSearchParams(params_dict);

        const auth_url = `https://discord.com/api/oauth2/authorize?${params}`;
        window.open(auth_url, 'Login / Register', `width=${width},height=${height},left=${left},top=${top}`);

        if (typeof(onClickedCallback) == 'function') onClickedCallback();
    }

    return <UpstreamDiscordLoginButton innerRef={ref} iconSize="20px" size="34px" onClick={onDiscord} >{children}</UpstreamDiscordLoginButton>
});