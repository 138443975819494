import { Card, Grid, Avatar, Divider, CardContent, Box } from "@mui/material";

const styles = {
    statValue: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 4,
        letterSpacing: '1px',
    },
    statLabel: {
        fontSize: 12,
        color: '#aaaaaa',
        fontWeight: 500,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        margin: 0,
    }
}

export default function DummyProfile() {

    return (
        <div style={{margin: '20px'}}>
            <Grid container spacing={1}>
                <Grid xs item display="flex" justifyContent="center" alignItems="center" sx={{ display: 'contents' }}>
                    <Card sx={{
                        borderRadius: '12px',
                        minWidth: 256,
                        textAlign: 'center',
                    }}>
                        <CardContent sx={{paddingTop: 1}}>
                            <Avatar 
                                sx={{
                                    width: 60,
                                    height: 60,
                                    margin: 'auto',
                                }}
                            />
                            
                            <h3 style={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                marginTop: 8,
                                marginBottom: 0,
                            }}>MartinCo#6402</h3>

                            <span style={{
                                fontSize: 10,
                                letterSpacing: '0.5px',
                                marginTop: 8,
                                marginBottom: 0,
                            }}>Member Since: 2022-05-02</span>

                        </CardContent>
                        <Divider light />
                        <Box display={'flex'}>
                            <Box p={2} flex={'auto'} >
                                <p style={styles.statLabel}>Sorties</p>
                                <p style={styles.statValue}>52</p>
                            </Box>
                            <Box p={2} flex={'auto'}>
                                <p style={styles.statLabel}>Hours Lead</p>
                                <p style={styles.statValue}>12</p>
                            </Box>
                            <Box p={2} flex={'auto'}>
                                <p style={styles.statLabel}>Hours Wing</p>
                                <p style={styles.statValue}>12</p>
                            </Box>
                        </Box>
                    </Card>
                
                </Grid>

                <Grid xs item display="flex" justifyContent="center" alignItems="center">
                    <div style={{minWidth: '300px', width:'300px', height:'500px', backgroundColor: '#ff0000'}}></div>
                </Grid>
                <Grid xs item display="flex" justifyContent="center" alignItems="center">
                    <div style={{minWidth: '300px', width:'300px', height:'500px', backgroundColor: '#0000ff'}}></div>
                </Grid>
            </Grid>
        </div>
    )
}