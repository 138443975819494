import { useContext } from "react";
import { AxiosContext } from "../contexts/AxiosContext";

export function useAxios() {
    return useContext(AxiosContext);
}

export function useAPI() {
    return useContext(AxiosContext).api;
}

export function useAuth() {
    return useContext(AxiosContext).auth;
}