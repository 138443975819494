import { Link } from "react-router-dom";

export default function TopBarContainer({
    forwardRef,
    children,
    logoBackgroundColor,
}) {
    return (
        <div style={{
                position: "sticky",
                top: 0,
                left: 0,
                height:"var(--wm-size-header)",
                width: "100%",
                minWidth: "var(--wm-min-width)",
                background:"var(--wm-color-grey)",
                boxShadow: logoBackgroundColor ? null : "0 5px 5px -5px #888",
                borderBottom: logoBackgroundColor ? null : "1px solid var(--wm-color-separators)",
                zIndex: 2,
                display: 'flex',
                alignItems: 'center',
            }}
            ref={forwardRef}
        >

            <div style={{
                    width:'200px',
                    minWidth:'200px',
                    backgroundColor: logoBackgroundColor,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
            }}>
                <Link to='/' style={{paddingLeft: '10px', paddingTop: '3px' }}>
                    <img src="/logo.svg" height="30px"/>
                </Link>
                <div style={{width:"42px"}}/>
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'left',
                boxShadow: logoBackgroundColor ? "0 5px 5px -5px #888" : null,
                borderBottom: logoBackgroundColor ? "1px solid var(--wm-color-separators)" : null,
            }}>
                {children}
            </div>
        </div>
    )
}