import { ExpandLess, ExpandMoreOutlined, InboxOutlined, StarBorder } from "@mui/icons-material";
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, styled, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    color: '#aaa',
    margin: 0,
    padding: 0,
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    //marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
}));




export default function SideBarLayout({
    config,
}) {

    // Find our active tab based on index
    let location = useLocation();
    let current = useResolvedPath("")

    let active_child = location.pathname.startsWith(current.pathname) ? location.pathname.substring(current.pathname.length).split('/')[1] || "" : "";

    const [open, setOpen] = useState(true);

    const handleExpand = (key) => {
        setOpen({...open, [key]: !(open?.[key] || false)})
    }

    const build_tree = (root, prefix, depth, expand_state, routes) => {
        let items = [];
        let has_selection = false;

        depth = depth || 0;
        expand_state = expand_state || {};
        routes = routes || [];

        for (const elem of root) {
            if (!elem.title) {
                continue;
            }
            let key = (prefix ? prefix + "_" : "") + elem.prefix;
            if (elem.children) {

                // Get our tree, and if we have an item selected expand
                let [section_expand, children, _] = build_tree(elem.children, prefix, depth+1, expand_state, routes)
                if (section_expand) {
                    has_selection = true;
                    expand_state[key] = true;
                }

                items.push(
                    <ListItemButton key={key} onClick={(e) => handleExpand(key)}>
                        <ListItemText primary={elem.title} />
                        <ExpandMore
                            expand={open?.[key]}
                            aria-expanded={open?.[key]}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </ListItemButton>
                )

                // If we're building our tree and it's open, set our state

                items.push(
                    <Collapse key={key+"_collapse"} in={open?.[key]} timeout="auto" unmountOnExit>
                        <List component="div" sx={{pl: 0}} disablePadding>
                            {children}
                        </List>
                    </Collapse>
                )
            } else {
                // If we have routes, and our current route matches, then this is our selected item
                if (active_child == elem.prefix) {
                    has_selection = true;
                }

                items.push(
                    <ListItemButton sx={{paddingLeft: 2 + 2*depth}} selected={active_child == elem.prefix} key={key} component={Link} to={elem.prefix}>
                        <ListItemText primary={elem.title} />
                    </ListItemButton>
                )

                routes.push(elem.redirect
                    && (<Route key={elem.prefix} path={elem.prefix} element={<Navigate to={elem.redirect} />} />)
                    || (<Route key={elem.prefix} path={elem.prefix + "/*" || "*"} element={elem.element} />))
            }
        }

        return [has_selection, items, expand_state, routes];
    }

    const tree = useMemo(() => build_tree(config), [open, location])

    // on first render, we need to populate our expanded state
    useEffect(() => {
        setOpen(tree[2])
    }, [])


    return (
        <div style={{
            position: "relative",
            display: "flex",
            width: "100%",
            // To keep us always showing full bar
            minHeight: "calc(100% - 48px)",
            alignItems: 'stretch',
        }}>
            <Box sx={{
                position: "fixed",
                minWidth: "200px",
                backgroundColor: "var(--wm-color-sidebar)",
                borderRight: "1px solid var(--wm-color-separators)",
                boxShadow: '5px 0px 5px -5px #888',
                minHeight: "100%",
                '& .MuiListItemButton-root.Mui-selected': {
                    backgroundColor: "#9c9c9c !important",
                    color: "#000",
                },
                '& .MuiListItemButton-root:hover': {
                    backgroundColor: "#9c9c9c50 !important",
                }
            }}>
                <List
                    sx={{ width: '100%', maxWidth: 250, color: "#aaa", fontWeight: "bold" }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {tree[1]}
                </List>

            </Box>
            <div style={{
                display: "block",
                width: "100%",
                margin: "10px",
                marginLeft: '210px',
            }}>
                <Routes>
                    {tree[3]}
                </Routes>
            </div>
        </div>
    )
}
