import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { useAPI } from "../../hooks/useAxios";
import style from '../../css/markdown-styles.module.css';
import remarkGfm from "remark-gfm";

export function CreateSquadronDialog({
    open,
    close,
    edit,
    onCreate,
    onUpdate,
}) {

    const {id: squadron_id, airframe, ...rest} = edit || {};

    const axios = useAPI();
    const params = useParams();

    const [form_state, set_form_state] = useState(rest || {});
    const [airframes, setAirframes] = useState([]);

    useEffect(() => {
        axios.get('/airframe').then((res) => {
            let select_options = [];
            for (const airframe of (res?.data || [])) {
                select_options.push(<MenuItem key={airframe.id} value={airframe.id}>{airframe.display_name}</MenuItem>)
            }
            setAirframes(select_options);
        })
    }, []);

    const submit = () => {
        if (squadron_id) {

            let patch = {}
            for (const [k, v] of Object.entries(form_state)) {
                if (edit[k] !== v) {
                    patch[k] = v
                }
            }

            console.log(patch);
            if (Object.keys(patch).length > 0) {
                axios.patch(`/wing/${params.wing_id}/squadrons/${squadron_id}`, patch).then((res) => {
                    onUpdate(res?.data)
                });
            }

        } else {
            axios.post(`/wing/${params.wing_id}/squadrons`, form_state).then((res) => {
                if (!res?.data) return;
                onCreate(res.data)
            });
            close();
        }
    }

    const set_form_value = (evt) => {
        console.log(evt.target.name, evt.target.value);
        set_form_state({...form_state, [evt.target.name]: evt.target.value})
    }

    return (

        <Dialog open={open} onClose={close}>
            <DialogTitle>{squadron_id ? 'Edit' : 'Create'} Squadron</DialogTitle>
            <DialogContent sx={{paddingBottom: 0}}>
                <Grid sx={{display: "grid", alignItems: "center", gridTemplateRows: 'auto', gridTemplateColumns: 'repeat(10, 1fr)', p: 1, gridGap: '15px' }}>

                    <TextField
                        sx={{gridColumnStart: 1, gridColumnEnd: 12}}
                        name="name"
                        label="Name"
                        value={form_state?.name || ""}
                        onChange={set_form_value}
                        placeholder="388th Virtual Fighter Squadron - The Peregrines"
                        InputLabelProps={{shrink: true}}
                    />

                    <FormControl sx={{gridColumnStart: 1, gridColumnEnd: 12}}>
                        <InputLabel id="select-airframe">Airframe</InputLabel>
                        <Select
                                labelId="select-airframe"
                                label="Airframe"
                                value={form_state?.airframe_id || "_"}
                                name="airframe_id"
                                onChange={set_form_value}
                                disabled={squadron_id ? true : false}
                        >
                            <MenuItem key="_" value="_" disabled><em>Select Airframe</em></MenuItem>
                            {airframes}
                        </Select>
                        {squadron_id && <FormHelperText>It is not possible to change the airframe of an existing squadron</FormHelperText>}
                    </FormControl>

                    <TextField
                        sx={{gridColumnStart: 1, gridColumnEnd: 6}}
                        name="abbrev"
                        label="Abbreviation"
                        value={form_state?.abbrev || ""}
                        onChange={set_form_value}
                        placeholder="388th"
                        InputLabelProps={{shrink: true}}
                    />

                    <TextField
                        sx={{gridColumnStart: 1, gridColumnEnd: 12}}
                        name="description"
                        onChange={set_form_value}
                        label="Operation Description"
                        value={form_state?.description || ""}
                        multiline
                        minRows={6}
                    />

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
                <Button onClick={submit}>{squadron_id ? 'Update' : 'Submit'}</Button>
            </DialogActions>
        </Dialog>

    )
}

export function SquadronCard({
    item,
}) {

    const [editOpen, setEditOpen] = useState(false);
    const [state, setState] = useState(item);


    return <Card sx={{borderRadius: "10px", marginBottom: '20px'}}>
        <CardHeader title={state.name} sx={{pb:0}}/>
        <CardContent sx={{display:"flex", pb:0 }}>
            <Box sx={{position: "relative"}}>
                <Box sx={{display: "flex", height: '30px', width: '200px', backgroundColor: '#00000044', justifyContent: "center", alignItems: "center"}}>
                    <Typography>{state.airframe.display_name}</Typography>
                </Box>
                <Box sx={{width: '200px', height: '100%'}}>
                    <img src="/squadrons/388th.png" style={{width: '100%', height: '100%', objectFit: 'contain' }} />
                </Box>
            </Box>
            <Box sx={{display:"flex", marginLeft: '5px', flexGrow: 1}}>
                <ReactMarkdown
                        className={style.reactMarkDown}
                        children={state.description}
                        remarkPlugins={[remarkGfm]}
                />
            </Box>

        </CardContent>
        <CardActions sx={{justifyContent: "end"}}>
            <Button onClick={() => { setEditOpen(true); }}>Edit</Button>
        </CardActions>

        <CreateSquadronDialog
            onUpdate={(new_data) => {setState(new_data); setEditOpen(false)} }
            close={() => { setEditOpen(false) }}
            open={editOpen}
            edit={state} />
    </Card>
}


export default function WingSqadronPage({}) {

    const params = useParams()
    const axios = useAPI();

    const [state, setState] = useState([]);
    const [createSquadronOpen, setCreateSquadronOpen] = useState(false);

    useEffect(() => {
        axios.get(`/wing/${params.wing_id}/squadrons`).then((res) => {
            if (!res?.data) return;
            setState(res.data);
        })
    }, []);

    const onCreate = (new_squadron) => {
        setState([...state, new_squadron]);
    }

    return <Box>
        {state.map((itm) => <SquadronCard
            key={itm.id}
            sx={{
                paddingBottom: '20px'
            }}
            item={itm}
            />
        )}

        <Box sx={{display: "flex", width: "100%", justifyContent: "end"}}>
            <ButtonGroup variant="text" aria-label="text button group">
                <Button onClick={() => { setCreateSquadronOpen(true) }}>create squadron</Button>
            </ButtonGroup>
        </Box>
        <CreateSquadronDialog onCreate={onCreate} open={createSquadronOpen} close={() => { setCreateSquadronOpen(false) }} />
    </Box>
}