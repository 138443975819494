import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAPI } from "../hooks/useAxios"


export function AuthenticationDryadTableElement({
    title,
    version,
    rows,
}) {

    const rows_title = 'ABCDEFGHIJKLMNOPQRSTUVWXY'.split('');

    // We'll just munge the rows here to strings
    let real_rows = [];
    const counts = [4,3,3,2,2,3,2,2,2,2]
    for (const row_data of rows) {
        let row = []
        let offset = 0;

        for (const count of counts) {
            let elem = ""
            for (let i = 0; i < count; i++) {
                elem = elem + row_data[offset];
                offset++;
            }
            row.push(elem)
        }
        real_rows.push(row);
    }

    const row_headings = (idx) => [
        <tr key={`hdg1_${idx}`} style={{height: "40px", verticalAlign: "bottom", fontWeight: "bold", textAlign: "center"}}>
            <td/>
            <td>ABC</td>
            <td>DEF</td>
            <td>GHJ</td>
            <td>KI</td>
            <td>MN</td>
            <td>PQR</td>
            <td>ST</td>
            <td>UV</td>
            <td>WX</td>
            <td>YZ</td>
        </tr>,
        <tr key={`hdg2_${idx}`} style={{fontWeight: "bold", textAlign: "center"}}>
            <td/>
            <td>0</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
        </tr>
    ];

    return (
        <Box sx={{ fontFamily: 'monospace', fontSize: 20, lineHeight: '12px' }}>
            <table cellPadding={'4px'}>
                <colgroup>
                    <col style={{width: '80px'}} />
                </colgroup>
                <tbody>
                    <tr>
                        <td colSpan={12} style={{textAlign: "center"}}>{title}</td>
                    </tr>
                    <tr>
                        <td colSpan={8}></td>
                        <td colSpan={3} style={{textAlign: "right"}}>Version: {version}</td>
                    </tr>
                    {real_rows.map((x, idx) => [
                        idx % 6 == 0 && idx != 24 && row_headings(idx),
                        <tr key={`tr_${idx}`}>
                            <td style={{fontWeight: "bold", textAlign: "center"}}>{rows_title[idx]}</td>
                            {x.map((y, yidx) => <td key={`td_${idx}_${yidx}`} style={{paddingLeft: "12px", paddingRight: "12px"}}>{y}</td>)}
                        </tr>
                    ])}
                </tbody>
            </table>
        </Box>
    )
}

export function CreateDryadTableDialog({
    api_endpoint,
    open,
    onCreate,
    onClose: parentOnClose,
}) {

    const axios = useAPI();

    const [form_state, set_form_state] = useState({});
    const [error, set_error] = useState(null);

    const update_form_state = (key, value) => {
        set_form_state({...form_state, [key]: value})
    }

    const submit = () => {
        let title = (form_state?.title || "").trim()
        if (title === "") {
            set_error("you must supply a title")
            return;
        }

        axios.post(
            api_endpoint,
            form_state
        ).then((res) => {
            if (!res?.data) return;
            onCreate(res.data);
        })
    }

    const onClose = () => {
        set_form_state({});
        set_error(null);
        parentOnClose();
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Generate DRYAD Table</DialogTitle>
            <DialogContent sx={{paddingBottom: 0}}>
                <Grid sx={{display: "grid", gridTemplateRows: 'auto', gridTemplateColumns: 'repeat(3, 1fr)', p: 1, gridGap: '15px' }}>
                    <TextField
                        onChange={(e) => { update_form_state(e.target.name, e.target.value) }}
                        sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        name="title"
                        error={!!error}
                        helperText={error||" "}
                        required label="Title (eg: Operation Exercise)"
                        InputLabelProps={{shrink: true}} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={submit}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}


export default function AuthenticationDryadTable() {

    const axios = useAPI()
    const params = useParams()

    const [state, setState] = useState();
    const [showCreateDialog, setShowCreateDialog] = useState(false);

    useEffect(() => {
        axios.get(`/campaigns/${params.campaign_id}/auth/dryad`).then((res) => {
            if (!res?.data) return;
            setState(res.data);
            setShowCreateDialog(false);
        })
    }, []);

    // Just draw a fixed table since there's no editing here
    return (
        <Box>
            <AuthenticationDryadTableElement title={state?.title} rows={state?.data||[]} version={state?.version} />

            <Box sx={{display: "flex", justifyContent: "end", width: "610px"}}>
                <Button onClick={() => setShowCreateDialog(true)}>{state ? "Update" : "Create" }</Button>
            </Box>


            <CreateDryadTableDialog
                api_endpoint={`/campaigns/${params.campaign_id}/auth/dryad/create`}
                open={showCreateDialog}
                onClose={() => { setShowCreateDialog(false) }}
                onCreate={(data) => { setState(data); setShowCreateDialog(false); }}
            />
        </Box>
    )
}