import { Button } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAPI } from "../../hooks/useAxios"
import BreadcrumbLayout from "../../layout/BreadcrumbLayout"
import CampaignCard from "../Campaign/Components/CampaignCard"
import CreateCampaignDialog from "../Campaign/Components/CreateCampaignDialog"

export default function WingCampaignPage() {

    const axios = useAPI();
    const params = useParams();

    const [show_create_campaign, set_show_create_campaign] = useState(null);

    const [campaign_cards, set_campaigns] = useState([]);

    const get_card_from_data = (data) => {

        return <CampaignCard
            id={data.id}
            target={`/campaigns/${data.id}`}
            key={`${data.wing_id}:${data.code}`}
            title={`${data.code}: ${data.name}`}
            manager={data.managed_by.username}
            avatar={data.tag}
            avatarColor={data.tagColor}
            detailMarkdown={data.descriptionMarkdown}
            actions={[
                <Button key="edit" onClick={() => set_show_create_campaign(data)}>Edit</Button>
            ]}
        />
    }

    useEffect(() => {
        axios.get(`/wing/${params.wing_id}/campaigns`).then((res) => {
            if (!res.data) return;

            let cards = []
            for (const campaign of res.data) {
                cards.push(get_card_from_data(campaign))
            }

            set_campaigns(cards);
        });
    }, []);

    const onCreate = (campaign) => {
        let card = get_card_from_data(campaign)
        set_campaigns([...campaign_cards, card]);
        set_show_create_campaign(null);
    }

    const onUpdate = (campaign) => {
        let new_cards = [];
        for (const card of campaign_cards) {
            new_cards.push(card.props.id === campaign.id ? get_card_from_data(campaign) : card)
        }
        set_campaigns(new_cards);
        set_show_create_campaign(null);
    }

    // List of campaigns, or add a campign
    return (
        <BreadcrumbLayout
            actions={[
                <Button onClick={() => { set_show_create_campaign({wing_id: params.wing_id}) }}>CREATE CAMPAIGN</Button>
            ]}
        >
            <Box sx={{p:1}}>
                {campaign_cards}
            </Box>
            <CreateCampaignDialog
                onCreate={onCreate}
                onUpdate={onUpdate}

                data={show_create_campaign}
                open={show_create_campaign !== null}
                close={() => { set_show_create_campaign(null) }}
            />
        </BreadcrumbLayout>
    )

}