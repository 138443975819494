import { Button } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { GridFooterContainer } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddAgencyDialog from "../../components/AddAgencyDialog";
import GridAirfieldAgency from "../../components/Grids/GridAirfieldAgency";
import GridTheatreAirfields from "../../components/Grids/GridTheatreAirfields";
import { StripedDataGrid } from "../../components/StripedDataGrid";
import { useAPI } from "../../hooks/useAxios";
import BreadcrumbLayout from "../../layout/BreadcrumbLayout";

export default function WingTheatreInfoPage() {

    // Lookup all our data
    const params = useParams();
    const [theatre, setTheatre] = useState(null);

    const breadcrumbs = [
        { title: 'Theatres', target: ".." },
        { title: theatre },
    ];

    return (
        <BreadcrumbLayout breadcrumbs={breadcrumbs}>
            <GridTheatreAirfields apiEndpoint={`/wing/${params.wing_id}`} setTheatre={(name) => setTheatre(name)} />
        </BreadcrumbLayout>
    )

}