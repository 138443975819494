import { Cancel } from "@mui/icons-material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useState } from "react";


export default function TestPage2() {

    const [rows, setRows] = useState([
        {
            id: 1,
            name: "test row 1",
        },
        {
            id: 2,
            name: "test row 2",
        },
        {
            id: 3,
            name: "test row 3",
        },
    ]);

    const [pinnedRows, setPinnedRows] = useState({
        top: [
            {
                id: 0,
                name: "pinned row 0",
            }
        ]
    })

    const handleDeleteClick = (id) => () => {
        if (id === 0) setPinnedRows(null);
        else setRows(rows.filter((x) => x.id !== id))
    }

    const columns = [
        {
            field: "name",
            headerName: "name",
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex: 1,
            maxWidth: 80,
            sx: {
                backgroundColor: "white",
            },
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<Cancel />}
                        label="Save"
                        onClick={handleDeleteClick(id)}
                    />,
                ]
            }
        }
    ];


    return (
        <DataGridPro
            columns={columns}
            rows={rows}
            pinnedRows={pinnedRows}
            autoHeight
            experimentalFeatures={{
                rowPinning: true,
            }}
        />
    )

}