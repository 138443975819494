
export function updateDataGridRows(rows, updates, additions, deletes) {

    let new_rows = []
    let actions_by_id = {}

    for (const item of (updates||[])) {
        if (!item?.id) continue
        actions_by_id[item.id] = ['update', item]
    }

    for (const item of (deletes||[])) {
        if (!item?.id) continue
        actions_by_id[item.id] = ['delete']
    }

    for (const item of rows) {
        let action = actions_by_id?.[item.id]?.[0];

        if (action === "delete") continue;
        if (action === "update") {
            new_rows.push(actions_by_id[item.id][1])
        } else {
            new_rows.push(item)
        }
    }

    for (const item of (additions||[])) {
        new_rows.push(item)
    }

    return new_rows;
}