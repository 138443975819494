import { Checkbox, FormControl, InputLabel, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select } from "@mui/material";

export default function MultiSelectCheckbox({
    name,
    label,
    items,
    value,
    onChange,
}) {

    const items_by_id = Object.fromEntries(items.map((itm) => [itm.id, itm]));

    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="multi-select-checkbox-label" shrink>{label}</InputLabel>
            <Select
                labelId="multi-select-checkbox-label"
                name={name}
                multiple
                value={value}
                onChange={onChange}
                input={<OutlinedInput notched label={label} />}
                renderValue={(selected) => selected.map((x) => items_by_id[x].display_name).join(', ')}
            >
            {items.map((item) => (
                item.subheader
                    ? <ListSubheader key={item.name}>{item.name}</ListSubheader>
                    : (
                        item.checkbox === undefined || item.checkbox === true
                        ? <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={value.includes(item.id) } />
                            <ListItemText primary={item.display_name} />
                          </MenuItem>
                        : <MenuItem key={item.id} value={item.id} disabled={item.disabled}>{item.display_name}</MenuItem>
                    )
            ))}
            </Select>
        </FormControl>
    )


}