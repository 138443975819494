import { ArrowForwardIosSharp } from "@mui/icons-material";
import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Avatar, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom"
import { useAPI } from "../../hooks/useAxios";
import { useShowDialog, useShowError } from "../../hooks/useErrorDialog";
import BreadcrumbLayout from "../../layout/BreadcrumbLayout";
import MizFileParser from "../../parsers/MizFileParser";
import LoadingDialog from "./Components/LoadingDialog";
import GridTheatreAirfields from "../../components/Grids/GridTheatreAirfields";
import GridAgencies from "../../components/Grids/GridAgencies";
import GridNavpoints from "../../components/Grids/GridNavpoints";
import LayoutMizAirfieldInfo from "../../components/LayoutMizAirfieldInfo";
import GridBullseye from "../../components/Grids/GridBullseye";


const SourceContainer = ({
    source,
    onDelete,
}) => {


    const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    const display_names = {
        "miz": "Mission File",
    }

    const date = new Date(source.modified_at);
    const showDialog = useShowDialog();

    const confirmDelete = () => {
        showDialog("Confirm", `Are you sure you wish to delete the miz`, "DELETE", () => { onDelete(source); });
    }

    return (
        <Card sx={{pb:0}}>
            <CardContent sx={{
                display: "flex",
                width: '100%',
                p:0,
                "&:last-child": {
                    pb: 0
                }
            }}>
                <CardHeader
                    sx={{display: "flex", flexGrow: 1}}
                    avatar={<Avatar sx={{ bgcolor: '#99ccff' }} variant="rounded" aria-label="recipe">miz</Avatar>}
                    title={`${source.filename}`}
                    subheader={date.toLocaleString('en-GB', date_options)}
                />
                <Box sx={{display: "flex", alignItems: 'center', pr:1}}>
                    <Button onClick={confirmDelete}>DELETE</Button>
                </Box>
            </CardContent>
        </Card>
    )
}


const SourcesList = ({
    apiEndpoint,
    onDelete,
    reloadTrigger,
}) => {

    const axios = useAPI();
    onDelete ??= () => {}

    const [sources, setSources] = useState([]);

    useEffect(() => {
        axios.get(apiEndpoint).then((res) => {
            if(!res.data) return;
            setSources(res.data);
        })
    }, [apiEndpoint, reloadTrigger])


    return (
        <Box sx={{}}>
            {sources.map((x) => <SourceContainer key={x.id} source={x} onDelete={onDelete} />)}
        </Box>
    )

}



const AccordionSummary = styled(({children, ...props}) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        {...props}
    >
        <Box sx={{whiteSpace: "nowrap"}}>
            {children}
        </Box>
        <Box sx={{paddingLeft: '10px', width: '100%'}}>
            <hr style={{height: '2px', border: 0, borderBottom: '1px solid #666', borderImageWidth: 0}} />
        </Box>
    </MuiAccordionSummary>
  ))(({ theme }) => ({
    //backgroundColor: "transparent",
        /*theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .0)'
        : 'rgba(0, 0, 0, .0)',*/

    flexDirection: 'row-reverse',
    color: '#666',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: "unset",
    border: `0px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: 'unset',
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


export default function CampaignTheatreEdit() {

    const axios = useAPI();
    const params = useParams();

    const fileRef = useRef();
    const [state, setState] = useState();
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState("navpoints");
    const [reloadTrigger, setReloadTrigger] = useState(0);

    const showError = useShowError();
    const navpointRef = useRef()


    useEffect(() => {
        axios.get(`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}`).then((res) => {
            if (!res.data) return;
            setState(res.data)
        })
    }, [params.campaign_id, params.theatre_id, axios, reloadTrigger]);

    const expand = (section) => {
        setExpanded(expanded === section ? null : section)
    }


    const processUpload = async (e) => {
        if (!e.target.value) return;

        // Otherwise we start our async loading
        setLoading(true)

        MizFileParser(axios, parseInt(params.theatre_id), e.target.files[0]).then((res) => {

            // Firstly, if we're not for this theatre, dont do anything
            if (res.theatre !== state.module) {
                showError("Processing Failed", `The file selected is ${res.theatre} not for ${state.module}`);
                return;
            }

            // We just send this to the server to process, rather than determine
            // what's changed here This allows us to explicitly relate the
            // existing items etc. in the DB and clean up we return the full
            // theatre info and replace our state as if we loaded the page for
            // the first time
            axios.post(`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}/sources/miz`, res).then((res) => {
                if (!res?.data) return;
                // Build our new state
                setReloadTrigger(reloadTrigger+1)
            })

        }).catch((e) => {
            console.log(e)
            showError("Processing Failed", JSON.stringify(e))
        }).finally(() => {
            setLoading(false);
            e.target.value = null;
        })
    }

    const onDeleteSource = (source) => {
        axios.delete(`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}/sources/miz/${source.id}`).then((res) => {
            if (!res?.data) return;
            setState(res.data);
            setReloadTrigger(reloadTrigger+1);
        });
    }

    // Here, we edit agencies, navpoints, and allow uploads from MIZ, oh the complex shit - though if it works for campaign, it'll work on event
    return (
        <BreadcrumbLayout
            breadcrumbs={[
                {title: "Theatre Data", target: ".."},
                {title: (state?.display_name || "") },
            ]}
        >
            <Box>
                <input onChange={processUpload} type="file" id="file" ref={fileRef} style={{display: "none"}} accept=".miz" />
                <Accordion expanded={expanded === "imports"} onChange={() => expand("imports")}>
                    <AccordionSummary>Data Imports</AccordionSummary>
                    <AccordionDetails sx={{pt:0}}>
                        <Button key="update" onClick={() => { fileRef.current.click() } }>Update Data</Button>
                        <SourcesList reloadTrigger={reloadTrigger} apiEndpoint={`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}/sources`} onDelete={onDeleteSource} />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "bullseye"} onChange={() => expand("bullseye")}>
                    <AccordionSummary>Bullseye</AccordionSummary>
                    <AccordionDetails>
                        <GridBullseye
                            reloadTrigger={reloadTrigger}
                            apiEndpoint={`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}/bullseye`} />
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === "navpoints"} onChange={() => expand("navpoints")}>
                    <AccordionSummary>Navpoints</AccordionSummary>
                    <AccordionDetails>
                        <GridNavpoints
                            reloadTrigger={reloadTrigger}
                            apiEndpoint={`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}/navpoints`}
                            theatre={state?.module}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === "theatre"} onChange={() => expand("theatre")}>
                    <AccordionSummary>Theatre Airfields</AccordionSummary>
                    <AccordionDetails>
                        <GridTheatreAirfields
                            apiEndpoint={`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}/airfields`}
                            toggleable
                            useRouting={false}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === "airfields"} onChange={() => expand("airfields")}>
                    <AccordionSummary>Mission Airfields</AccordionSummary>
                    <AccordionDetails>
                        <GridTheatreAirfields
                            apiEndpoint={`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}/miz_airfields`}
                            toggleable
                            editable
                            displayField='unit_name'
                            useRouting={false}
                            component={LayoutMizAirfieldInfo}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "tankers"} onChange={() => expand("tankers")}>
                    <AccordionSummary>Mission Tankers</AccordionSummary>
                    <AccordionDetails>
                        Tanker information, (lat, lon of orbit / racetrack point), alt, tcn, type (boom / drogue / both) to facilitate ATO selection
                        <br/>
                        Able to add custom tankers as they could be scripted additions
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "agencies"} onChange={() => expand("agencies")}>
                    <AccordionSummary>Administrative Agencies</AccordionSummary>
                    <AccordionDetails>
                        <GridAgencies
                            apiEndpoint={`/campaigns/${params.campaign_id}/theatres/${params.theatre_id}/agencies`}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "presets"} onChange={() => expand("presets")}>
                    <AccordionSummary>Airframe Presets</AccordionSummary>
                    <AccordionDetails>
                        Read only view of Airframes available in the miz, their presets, and errors
                        <br/>
                        This can show which airfield they are available at on hover state (used to limit ATO depart from selector if available)
                    </AccordionDetails>
                </Accordion>
            </Box>
            <LoadingDialog open={loading} />
        </BreadcrumbLayout>
    )
}