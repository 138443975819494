// General inputFilter / validator Cell for the grid

import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import { useCellCommitValue } from "../GridManagement/useCellCommitValue";
import GridCellDisplay from "./GridCellDisplay";
import GridCellInput from "./GridCellInput";

export const GridCellValidated = ({
    value: initialValue,
    displayValue: initialDisplayValue,
    gridProps,

    stringFormatter: upstreamFormatter,

    sx,

    inputType,
    inputMin,
    inputMax,
    inputStep,
    inputFilter,
    inputFormatter,

    validator,
    input,
}) => {

    const defaultStringFormatter = useMemo(() => (x) => x);
    const stringFormatter = upstreamFormatter || defaultStringFormatter;

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [displayValue, setDisplayValue] = useState(initialDisplayValue);
    const [error, setError] = useState(null);

    const cellCommitValue = useCellCommitValue();

    const onChange = async (e) => {

        let newValue = e.target.value;
        console.log(newValue);

        if (typeof(inputFilter) === "function") {
            if(!inputFilter(newValue)) {
                return
            }
        }

        if (typeof(inputFormatter) === "function") {
            newValue = inputFormatter(newValue);
        };

        // If we're numeric, make sure empty is 0
        if (newValue === "" && inputType === "number") {
            newValue = 0;
        }

        // always check the validator against a string
        const error = typeof(validator) === "function" ? validator(newValue) : null;

        console.log(newValue);
        // We update the real value with our object if possible

        cellCommitValue({
            id: gridProps.id,
            field: gridProps.field,
            value: error && error[0] === 2 ? {error: true} : newValue,
        });

        // and set our string value to whatever was entered
        setError(error ? error : null);
        setValue(newValue);
        setDisplayValue(stringFormatter(newValue))
    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        console.log("INITIAL VALUE CHANGED")
        if (!initialValue) initialValue = "";
        if (initialValue?.error === true) return;
        setValue(initialValue)
        setDisplayValue(stringFormatter(initialValue))
        setError(typeof(validator) === "function" ? validator(initialValue) : null);
    }, [initialValue, validator])

    if (input) return (

        <GridCellInput
            gridProps={gridProps}
            autoFocus={gridProps.hasFocus||false}

            feedbackLevel={error && error[0]}
            feedback={error && error[1]}

            value={displayValue}
            onChange={onChange}

            inputType={inputType}
            inputMin={inputMin}
            inputMax={inputMax}
            inputStep={inputStep}
        />
    )

    return <GridCellDisplay input sx={sx} gridProps={gridProps} feedbackLevel={error && error[0]} feedback={error && error[1]}>{displayValue}</GridCellDisplay>

}

const non_empty_validator = (v) => { if (!v.match(/^\s*[^\s]/)) return [2, "Must not be empty"]; return null }
const integer_validator = (v) => { if (!v.toString().match(/^[0-9]*$/)) return [2, "Must be an integer"]; return null }

export const renderGridCellStringNonEmpty = (mode) => (props) => {
    return <GridCellValidated
        gridProps={props}
        value={props.value}
        input={mode === "edit" || props.id === 0}
        validator={non_empty_validator}
    />
}

export const renderGridCellInteger = (mode) => (gridProps) => {
    return <GridCellValidated
        gridProps={gridProps}
        value={gridProps.value}
        input={mode === "edit" || gridProps.id === 0}
        inputType="number"
        inputStep={1}
        validator={integer_validator}
        sx={{justifyContent: 'right', pr: 1}}
    />
}