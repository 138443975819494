import { Cancel, CancelOutlined, CancelPresentation, Clear } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import StandardGrid from "../../components/Grid/StandardGrid";
import { CELL_FREQUENCY_WIDTH } from "../../components/GridCells/Constants";
import { renderGridCellFrequency, renderGridCellFrequencyEdit, renderGridCellFrequencySortComparator, renderGridCellFrequencyValueFormatter } from "../../components/GridCells/GridCellFrequency";
import { renderGridCellStringNonEmpty } from "../../components/GridCells/GridCellValidated";
import { reflectErrorValue } from "../../components/GridCells/reflectErrorValue";
import { getGridRowPatchObject } from "../../components/GridManagement/getGridRowPatchObject";
import { useAPI } from "../../hooks/useAxios";
import { useShowError } from "../../hooks/useErrorDialog";

export default function WingFrequencyCodes() {

    const axios = useAPI();
    const params = useParams()
    const showError = useShowError();
    const codes_per_key = 12;

    const [rows, set_rows] = useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(null);

    const frequency_cols = useMemo(() => {
        let cols = [
            {
                field: 'code',
                headerName: "Code",
                editable: true,
                width: 85,
                headerClassName: 'filled hideSeparator',
                cellClassName: 'fullCell',
                renderCell: renderGridCellStringNonEmpty(),
                renderEditCell: renderGridCellStringNonEmpty("edit"),
                preProcessEditCellProps: reflectErrorValue,

            }
        ]

        for (let i = 1; i <= codes_per_key; i++) {
            cols.push({
                field: `${i}`,
                headerAlign: "center",
                editable: true,
                width: CELL_FREQUENCY_WIDTH,
                align: "right",
                cellClassName: 'fullCell',
                renderCell: renderGridCellFrequency,
                renderEditCell: renderGridCellFrequencyEdit,
                preProcessEditCellProps: reflectErrorValue,
                valueFormatter: renderGridCellFrequencyValueFormatter,
                sortComparator: renderGridCellFrequencySortComparator,
            })
        }

        // Push our action col, so we can put a spacer afeter
        cols.push({
            field: 'actions',
            headerName: 'Actions',
            headerClassName: '',
            cellClassName: '',
            type: 'actions',
            getActions: (props) => [
                <GridActionsCellItem
                    icon={<Clear />}
                    label="Save"
                    onClick={deleteCode(props.row)}
                />
            ]
        })

        cols.push({
            field: 'spacer',
            cellClassName: 'blank',
            headerClassName: 'hideSeparator nooutline',
            headerName: '',
            flex: 1,
            sortable: false,
            editable: false,
        })

        return cols
    }, []);

    const deleteCode = (row) => () => {
        // Confirm before requesting delete
        setShowConfirmDelete({id: row.id, code: row.code});
    }

    const confirmDeleteCode = () => {
        let deleted_id = showConfirmDelete.id;
        axios.delete(`/wing/${params.wing_id}/frequencies/${deleted_id}`).then((res) => {
            set_rows(rows.filter((x) => x.id !== deleted_id))
        });
        setShowConfirmDelete(null);
    }

    const cancelDeleteCode = () => {
        setShowConfirmDelete(null);
    }



    const onCreateRow = async (newRow) => {

        // Ensure name is not empty
        if ((newRow.code||"").trim() === "") {
            showError("Invalid Code", "Name must not be empty!")
            return;
        }

        // Bundle up an array of frequencies
        let values = []
        for (const [key, value] of Object.entries(newRow)) {
            if (key === 'code') continue;
            if (!value) continue;
            values.push({
                frequency_id: key,
                frequency: value,
            })
        }

        if (values.length === 0) {
            showError("Invalid Code", "At least one frequency must be specified")
            return;
        }

        const create_data = {
            code: newRow.code,
            values: values,
        }

        // to json
        return axios.post(`/wing/${params.wing_id}/frequencies`, create_data).then((res) => {
            if (!res?.data) return Promise.reject();

            // Merge our freqs
            let new_row = {
                id: res.data.id,
                code: res.data.code,
            }

            for (const freq of res.data.values) {
                new_row[freq.frequency_id] = freq.frequency
            }

            set_rows([...rows, new_row]);
            return
        });

    }

    useEffect(() => {
        axios.get(`/wing/${params.wing_id}/frequencies`).then((res) => {
            if (!res?.data) return;
            let new_rows = [];
            for (let row of res.data) {
                for (const freq of row.values) {
                    row[freq.frequency_id] = freq.frequency
                }
                new_rows.push(row)
            }
            set_rows(new_rows);
        })
    }, []);

    const updateFrequencyRow = (newRow, oldRow) => {

        let diff = getGridRowPatchObject(newRow, oldRow)
        if (!diff) return oldRow;

        let patch = {
            values: [],
        }

        for (const [key, value] of Object.entries(diff)) {
            if (key === 'code') {
                patch[key] = value
            } else {
                patch.values.push({frequency_id: key, frequency: value})
            }
        }

        return axios.patch(`/wing/${params.wing_id}/frequencies/${oldRow.id}`, patch).then((res) => {
            if (!res?.data) return oldRow
            return newRow
        });
    }

    return (
        <Box sx={{display: "flex", height: '100%', flexGrow: 1}}>
        <StandardGrid
            sx={{
                display: "flex",
                //minWidth: `${100*12 + 80 + 11 + 76}px`,
                '& .MuiDataGrid-cell': {
                    align: 'right',
                    borderBottom: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                },
                '& .MuiDataGrid-cell.blank': {
                    border: 0,
                    backgroundColor: "transparent !important",
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #ccc',
                },
                '& .filled': {
                    backgroundColor: '#ddd',
                    borderColor: '#ccc',
                }
            }}

            initialState={{
                sorting: {
                    sortModel: [{ field: 'code', sort: 'asc' }],
                },
                pinnedColumns: { left: ['code'] },
            }}

            columns={frequency_cols}
            rows={rows}

            createRowTitle="ADD CODE"
            createRow={onCreateRow}

            disableColumnMenu

            getCellClassName={(params) => {
                if (params.field === 'code') return 'filled'
            }}

            processRowUpdate={updateFrequencyRow}
        />
        <Dialog
            open={showConfirmDelete!==null}
            onClose={cancelDeleteCode}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {`Are you sure you wish to delete ${showConfirmDelete?.code}?`}
            </DialogTitle>
            <DialogActions>
                <Button onClick={cancelDeleteCode}>No</Button>
                <Button onClick={confirmDeleteCode} autoFocus>Yes</Button>
            </DialogActions>
        </Dialog>
        </Box>
    )


}