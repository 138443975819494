import proj4 from "proj4";
import LatLon from "./LatLon";

export const THEATRE_TRANSFORM_DATA = {
    'Caucasus': {
        'central_meridian': 33,
        'false_easting': -99516.9999999732,
        'false_northing': -4998114.999999984,
    },
    'Falklands': {
        'central_meridian': -57,
        'false_easting': 147639.99999997593,
        'false_northing': 5815417.000000032,
    },
    'MarianaIslands': {
        'central_meridian': 147,
        'false_easting': 238417.99999989968,
        'false_northing': -1491840.000000048,
    },
    'Nevada': {
        'central_meridian': -117,
        'false_easting': -193996.80999964548,
        'false_northing': -4410028.063999966,
    },
    'Normandy': {
        'central_meridian': -3,
        'false_easting': -195526.00000000204,
        'false_northing': -5484812.999999951,
    },
    'PersianGulf': {
        'central_meridian': 57,
        'false_easting': 75755.99999999645,
        'false_northing': -2894933.0000000377,
    },
    'TheChannel': {
        'central_meridian': 3,
        'false_easting': 99376.00000000288,
        'false_northing': -5636889.00000001,
    },
    'Syria': {
        'central_meridian': 39,
        'false_easting': 282801.00000003993,
        'false_northing': -3879865.9999999935,
    },
}

export class CoordinateConverter {

    static get_transform(theatre) {

        const origins = THEATRE_TRANSFORM_DATA[theatre];

        return '+proj=tmerc ' +
            '+lat_0=0 ' +
            `+lon_0=${origins.central_meridian} ` +
            '+k_0=0.9996 ' +
            `+x_0=${origins.false_easting} ` +
            `+y_0=${origins.false_northing} ` +
            '+towgs84=0,0,0,0,0,0,0 ' +
            '+units=m ' +
            '+vunits=m ' +
            '+ellps=WGS84 ' +
            '+no_defs ' +
            '+axis=neu';
    };

    static dcs_to_ll(x, y, theatre, accuracy) {
        const ll = proj4(
            CoordinateConverter.get_transform(theatre),
            proj4.Proj("WGS84"),
            proj4.toPoint([y, x]));

        // 7 decimal places is ~1cm
        const exp = 10 ** (accuracy || 7);

        return {
            'x': x,
            'y': y,
            'lat': Math.round(ll.y * exp) / exp,
            'lon': Math.round(ll.x * exp) / exp,
            'theatre': theatre,
        }
    }

    static ll_to_dcs(lat, lon, theatre, accuracy) {
        const xy = proj4(
            proj4.Proj("WGS84"),
            CoordinateConverter.get_transform(theatre),
            proj4.toPoint([lon, lat]));

        // DCS is in meters, so we maintain our 1cm
        const exp = 10 ** (accuracy || 2);
        return {
            'x': Math.round(xy.y * exp) / exp,
            'y': Math.round(xy.x * exp) / exp,
            'lat': lat,
            'lon': lon,
            'theatre': theatre,
        }
    }

    static ll_to_mgrs(lat, lon, accuracy) {
        return proj4.mgrs.forward([lon, lat], accuracy);
    }

    static dcs_to_mgrs(x, y, theatre, accuracy) {
        var ll = this.dcs_to_ll(x, y, theatre)
        return proj4.mgrs.forward([ll.lon, ll.lat], accuracy);
    }
}