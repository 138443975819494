import { Link, Navigate, useNavigate, useParams } from "react-router-dom"
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useContext, useEffect, useState } from "react";
import { AxiosContext } from "../../contexts/AxiosContext";
import { Box } from "@mui/system";
import BreadcrumbLayout from "../../layout/BreadcrumbLayout";
import { useAPI } from "../../hooks/useAxios";

export default function DataAirframesPage(props) {

    const params = useParams()
    const navigate = useNavigate();
    const axios = useAPI();

    const [airframes, setAirframes] = useState([]);

    const columns = [
        { field: 'id', width: 80 },
        { field: 'display_name', flex: 100 },
        { field: 'enabled', type: 'boolean', width: 140 }
    ]

    useEffect(() => {
        axios.get('airframe').then((res) => {
            setAirframes(res.data);
        });
    }, []);

    const onRowClick = (e) => {
        navigate(e.id.toString())
    }

    return(
        <BreadcrumbLayout breadcrumbs={[{title: 'Airframes', target: "/"}]}>
            <DataGridPro
                initialState={{
                    sorting: {
                    sortModel: [{ field: 'display_name', sort: 'asc' }],
                    },
                }}
                onRowClick={onRowClick} rows={airframes} columns={columns} disableSelectionOnClick autoHeight />
        </BreadcrumbLayout>
    )
}