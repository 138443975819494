import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TheatreList from "../../components/TheatreList";

export default function CampaignTheatreRoot({campaign}) {

    const navigate = useNavigate();

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{width: '100%'}}>
                <Typography>
                    {campaign?.name}
                </Typography>
            </Box>
            <Typography variant="h4" sx={{marginBottom: '20px'}}>
                Campaign Theatre Data
            </Typography>
            <Typography sx={{marginBottom: '20px'}}>
                Campaign theatre data inherits wing-theatre data and forms the foundational data from which an event inherits.
                <br/><br/>
                This can be especially useful to populate for repeated events such as training events facilitating reduced workload for event hosts.
                <br/><br/>
                Select a theatre to configure:
            </Typography>
            <List
                sx={{
                    listStyleType: "disc",
                    pl: 4,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    },
                }}
            >
                <ListItem disablePadding disableGutters><Typography>Nav Points</Typography></ListItem>
                <ListItem disablePadding disableGutters><Typography>Agencies</Typography></ListItem>
            </List>

            <TheatreList onRowClick={(e) => { navigate(e.id.toString())}} />
        </Box>
    )
}