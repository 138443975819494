import { createContext } from "react";

export const GridUpdateContext = createContext({
    cellCommitValue: () => {},
});

export const GridUpdateContextProvider = ({
    value,
    children,
}) => {
    return (
        <GridUpdateContext.Provider value={value}>
            {children}
        </GridUpdateContext.Provider>
    )
}