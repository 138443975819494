import { Route, Routes } from "react-router-dom";
import DataAirframeDetailPage from "./DataAirframeDetailPage";
import DataAirframesPage from "./DataAirframesPage";

export default function DataAirframesRouter(props) {

    return (
        <Routes>
            <Route path="/:airframe_id" element={<DataAirframeDetailPage/>} />
            <Route path="" element={<DataAirframesPage />} />
        </Routes>
    )
}