import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Tab, Tabs } from "@mui/material";
import { Link, Navigate, Route, Routes, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import ContentBarLayout from "./ContentBarLayout";

export default function BreadcrumbLayout({
    children,
    breadcrumbs,
    actions,
}) {

    // Find our active tab based on index
    let location = useLocation();
    let current = useResolvedPath("")

    //let active_child = location.pathname.startsWith(current.pathname) ? location.pathname.substring(current.pathname.length).split('/')[1] || "" : "";
    //let breadcrumbs = config.filter(x => x.title !== undefined);
    //let active_index = breadcrumbs.findIndex(x => x.prefix === active_child);

    return (
        <ContentBarLayout
            bar={
                <Breadcrumbs separator={<NavigateNext fontSize="small"/>}>
                    {(breadcrumbs || []).map((elem) => {
                        if (elem.target === undefined) return <div key={`dummy_${elem.title}`}>{elem.title}</div>
                        return <Link to={elem.target} key={`${elem.target}_${elem.title}`}>{elem.title}</Link>
                    })}
                </Breadcrumbs>
            }
            actions={
                actions
            }
        >
            {children}
        </ContentBarLayout>
    )
}