import { useEffect, useState } from "react";
import { CoordinateConverter } from "../common/Coordinates";
import TopBar from "../layout/TopBar";

function Image({ image, title, content, position, color, maxWidth, minWidth }) {

    color = color || "#ffffff";
    minWidth = minWidth || 400;
    maxWidth = maxWidth || 600;

    const pos = (() => {
        if (position === "br") return { right: "40px", bottom: "140px" };
        if (position === "bl") return { left: "40px", bottom: "140px" };
        if (position === "tr") return { right: "40px", top: "80px" };
        return { left: "40px", top: "80px" };
    })();

    return (
        <div style={{position: "relative", width: "100%", height: "100%", scrollSnapAlign: "end"}}>
            <img src={`${process.env.PUBLIC_URL}/home/${image}`} style={{objectFit: "cover", width: "100%", height: "100%"}} alt="A London skyscraper" />
            <div style={{position: "absolute", display: 'table', width: '1%', minWidth: `${minWidth}px`, maxWidth: `${maxWidth}px`, ...pos}}>
                <div style={{fontFamily: "var(--wm-font-header)", fontSize: "38px", fontWeight: "bold", color: color, whiteSpace: "nowrap"}}>
                    {title}
                </div>
                <div style={{width: '100%', paddingTop: "5px", fontFamily: "var(--wm-font-body)", fontSize: "14px", color: color}}>
                    {content}
                </div>
            </div>
        </div>
    );
  }


export default function HomePage({
    forwardRef
}) {

    useEffect(() => {

        let wheelHandler = (e) => {
            e.preventDefault();
            forwardRef.current.scrollTo(0,forwardRef.current.scrollTop+window.innerHeight *(e.deltaY > 0 ? 1: -1));
        };

        forwardRef.current.addEventListener('wheel', wheelHandler, { passive: false });

        return () => {
            forwardRef.current.removeEventListener('wheel', wheelHandler);
        }
    }, [forwardRef]);

    return (
        <>
        <TopBar/>
        <div style={{width: "100%", height: "100%", scrollSnapAlign: "end"}}>
            <Image image="page1.png"
                title="DCS Wing Manager" content={
                <>
                    A new approach to consolidate the administration of wings for DCS. From simple recurring training
                    events to multi-event, munition inventory managed, inteligence driven campaigns.
                    <br/><br/>
                    Scroll down for more
                    { JSON.stringify(CoordinateConverter.dcs_to_ll(611639.38426116, -277400.89436233, "Caucasus")) }
                </>
             } />
            <Image image="page2.jpg"
                position="tr" color="#000"
                title="Manage Your Wing"
                maxWidth={800}
                content={
                <>
                    Complete management of wing operations such as Membership, Maintaining Squadrons with Roles, Ranks, and Qualifications.
                    Manage your Wing's default data such as per-theatre agencies such as manned ATC stations, Frequency charts and authentication codes.
                </>
            } />
            <Image image="page3.png"
                position="bl" color="#fff"
                title="Multi-Event Campaigns"
                content={
                <>
                    Campaigns act as containers to hold Events and associated information - campaign wide agencies, Code Words,
                    Nav Points, Optional campaign based Authentication or Freqnecy Charts as required or defaulting to your Wing's defaults
                </>
            } />
            <Image image="page1.png"
                position="tl" color="#fff"
                title="Event Hosting"
                minWidth={500}
                content={
                <>
                    Easily create your Private, Semi-Private (Allied Wings) or Public Event from a painless .miz file import process
                    to extract Mission Airfield, Navdata, tanker information  and per-airframe presets.
                    <br/><br/>
                    Create taskings, with automatic internal Frequency, TCN and Laser code assignment without worrying
                    about airframe compatability or ensuring deconfliction with other event or campaign wide agencies.
                </>
            } />
            <Image image="page3.png"
                title="Event Participation"
                position="br"
                color="#fff"
                minWidth={700}
                content={
                <>
                    Easier than ever to participate in an Event!<br/>
                    <ul>
                        <li>Sign Up to an Event and wait to be allocated to a flight by an Event Host</li>
                        <li>Plan your waypoints, routes, comms plan, presets, and loadouts for your flight members
                            <ul>
                                <li>With Import from CombatFlite, Miz, or Google Earth</li>
                            </ul>
                        </li>
                        <li>Add images or other notes to your tasking</li>
                        <li>Download your individual mission-ready kneeboard pack
                            <ul>
                                <li>Pre-Formatted PNG or PDF Kneeboard complete covering all aspects of your flight</li>
                                <li>DCS DTC Data Loader or A-10C DataLoader compatable files for supported Airframes</li>
                            </ul>
                        </li>
                    </ul>
                </>
                }/>
            <Image image="page1.png"
                title="Get Started!"
                color="#fff"
                minWidth={700}
                content={
                <>
                    Sign Up Now using your Discord login, and the rest is up to you:
                    <ul>
                        <li>Sign up to a Public Event</li>
                        <li>Join a Wing</li>
                        <li>Create a Wing</li>
                    </ul>
                </>
                }/>
        </div>
        </>
      );
}