import { Box } from "@mui/system";
import GridAirfieldAgency from "./Grids/GridAirfieldAgency";
import GridMizAirfieldInfo from "./Grids/GridMizAirfieldInfo";
import GridTheatreAirfields from "./Grids/GridTheatreAirfields";



export default function LayoutMizAirfieldInfo({
    apiEndpoint,
}) {

    return (
        <Box>
            <GridMizAirfieldInfo
                apiEndpoint={apiEndpoint}
            />
            <GridAirfieldAgency
                apiEndpoint={apiEndpoint}
            />
        </Box>
    )

}