import { Cancel, Check, Close, Save } from "@mui/icons-material";
import { Box, Button, LinearProgress } from "@mui/material";
import { GridActionsCellItem, GridFooterContainer, useGridApiRef, GridFooter, selectedGridRowsCountSelector } from "@mui/x-data-grid-pro";

import { useCallback, useEffect, useMemo, useState } from "react";
import { GridUpdateContextProvider } from "../GridManagement/GridUpdateContext";
import { StripedDataGrid } from "../StripedDataGrid";


export default function StandardGrid({

    createRow,          // async Function to create row
    createRowTitle,     // button title
    createInitialProps, // Initial Props

    // Things we overwrite, or want to reference
    sx,
    components,
    columns: columnDefs,
    processRowUpdate,

    ...gridProps
}) {

    createRow ??= () => Promise.resolve();

    const [pinnedRow, setPinnedRow] = useState();
    const [createData, setCreateData] = useState({});

    const gridStyle = {
        m:1,
        '& .hideSeparator > .MuiDataGrid-columnSeparator': {
            visibility: "hidden",
        },
        '& .MuiDataGrid-columnHeader.nooutline': {
            outline: "none !important",
        },
        '& .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable):focus': {
            outline: "none",
        },
        '& .MuiDataGrid-row.edit > .MuiDataGrid-cell.actions': {
            borderBottom: "1px solid #ccc",
            borderRight: "2px solid #ccc",
        },
        '& .MuiDataGrid-actionsCell': {
            gridGap: 0,
        },
        '& .fullCell': {
            padding: '0 !important',
        },
        ...sx,
    }

    const gridRef = useGridApiRef();

    ///////////////////////////////////////////////////////////////////////////
    // PINNED ROW HANDLING
    ///////////////////////////////////////////////////////////////////////////

    const discardCreateRow = () => {
        // Currently triggers an uncaught exception
        // https://github.com/mui/mui-x/issues/7580
        setPinnedRow(null);
        setCreateData({});
    }

    // Create the pinned row and set cursor to first tabIndex 0 in the cell (our input)
    const handleAddRowClicked = () => {
        if (pinnedRow) return;
        setPinnedRow({id:0, ...createInitialProps});
        setCreateData(createInitialProps);

        setTimeout(() => {
            // If we have an editable row, try and select it
            const first_editable_column = columns.find((x) => x.editable === true)
            if (!first_editable_column) return;
            gridRef.current.getCellElement(0, first_editable_column.field)?.querySelector('[tabIndex = "0"]')?.focus();
        });
    }

    // Let process Row Update handle the additional validation
    const handleCreateRowSubmit = async () => {
        if (!pinnedRow) return;
        try {
            await createRow(createData).then(() => {
                discardCreateRow();
            })
        } catch(e) {}
    }

    // If we're in our fake edit mode, don't do any key events except tab
    const onCellKeyDown = (props, event) => {
        if (pinnedRow) {
            switch (event.key) {
                case 'Escape':
                    discardCreateRow();
                    event.defaultMuiPrevented = true;
                    return;
                case 'Enter':
                    handleCreateRowSubmit();
                    event.defaultMuiPrevented = true;
                    return;
                case 'Tab':
                    event.defaultMuiPrevented = true;
                    break;
            }
            event.defaultMuiPrevented = true;
        }
    }

    // Our context function to update our cell values from the pinned row "view" model
    // or update the grid proper
    const onCellCommitValue = useCallback(async ({id, field, value, forceUpdate}) => {
        if (id !== 0) {
            if (forceUpdate) {
                const old_row = gridRef.current.getRow(id)
                const new_row = {...old_row, [field]: value}
                processRowUpdate(new_row, old_row)
            } else {
                return gridRef.current.setEditCellValue({
                    id: id,
                    field: field,
                    value: value,
                });
            }
        } else {
            // Update our create data
            let new_data = {...createData, [field]: value}
            setCreateData(new_data);
            return
        }
    }, [processRowUpdate, gridRef, createData, setCreateData]);

    const ctxValue = useMemo(() => ({cellCommitValue: onCellCommitValue}), [onCellCommitValue]);

    ///////////////////////////////////////////////////////////////////////////
    // Update column defs for our pinned row actions
    ///////////////////////////////////////////////////////////////////////////

    const columns = (() => {
        // If we have an actions row, put the options behind an if
        // otherwise add the actions column
        if (!createRow) {
            return columnDefs;
        }

        let newDefs = []
        let hasActions = false;

        for (const colDef of columnDefs) {
            if (colDef.type !== 'actions') {
                newDefs.push(colDef);
                continue
            }

            // Replace getActions with our own for row 0
            hasActions = true;
            newDefs.push({
                ...colDef,
                cellClassName: `${colDef?.cellClassName} actions`,
                headerClassName: `${colDef?.headerClassName} nooutline`,
                getActions: (props) => {
                    if (props.id === 0) {
                        return [
                            <GridActionsCellItem
                                icon={<Check />}
                                label="Save"
                                onClick={handleCreateRowSubmit}
                            />,
                            <GridActionsCellItem
                                icon={<Close />}
                                label="Cancel"
                                onClick={discardCreateRow}
                            />,
                        ]
                    };

                    return colDef.getActions(props);
                }
            });
        }

        if (!hasActions && createRowTitle) {
            newDefs.push({
                field: 'actions',
                type: 'actions',
                width: 80,
                getActions: (props) => {
                    if (props.id === 0) {
                        return [
                            <GridActionsCellItem
                                icon={<Save />}
                                label="Save"
                                onClick={handleCreateRowSubmit}
                            />,
                            <GridActionsCellItem
                                icon={<Cancel />}
                                label="Cancel"
                                onClick={discardCreateRow}
                            />,
                        ]
                    };
                    return [];
                }
            });
        }

        return newDefs;

    })();


    return (
        <GridUpdateContextProvider value={ctxValue}>
            <StripedDataGrid
                sx={gridStyle}

                // Things we explicitly set
                apiRef={gridRef}
                columns={columns}
                onCellKeyDown={onCellKeyDown}

                processRowUpdate={processRowUpdate}
                experimentalFeatures={{
                    newEditingApi: true,
                    rowPinning: true,
                }}

                pinnedRows={pinnedRow ? {bottom: [pinnedRow]} : null}

                // Our footer for creating rows
                components={{
                    ...(components||{}),
                    Footer: () => (
                        <GridFooterContainer sx={{justifyContent: "end"}}>
                            {createRowTitle && <Button onClick={handleAddRowClicked}>{createRowTitle}</Button>}
                            <Box sx={{display: "flex", flexGrow: 1}} />
                            <GridFooter />
                        </GridFooterContainer>
                    ),
                    LoadingOverlay: LinearProgress,
                }}

                getRowClassName={(params) => {
                    if (params.id === 0) return 'edit'
                    return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }}

                // Rest of props
                {...gridProps}

            />
        </GridUpdateContextProvider>
    )
}