import { DiscordLoginButton } from "../../components/DiscordLoginButton";
import TopBar from "../../layout/TopBar";

export default function LoginPage() {

    // When we first get here, we'll try and do a silent login first, this allows us to use the
    // navigate api...seems weird but ... whatever

    return (
        <>
        <TopBar />
        <div style={{position: 'absolute', top: "0px", borderTop: "50px", display: "flex", justifyContent: 'center', maxWidth: "100%", alignItems: 'center', minWidth: "100%", width: '100%', height: "100%"}}>
            <div style={{width:"405px", padding: '20px', backgroundColor: "#ccc"}}>
                <div style={{fontSize: '20px'}}>You must be logged in to view this page</div>
                <hr/>
                <DiscordLoginButton />
            </div>
        </div>
        </>
    )
}


