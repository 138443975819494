import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import LatLon from "../../common/LatLon";
import { useCoordinateDialog } from "../../hooks/useCoordinateDialog";
import { useCellCommitValue } from "../GridManagement/useCellCommitValue";
import { GridCellOverrideNotification } from "./GridCellOverrideNotification";

export function GridCellLatLon({
    value: initialValue,
    theatre,
    gridProps,
}) {

    const {showDialog, hideDialog} = useCoordinateDialog();
    const cellCommitValue = useCellCommitValue();
    const [state, setState] = useState(null);

    useEffect(() => {
        if (initialValue instanceof LatLon) {
            setState(initialValue);
        } else {
            const new_state = LatLon.from_obj(initialValue, theatre);
            setState(new_state);
        }
    }, [initialValue, theatre])

    // If we're overriden, say what we came from
    const override = useMemo(() => {
        if (gridProps?.row?.base?.[gridProps.field]) {
            let orig = gridProps.row.base[gridProps.field]
            let ll = LatLon.from_ll(orig.lat, orig.lon, theatre)
            return `Reset to ${ll.lat.format_ddm(3)}, ${ll.lon.format_ddm(3)}`;
        }
        return null;
    }, [gridProps.row, gridProps.field]);

    const onChange = async (latlon) => {
        if (latlon && !latlon.isEqual(state)) {
            await cellCommitValue({
                id: gridProps.id,
                field: gridProps.field,
                value: latlon,
                forceUpdate: true,
            });

            // As we're not controlled in edit rows, set our value
            setState(latlon);
        }
        hideDialog();
    }

    const onRevert = () => {
        cellCommitValue({
            id: gridProps.id,
            field: gridProps.field,
            value: null,
            forceUpdate: true,
        })
    }

    const onClick = (e) => {
        showDialog(state, onChange)
    }

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: "flex",
            alignItems: 'center',
            gap: '20px',
        }}>
            <GridCellOverrideNotification text={override} row={gridProps.row} field={gridProps.field} onRevert={onRevert}>
                <Box onClick={onClick} sx={{display: "flex", gap: "10px", height: '100%', flexGrow: 1, alignItems: "center", cursor: "pointer" }}>
                    <Box>{state?.lat?.format_ddm(3)}</Box>
                    <Box>{state?.lon?.format_ddm(3)}</Box>
                </Box>
            </GridCellOverrideNotification>
        </Box>
    )
}

export const renderGridCellLatLon = (theatre) => (props) => {
    return <GridCellLatLon theatre={theatre} gridProps={props} value={props.value}  />;
};
