import { Close, PriorityHigh, Warning } from "@mui/icons-material";
import { Box, Popover } from "@mui/material";
import { useState } from "react";

export default function GridCellFeedback({
    feedbackColor,
    feedbackLevel,
    children,
}) {

    feedbackColor ??= ["green", "orange", "red"][feedbackLevel] || "red";

    // Popover Anchor / Open state
    const [anchorEl, setAnchorEl] = useState(null);
    const popOverOpen = Boolean(anchorEl);
    const handlePopoverClose = () => { setAnchorEl(null); }

    if (!children) return null;

    return (
        <Box>
            <Popover
                open={popOverOpen}
                sx={{
                    pointerEvents: 'none',
                    borderRadius: '10px',
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: -5,
                    horizontal: -5,
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{p:1, fontSize:"14px", display: "grid", gridTemplateColumns: 'auto', gap: '10px'}}>
                    {children}
                </Box>
            </Popover>
            <Box
                sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: 0,
                    height: 0,
                    borderLeft: '12px solid transparent',
                    borderRight: '0px solid transparent',
                    borderTop: `12px solid ${feedbackColor}`,
                }}
                onMouseOver={(e) => setAnchorEl(e.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
            />
        </Box>
    )
}