import { Close } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

import { useEffect, useState } from "react";
import { updateDataGridRows } from "../GridManagement/updateDataGridRows";
import { useAPI } from "../../hooks/useAxios";
import { useShowError } from "../../hooks/useErrorDialog";
import { renderGridCellFrequency, renderGridCellFrequencyEdit } from "../GridCells/GridCellFrequency";
import { renderGridCellInteger, renderGridCellStringNonEmpty } from "../GridCells/GridCellValidated";
import { reflectErrorValue } from "../GridCells/reflectErrorValue";
import { getGridRowPatchObject } from "../GridManagement/getGridRowPatchObject";
import StandardGrid from "../Grid/StandardGrid";
import { useParams } from "react-router-dom";
import { CELL_ENABLED_PLUS_OVERRIDE_WIDTH, CELL_FREQUENCY_PLUS_OVERRIDE_WIDTH, CELL_FREQUENCY_WIDTH, CELL_LATLON_PLUS_OVERRIDE_WIDTH } from "../GridCells/Constants";
import { renderGridCellBoolean } from "../GridCells/GridCellBoolean";
import { renderGridCellLatLon } from "../GridCells/GridCellLatLon";
import { renderGridCellTacan, renderGridCellTacanEdit } from "../GridCells/GridCellTacan";
import { renderGridCellDisplay } from "../GridCells/GridCellDisplay";

export default function GridMizAirfieldInfo({
    apiEndpoint: rootEndpoint,
}) {

    const params = useParams();
    const apiEndpoint = `${rootEndpoint}`

    const [state, setState] = useState(null);
    const showError = useShowError();

    const axios = useAPI();

    useEffect(() => {
        setState(null);
        axios.get(apiEndpoint).then((res) => {
            if (!res.data) return;
            setState(res.data);
        });
    }, [rootEndpoint]);



    const processRowUpdate = (newRow, oldRow) => {
        const patch = getGridRowPatchObject(newRow, oldRow);
        if (!patch) return oldRow;
        if (!newRow.id) return oldRow;

        console.log(patch);

        return axios.patch(`${apiEndpoint}`, patch).then((res) => {
            if (!res.data) return oldRow;
            setState(res.data);
            return newRow;
        });
    }

    const onProcessRowUpdateError = (e) => {
        console.log(e)
    }

    const columns = (() => {
            let cols = [
            {
                field: 'tacan',
                headerName: 'TACAN',
                width: CELL_FREQUENCY_PLUS_OVERRIDE_WIDTH,
                sortable: false,
                editable: true,
                cellClassName: 'fullCell',
                renderCell: renderGridCellTacan,
                renderEditCell: renderGridCellTacanEdit,
                preProcessEditCellProps: reflectErrorValue,
            },
            {
                field: 'icls',
                headerName: 'ICLS',
                width: CELL_FREQUENCY_PLUS_OVERRIDE_WIDTH,
                cellClassName: 'fullCell',
                renderEditCell: renderGridCellInteger("edit"),
                renderCell: renderGridCellInteger(),
                preProcessEditCellProps: reflectErrorValue,
                sortable: false,
                editable: true,
            },
            {
                field: 'latlon',
                headerName: 'Location',
                flex: 1,
                sortable: false,
                editable: false,
                cellClassName: 'fullCell',
                renderCell: renderGridCellLatLon(state?.theatre),
            },
            {
                field: 'enabled',
                headerName: 'Enabled',
                width: CELL_ENABLED_PLUS_OVERRIDE_WIDTH,
                sortable: false,
                editable: true,
                width: 100,
                cellClassName: 'fullCell',
                renderCell: renderGridCellBoolean,
                type: "boolean",
            },
        ];

        return cols;
    })();

    return (
        <StandardGrid

            columns={columns}
            rows={state ? [state] : []}

            autoHeight

            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}

            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableChildrenSorting
            disableColumnResize
            density="compact"

            hideFooter

            loading={state === null}

            components={{
                NoRowsOverlay: () => (
                    <Stack height="100%" sx={{backgroundColor: "rgba(255,255,255,0.4)"}} alignItems="center" justifyContent="center">
                        {`No agencies configured for ${state?.name}`}
                    </Stack>
                ),
            }}

            initialState={{
                sorting: {
                    sortModel: [{ field: 'name', sort: 'desc' }],
                },
                pagination: {
                    pageSize: 5,
                    classes: null,
                },
            }}
        />
    )
}