import { Box } from "@mui/system";
import { useMemo } from "react";
import { useCellCommitValue } from "../GridManagement/useCellCommitValue";
import GridCellFeedback from "./GridCellFeedback";
import { GridCellOverrideNotification } from "./GridCellOverrideNotification";

export default function GridCellDisplay({
    sx,
    override,
    overrideCallback,
    feedback,
    feedbackLevel,
    gridProps,
    absolutePosition,
    children,
}) {

    const cellCommitValue = useCellCommitValue();

    const default_override = useMemo(() => {
        if (gridProps.row?.base?.[gridProps.field] !== undefined) {
            let old_value = gridProps.row.base[gridProps.field];
            if (gridProps?.colDef?.type === "boolean") return old_value ? "true" : "false";
            return old_value || "empty"
        }
        return null
    }, [gridProps.row, gridProps.field])

    override ??= default_override
    overrideCallback ??= async ({id, field, value}) => {
        await cellCommitValue({
            id: id,
            field: field,
            value: null,
            forceUpdate: true,
        });
    }

    const style = {
        ...sx
    }

    return (
        <Box sx={{position: "relative", width: '100%', height: '100%', display: 'flex', m: 0, p:0, pr:0, border: 0, outline: 0, alignItems: "center"}}>
            <Box sx={{height: '100%', width: '100%', border:0, outline: 0}}>
                <GridCellOverrideNotification
                    absolutePosition={absolutePosition}
                    field={gridProps.field}
                    row={gridProps.row}
                    onRevert={overrideCallback}
                    text={override}
                    sx={style}
                >
                    {children}
                </GridCellOverrideNotification>
            </Box>
            {feedback && <GridCellFeedback feedbackLevel={feedbackLevel}>{feedback}</GridCellFeedback> }
        </Box>
    )
}

export const renderGridCellDisplay = (props) => (gridProps) => {
    return <GridCellDisplay {...props} gridProps={gridProps}>{gridProps.value}</GridCellDisplay>
}