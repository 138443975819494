import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import AuthenticationDryadTable from "../../components/AuthenticationDrayadTable";
import AuthenticationTATTable from "../../components/AuthenticationTATTable";
import { useAPI } from "../../hooks/useAxios";
import SideBarLayout from "../../layout/SideBarLayout";
import TopBar from "../../layout/TopBar";
import CampaignHomePage from "./CampaignHomePage";
import CampaignTheatreRouter from "./CampaignTheatreRouter";
import CampaignWarehouse from "./CampaignWarehouse";


function CampaignContext() {

    return (
        <>
        <TopBar logoBackgroundColor="var(--wm-color-sidebar)" />
        <SideBarLayout config={[
            {
                title: 'Events',
                prefix: "",
                element: <>Dolally</>,
            },
            {
                title: 'Theatre Data',
                prefix: "theatres",
                element: <CampaignTheatreRouter />,
            },
            {
                title: 'Codewords',
                prefix: "codewords",
                element: <>Code Words</>
            },
            {
                title: 'Intelligence',
                prefix: "intel",
                element: <>Intelligence</>
            },
            {
                title: 'Inventory/Warehouse',
                prefix: "warehouse",
                element: <CampaignWarehouse />
            },
            {
                title: 'Authentication',
                prefix: "authentication",
                children: [
                    {
                        title: "DRYAD",
                        prefix: 'auth_dryad',
                        element: <AuthenticationDryadTable />,
                    },
                    {
                        title: "TAT",
                        prefix: 'auth_tat',
                        element: <AuthenticationTATTable />,
                    },
                ]
            },
        ]} />
        </>
    )

}


export default function CampaignRouter() {

    const navigate = useNavigate();

    return (
        <Routes>
            <Route path="" element={<>
                <TopBar />
                <CampaignHomePage />
            </>} />
            <Route path="/:campaign_id/*" element={<CampaignContext />} />
        </Routes>
    )
}