// General inputFilter / validator Cell for the grid
import PropTypes from 'prop-types';

import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import GridCellDisplay from "./GridCellDisplay";
import { Box } from '@mui/system';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import GridCellFeedback from './GridCellFeedback';
import { rowEditTabHandler } from './rowEditTabHandler';
import { useCellCommitValue } from '../GridManagement/useCellCommitValue';
import TACAN from '../../common/TACAN';


export const GridCellTacan = ({
    value: initialValue,
    gridProps,
    input,
    in_mhz
}) => {

    const cellCommitValue = useCellCommitValue();

    // We have two values....a Tacan, and a Band
    // These only go back to a grid as a Tacan object
    // and so initialValue should be there

    const [TacanObj, setTacanObj] = useState(null);

    // We have to store the Band / Tacan ourselves,
    // as otherwise we can't set Band until we have a valid
    // Tacan, and that's ugly
    const [inputValue, setInputValue] = useState("");
    const [channel, setChannel] = useState(null);
    const [bandValue, setBandValue] = useState("AM");

    // If we have an override, what's our display format
    const [revertText, setRevertText] = useState(null);

    // If we have an error
    const [error, setError] = useState(null);

    const apiRef = useGridApiContext();

    const onBandChange = async (e, newBand) => {

        // Ignore deselection
        if (newBand === null) return;

        // If we have an input channel - then we have enough to create our Tacan object and validate it
        if (channel) {

            const newTacan = new TACAN(channel, newBand);
            const newErrors = newTacan.conforms();

            await cellCommitValue({
                id: gridProps.id,
                field: gridProps.field,
                value: !newTacan ? { error: true } : newTacan,
            });
        }

        setBandValue(newBand);
    }

    const onTacanChange = async (e) => {

        // When we change our Tacan text field, process it to a number, and try and
        // create a Tacan object and validate it

        let inputValue = e.target.value;
        let inputBand = bandValue;

        // If we used X or Y then pass on to onBandChange instead (e.g: copy and paste)
        const lastCharacter = inputValue.slice(-1).toUpperCase()
        if (['X', 'Y'].includes(lastCharacter)) {
            inputBand = lastCharacter
            inputValue = inputValue.slice(0, -1)
        }

        let newTacanValue = inputValue.trim();

        let newError = null;
        let newchannel = null;
        let newTacanObject = null;

        if (!newTacanValue) {
            newchannel = null;
        } else {

            if (!newTacanValue.match(/^[0-9]+$/)) return;
            newTacanObject = new TACAN(parseInt(newTacanValue), inputBand)
            setChannel(newchannel)
            newError = newTacanObject.conforms();
        }

        // Error reflection, and update grid value if we're valid, we ignore
        // this on our pinned row
        await cellCommitValue({
            id: gridProps.id,
            field: gridProps.field,
            value: newError !== null && newError[0] == 2 ? {error: true} : newTacanObject
        })

        // and set our string value to whatever was entered (incl. end spaces etc)
        setError(newError);
        setInputValue(inputValue);
    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {

        // Plain reset to null
        if (!initialValue) {
            setError(null);
            // Text Field State
            setInputValue("");
            // Default state
            setChannel(null);
            // Reset object in case it used to be set
            setTacanObj(null);
            setBandValue("X");
            return;
        }

        // Ignore error reflection
        if (initialValue.error === true) return;

        // Try and get a Tacan object
        let tacanObject = null;

        if (initialValue instanceof TACAN) {
            tacanObject = initialValue;
        } else {
            try {
                tacanObject = TACAN.from_object(initialValue)
            } catch (e) {
                console.log(e)
            }
        }

        if (gridProps.row?.base?.[gridProps.field]) {
            try {
                const old_value = TACAN.from_object(gridProps.row.base[gridProps.field])
                setRevertText(old_value.toString())
            } catch(e) {
                setRevertText(null)
            }
        } else {
            setRevertText(null)
        }

        if (tacanObject) {
            setTacanObj(tacanObject);
            setBandValue(tacanObject.band);
            setChannel(tacanObject.channel);
            setInputValue(tacanObject.channel);
            setError(tacanObject.conforms());
            return;
        }

        // shouldn't really get here, so we just ignore it's existence
        setError(null);
        setInputValue("");
        setChannel(null);
        setBandValue("X");

    }, [gridProps.row, gridProps.field, initialValue]);


    if (input) return (

        <Box
            sx={{
                position: "relative",
                display: "flex",
                width: '100%',
                height: '100%',
                pl:1,
                pr:0,
                justifyItems: "center",
                alignItems: "center",
            }}
        >
            <input
                tabIndex={0}
                autoFocus={gridProps.hasFocus === true}
                style={{
                    textAlign: "left",
                    width: '100%',
                    height: '100%',
                    border:0,
                    outline: 0,
                    background: "transparent",
                    fontFamily: "var(--wm-font-monospace)",
                 }}
                value={inputValue}
                onChange={onTacanChange}
                onKeyDown={rowEditTabHandler(apiRef, gridProps.id, gridProps.field)}
            />
            <ToggleButtonGroup
                value={bandValue}
                color="primary"
                orientation="vertical"
                exclusive
                onChange={onBandChange}
                aria-label="text alignment"
                sx={{
                    '& .MuiToggleButton-root': {
                        borderRadius: 1.5,
                        pr: 1,
                        pl: 1,
                        mt: '1px',
                        mr: '2px',
                    }
                }}
            >
                <ToggleButton sx={{fontSize: '10px', p:'4px', height: '16px'}} size="small" value={"X"} aria-label="X">
                X
                </ToggleButton>
                <ToggleButton sx={{fontSize: '10px', p:'4px', height: '16px'}}  size="small" value={"Y"} aria-label="Y">
                Y
                </ToggleButton>
            </ToggleButtonGroup>
            {error && <GridCellFeedback feedbackLevel={error[0]}>{error[1]}</GridCellFeedback>}
        </Box>
    )

    return <GridCellDisplay
        gridProps={gridProps}
        sx={{
            justifyContent: "right",
            fontFamily: "var(--wm-font-monospace)",
        }}
        override={revertText}
        feedbackLevel={error && error[0]}
        feedback={error && error[1]}
    >
        {TacanObj ? TacanObj.toString() : ""}
    </GridCellDisplay>

}

GridCellTacan.propTypes = {
    value: PropTypes.object
}

export const renderGridCellTacan = (props) => {
    return <GridCellTacan input={props.id === 0} gridProps={props} value={props.value} in_mhz />
}

export const renderGridCellTacanEdit = (props) => {
    return <GridCellTacan input gridProps={props} value={props.value} in_mhz />
}

export const renderGridCellTacanSortComparator = (a, b) => a?.channel - b?.channel
export const renderGridCellTacanValueFormatter = (a) => {
    let TacanObject = null;
    try {
        TacanObject = TACAN.from_object(a.value)
    } catch (e) {
        return ""
    }
    return TacanObject.toString();
}
