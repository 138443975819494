import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, ButtonGroup, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { Link, Navigate, Route, Routes, useLocation, useMatch, useResolvedPath } from "react-router-dom";

export default function ContentBarLayout({
    bar,
    actions,
    children,
}) {

    return (
        <>
            <div style={{
                position: "relative",
                width: "100%",
                minHeight: "30px",
                paddingBottom: '0px',
                paddingTop: '10px',
                paddingBottom: '10px',
                //borderBottom: "1px solid var(--wm-color-separators)",
                display: "flex",
                alignItems: 'top',
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: 'center',
                    justifyContent: "end",
                    width: "100%",
                }}>
                    {bar}
                    <Box sx={{flexGrow: 1}} />
                    <ButtonGroup sx={{marginRight: '10px'}} size="small" variant="text" aria-label="text button group">
                        {actions}
                    </ButtonGroup>
                </Box>
            </div>
            <div style={{
                background: '#fff',
                borderTop: '1px solid #999',
                borderBottom: '1px solid #ccc',
                width: '100%',
                height: '0px',
            }} />
            <div style={{
                position: "relative",
                left: 0,
                top: 0,
                width: "100%",
                //height: "calc(100% - 52px)",
                marginTop: "15px",
            }}>
                {children}
            </div>
        </>
    )
}