import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";

export default function LoadingDialog({
    open,
}) {

    return (
        <Dialog open={open}>
            <DialogTitle>Loading....</DialogTitle>
            <DialogContent sx={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    )
}