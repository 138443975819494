import { Route, Routes, useNavigate } from "react-router-dom";
import TheatreList from "../../components/TheatreList";
import SideBarLayout from "../../layout/SideBarLayout";
import TopBar from "../../layout/TopBar";
import CampaignRouter from "../Campaign/CampaignRouter";
import WingCampaignPage from "./WingCampaignsPage";
import WingFrequencyCodes from "./WingFrequencyCodes";
import WingSqadronPage from "./WingSquadronPage";
import WingTheatreInfoPage from "./WingTheatreInfoPage";

export default function WingRouter() {

    const navigate = useNavigate();

    return (
        <>
        <TopBar logoBackgroundColor="var(--wm-color-sidebar)" />
        <SideBarLayout config={[
            { prefix: "", redirect: "news" },
            {
                title: 'News',
                prefix: "news",
                element: <>News</>,
            },
            {
                title: 'Squadrons',
                prefix: "squadrons",
                element: <WingSqadronPage />,
            },
            {
                title: 'Members',
                prefix: "members",
                element: <>Members</>
            },
            {
                title: 'Roster',
                prefix: "roster",
                element: <>Roster</>
            },
            {
                title: 'Campaigns',
                prefix: "campaigns",
                element: <WingCampaignPage />
            },
            {
                title: 'Admin',
                prefix: "admin",
                children: [
                    {
                        title: 'Theatres',
                        prefix: "theatres",
                        element: <Routes>
                            <Route path="" element={<TheatreList onRowClick={(e) => { navigate('theatres/' + e.id.toString())}} />} />
                            <Route path=":theatre_id" element={<WingTheatreInfoPage />} />
                            <Route path=":theatre_id/airfield/:airfield_id" element={<WingTheatreInfoPage />} />
                        </Routes>
                    },
                    {
                        title: 'Frequency Codes',
                        prefix: "frequencies",
                        element: <WingFrequencyCodes/>
                    }
                ]
            }
        ]} />
        </>
    )
}