// General inputFilter / validator Cell for the grid
import { unstable_composeClasses } from "@mui/material";
import { getDataGridUtilityClass, useGridRootProps } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import GridCellDisplay from "./GridCellDisplay";


export const GridCellBoolean = ({
    value,
    gridProps,
}) => {

    const rootProps = useGridRootProps();

    const Icon = useMemo(() =>
        value ? rootProps.components.BooleanCellTrueIcon : rootProps.components.BooleanCellFalseIcon,
        [rootProps.components.BooleanCellFalseIcon, rootProps.components.BooleanCellTrueIcon, value]);

    const color = useMemo(() =>
        value ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.38)',
        [value]);

    return <GridCellDisplay gridProps={gridProps} sx={{justifyContent: 'center'}} absolutePosition>
        <Icon fontSize="small" sx={{color: color}}/>
    </GridCellDisplay>

}

export const renderGridCellBoolean = (gridProps) => {
    return <GridCellBoolean gridProps={gridProps} value={gridProps.value}/>
}