import { Experimental_CssVarsProvider } from "@mui/material"

export const RE_TACAN = /^(?<channel>[0-9]+)\s*(?<band>[XxYy])$/

export default class TACAN {

    constructor(channel, band) {

        if (!Number.isInteger(channel)) throw new Error("Channel must be an integer")
        if (channel < 1 || channel > 126) throw new RangeError("TACAN channel must be between 1 and 126")
        if (typeof band !== "string" || !['X', 'Y'].includes(band)) throw new Error("band must be X or Y")

        this.channel = channel
        this.band = band
    }

    conforms() {

        if (this.channel < 1 || this.channel > 126) return [2, "TACAN must be between 1 and 126"]

        // TODO: Type checking, https://forum.dcs.world/topic/165047-hornet-mini-updates/page/6/#comment-3803291
        //
        // F18:
        // Because the upper antenna filter is fixed, it filters A/A TACAN
        // frequencies on channels 1-36 and 64-99 (X and Y). A/A TACAN channels
        // should be chosen outside of these ranges. The upper filter makes the
        // top antenna unusable for T/R (Air to Ground) TACAN channels 1-29 X
        // and Y, 47X to 63X and 64Y to 92Y.

        if ((this.channel >= 1 && this.channel <= 36) || (this.channel >= 64 && this.channel <= 99) ) {
            return [1, "A/A Interference with Data Link (F-18)"]
        }
    }

    isEqual(other) {
        if (!other instanceof TACAN) throw new TypeError("isEqual must reference another TACAN object")
        return other.channel === this.channel && other.band == this.band
    }

    toString() {
        return `${this.channel} ${this.band}`
    }

    static from_string(tacan_string) {

        if (typeof tacan_string !== "string") throw Error("fromString expects a string")
        if (tacan_string === "") return null;

        const matches = RE_TACAN.exec(tacan_string);
        if (!matches) throw Error("TACAN must have a number between 1-126 followed by X or Y")

        let channel = parseInt(matches.groups.channel);
        let band = matches.groups.band.toUpperCase()

        return new TACAN(channel, band)
    }

    static from_object(tacan_obj) {
        if (typeof tacan_obj !== "object") throw new Error("fromObject expects an object")
        if (!tacan_obj) throw new Error("Expects an object with channel and band")

        let channel = tacan_obj?.channel;
        if (!channel) throw new Error("expected channel attribute")
        channel = parseInt(channel);

        let band = tacan_obj?.band
        if (!band) throw new Error("expected band attribute")

        return new TACAN(channel, band)
    }

}