import { Close } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { GridActionsCellItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";

import { useEffect, useState } from "react";
import { updateDataGridRows } from "../GridManagement/updateDataGridRows";
import { useAPI } from "../../hooks/useAxios";
import { useShowError } from "../../hooks/useErrorDialog";
import { renderGridCellInteger, renderGridCellStringNonEmpty } from "../GridCells/GridCellValidated";
import { reflectErrorValue } from "../GridCells/reflectErrorValue";
import { getGridRowPatchObject } from "../GridManagement/getGridRowPatchObject";
import StandardGrid from "../Grid/StandardGrid";
import { useParams } from "react-router-dom";
import { CELL_LATLON_PLUS_OVERRIDE_WIDTH } from "../GridCells/Constants";
import { Box } from "@mui/material";
import { renderGridCellLatLon } from "../GridCells/GridCellLatLon";
import { renderGridCellBoolean } from "../GridCells/GridCellBoolean";
import { renderGridCellDisplay } from "../GridCells/GridCellDisplay";

function QuickSearchToolbar() {
    return (
      <GridToolbarContainer>
        <Box sx={{flexGrow: 1}} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
}

export default function GridNavpoints({
    apiEndpoint,
    reloadTrigger,
}) {

    const params = useParams();

    const [state, setState] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const showError = useShowError();

    const axios = useAPI();

    useEffect(() => {
        setState(null);
        axios.get(apiEndpoint).then((res) => {
            if (!res.data) return;
            setState(res.data);
            console.log("S", res.data);
        });
    }, [apiEndpoint, reloadTrigger]);

    ///////////////////////////////////////////////////////////////////////////
    // Row creation handling
    ///////////////////////////////////////////////////////////////////////////

    // Let process Row Update handle the additional validation
    const createNavpoint = async (createData) => {

        // We can ensure we have a fixed coalition/type here
        createData = {...createData, coalition: "all", type: "custom"}
        console.log(createData)

        // Ensure name is not empty
        if ((createData?.name||"").trim() === "") {
            showError("Invalid Navpoint", "Name must not be empty!")
            return Promise.reject();
        }

        // Make sure one of primary, secondary, or tertary is set
        if (!createData.latlon) {
            showError("Invalid Navpoint", "Please esnure a valid location")
            return Promise.reject();
        }

        // to json
        return axios.post(`${apiEndpoint}`, createData).then((res) => {
            if (!res.data) return;
            setState({...state, navpoints: [...state.navpoints, res.data]});
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // STANDARD GRID HANDLING
    ///////////////////////////////////////////////////////////////////////////

    const handleDeleteClick = (id) => () => {
        if (id === 0) return;

        return axios.delete(`${apiEndpoint}/${id}`).then((res) => {
            if (!res.data) return;
            setState({...state, navpoints: state.navpoints.filter((x) => x.id !== id)});
        });
    }

    const processRowUpdate = (newRow, oldRow) => {

        const bulkable = ['coalition', 'enabled']

        const patch = getGridRowPatchObject(newRow, oldRow);
        if (!patch) return oldRow;
        if (!newRow.id) return oldRow;

        // If we have a bulkable update key, process those as we can only edit one field at a time
        const changes = bulkable.filter(x => patch[x] !== undefined)

        // Only update the rows if one item changing, which it should be
        if (changes.length == 1 && selectionModel.includes(newRow.id) && selectionModel.length > 1) {
            const key_changed = changes[0];
            return axios.post(`${apiEndpoint}/bulk`, {
                'update': selectionModel.map((id) => { return { "id": id, [key_changed]: newRow[key_changed] } })
            }).then((res) => {
                if (!res?.data) return oldRow;
                const new_state = {...state, navpoints: updateDataGridRows(state.navpoints, res.data?.update||[])}
                setState(new_state);
                return newRow;
            });
        } else {
            return axios.patch(`${apiEndpoint}/${newRow.id}`, patch).then((res) => {
                if (!res?.data) return oldRow;
                const new_state = {...state, navpoints: updateDataGridRows(state.navpoints, [res.data])}
                setState(new_state);
                return newRow;
            });
        }
    }

    const onProcessRowUpdateError = (e) => {
        console.log(e)
    }

    const columns = (() => {
        let cols = [
            {
                field: "coalition",
                headerName: 'Coalition',
                width: 110,
                sortable: true,
                editable: true,
                type: "singleSelect",
                renderCell: renderGridCellDisplay(),
                //renderCell: (props) => <TextCell overrideDict={overrideDict} rowUpdate={rowUpdate} {...props} />,
                valueOptions: ["all", "blue", "red"],
            },
            {
                field: 'type',
                headerName: 'Type',
                width: 100,
            },
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                editable: true,
                cellClassName: 'fullCell',
                renderCell: renderGridCellStringNonEmpty(),
                renderEditCell: renderGridCellStringNonEmpty("edit"),
                preProcessEditCellProps: reflectErrorValue,
            },
            {
                field: 'latlon',
                headerName: 'Location',
                width: CELL_LATLON_PLUS_OVERRIDE_WIDTH,
                sortable: false,
                editable: false,
                renderCell: renderGridCellLatLon(state?.theatre),
                cellClassName: 'fullCell',
            },
            {
                field: 'alt_ft',
                headerName: 'Altitude (ft)',
                width: 100,
                sortable: false,
                editable: true,
                cellClassName: 'fullCell',
                renderEditCell: renderGridCellInteger("edit"),
                renderCell: renderGridCellInteger(),
                preProcessEditCellProps: reflectErrorValue,
            },
            {
                field: "enabled",
                headerName: 'Enabled',
                flex: 1,
                maxWidth: 110,
                sortable: true,
                editable: true,
                renderCell: renderGridCellBoolean,
                valueGetter: ({value}) => value === undefined ? true : value,
                type: "boolean",
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 80,
                getActions: (props) => {
                    return [
                        <GridActionsCellItem
                            icon={<Close />}
                            label="Cancel"
                            onClick={handleDeleteClick(props.id)}
                        />,
                    ]
                }
            }
        ]

        return cols;
    })();

    return (
        <StandardGrid

            columns={columns}
            rows={state?.navpoints||[]}

            autoHeight

            createRow={createNavpoint}
            createRowTitle="Add Navpoint"
            createInitialProps={{"type": "custom", "coalition": "all"}}

            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}

            pagination
            rowsPerPageOptions={[5, 15, 25, 50, 100]}

            disableSelectionOnClick
            disableColumnMenu
            density="compact"

            loading={state === null}

            // Checkbox Handling
            checkboxSelection={true}
            onSelectionModelChange={(newSelectionModel) => { setSelectionModel(newSelectionModel); }}
            selectionModel={selectionModel}

            components={{
                Toolbar: QuickSearchToolbar,
                NoRowsOverlay: () => (
                    <Stack height="100%" sx={{backgroundColor: "rgba(255,255,255,0.4)"}} alignItems="center" justifyContent="center">
                        {`No navpoints configured for ${state?.name}`}
                    </Stack>
                ),
            }}

            initialState={{
                sorting: {
                    sortModel: [{ field: 'name', sort: 'desc' }],
                },
                pagination: {
                    pageSize: 15,
                    classes: null,
                },
            }}
        />
    )
}