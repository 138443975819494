import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAPI } from "../../hooks/useAxios"
import ContentBarLayout from "../../layout/ContentBarLayout"
import TopBar from "../../layout/TopBar"

export default function WingPage() {

    const axios = useAPI();
    const navigate = useNavigate();

    // Just dump a list of wings for now
    const [is_show_wing_dialog, set_show_wing_dialog] = useState(false);
    const show_wing_dialog = () => { set_show_wing_dialog(true) };
    const hide_wing_dialog = () => { set_show_wing_dialog(false) };

    const default_state = {
        _example: "GhostRider",
        tag_space: true,
        tag_suffix: false,
    }

    const [wing_add_state, set_wing_add_state] = useState(default_state);

    // Data Grid items
    const wing_cols = [
        {'field': 'name', headerName:'Wing Name', flex: 1, editable: false},
    ];

    const [wing_rows, set_wing_rows] = useState([]);

    useEffect(() => {
        axios.get('/wing').then((res) => {
            if (!res?.data) return
            console.log(res.data);
            set_wing_rows(res.data);
        })
    }, [])

    const update_wing_add_state = (event) => {
        let key = event.target.name;

        let value = event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;

        let new_state = {...wing_add_state, [key]: value}

        // Update our example
        let tag = new_state.tag || "";
        let callsign = "GhostRider";
        let tag_space = new_state.tag_space ? " " : "";

        new_state._example = (new_state.tag_suffix
            ? `${callsign}${tag_space}${tag}`
            : `${tag}${tag_space}${callsign}`).trim();

        // If we have tried to submit, revalidate, but not submit
        if (new_state._submit) {
            validate_state(new_state);
            return
        }

        set_wing_add_state(new_state);
    }

    const validate_state = (new_state) => {

        // Ensure name, short_name are not absent
        let err = false;

        // if (onSubmit) new_state['_submit'] = true;

        if (!(new_state.name || "").trim()) {
            new_state._name_error = "You must enter a wing name"
            err = true;
        } else if (new_state._name_error) {
            new_state._name_error = undefined;
        }

        if (!(new_state.short_name||"").trim()) {
            new_state._short_name_error = "You must enter a short name"
            err = true;
        } else {
            new_state._short_name_error = undefined;
        }

        set_wing_add_state(new_state);

        if (err) return null;
        return new_state;
    }

    const onRowClick = (e) => {
        navigate(e.id.toString())
    }

    const add_wing_submit = () => {
        let valid = validate_state({...wing_add_state, _submit: true});
        if (valid) {
            let patch = Object.fromEntries(Object.entries(valid).filter(([key]) => !key.startsWith('_')));
            axios.post('/wing', patch).then((res) => {
                // Add wing to our wing list
                if (!res.data) return;
                set_wing_rows([...wing_rows, res.data]);
                set_show_wing_dialog(false);
            })
        }
    }

    return (
        <>
            <TopBar/>
            <ContentBarLayout actions={[
                <Button key="join" onClick={show_wing_dialog}>Join Wing</Button>,
                <Button key="create" onClick={show_wing_dialog}>Create Wing</Button>
            ]}>
                <DataGridPro
                    //onRowClick={onRowClick}
                    rows={wing_rows}
                    columns={wing_cols}
                    disableSelectionOnClick autoHeight hideFooter
                    onRowClick={onRowClick}
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" sx={{backgroundColor: "rgba(255,255,255,0.4)"}} alignItems="center" justifyContent="center">
                            Please join or create a wing
                          </Stack>
                        ),
                    }}
                />
            </ContentBarLayout>


        <Dialog open={is_show_wing_dialog} onClose={hide_wing_dialog}>
            <DialogTitle>Add Wing</DialogTitle>
            <DialogContent sx={{maxWidth: '800px'}}>
                <Box sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(10, 1fr)",
                    gridGap: '15px',
                    margin: '5px',
                }}>
                    <TextField
                        sx={{
                            gridColumnStart: 1,
                            gridColumnEnd: 6,
                        }}
                        name="name"
                        label="Full Name"
                        placeholder="Dynamic DCS"
                        onChange={update_wing_add_state}
                        value={wing_add_state.name || ""}
                        error={wing_add_state._submit && wing_add_state._name_error !== undefined}
                        helperText={wing_add_state._submit && wing_add_state._name_error}
                        fullWidth
                        required
                        InputLabelProps={{shrink: true}} />

                    <TextField
                        sx={{
                            gridColumnStart: 6,
                            gridColumnEnd: 11,
                        }}
                        name="short_name"
                        label="Abbreviation"
                        onChange={update_wing_add_state}
                        value={wing_add_state.short_name || ""}
                        error={wing_add_state._submit && wing_add_state._short_name_error !== undefined}
                        helperText={wing_add_state._submit && wing_add_state._short_name_error}
                        placeholder="DDCS"
                        required
                        fullWidth
                        InputLabelProps={{shrink: true}} />


                    <TextField
                        sx={{
                            gridColumnStart: 1,
                            gridColumnEnd: 11,
                        }}
                        fullWidth
                        name="website"
                        label="Website"
                        onChange={update_wing_add_state}
                        value={wing_add_state.website || ""}
                        placeholder="www.dynamicdcs.com"
                        InputLabelProps={{shrink: true}} />

                    <TextField
                        sx={{
                            gridColumnStart: 1,
                            gridColumnEnd: 4,
                        }}
                        fullWidth
                        name="tag"
                        label="Member Tag"
                        onChange={update_wing_add_state}
                        value={wing_add_state.tag || ""}
                        placeholder="=DDCS="
                        InputLabelProps={{shrink: true}} />

                    <TextField
                        sx={{
                            gridColumnStart: 4,
                            gridColumnEnd: 11,
                        }}
                        fullWidth
                        disabled
                        name="tag"
                        label="Example Member Display"
                        value={wing_add_state._example || ""}
                        InputLabelProps={{shrink: true}} />

                    <FormControlLabel
                        sx={{
                            gridColumnStart: 1,
                            gridColumnEnd: 4,
                        }}
                        control={
                            <Checkbox
                                name="tag_suffix"
                                checked={wing_add_state.tag_suffix}
                                onChange={update_wing_add_state} />
                        }
                        label="Tag as Suffix" />

                    <FormControlLabel
                        sx={{
                            gridColumnStart: 4,
                            gridColumnEnd: 11,
                        }}
                        control={
                            <Checkbox
                                name="tag_space"
                                onChange={update_wing_add_state}
                                checked={wing_add_state.tag_space}
                            />
                        }
                        label="Space between Tag and Callsign" />

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide_wing_dialog}>Cancel</Button>
                <Button onClick={add_wing_submit}>Create Wing</Button>
            </DialogActions>
        </Dialog>

        </>
    )
}