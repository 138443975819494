import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { srRS } from "@mui/material/locale";
import { createContext, useCallback, useState } from "react";

export const DialogContext = createContext();

export const DialogContextProvider = ({
    children,
}) => {

    const [dialog, setDialog] = useState(null);

    const ctxValue = {
        dialog: dialog,
        showDialog: useCallback((title, body, actionItem, actionCallback) => setDialog({title, body, actionItem, actionCallback, open: true}), []),
    }

    const handleClose = () => setDialog({...dialog, open: false});

    const handleAction = () => {
        if (dialog?.actionCallback) dialog.actionCallback()
        handleClose();
    }

    return (
        <DialogContext.Provider value={ctxValue}>
            {children}

            <Dialog open={dialog?.open || false} onClose={handleClose}>
                <DialogTitle>{dialog?.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialog?.body}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    {dialog?.actionItem && dialog?.actionCallback && <Button onClick={handleAction}>{dialog.actionItem}</Button>}
                </DialogActions>
            </Dialog>
        </DialogContext.Provider>
    );
};